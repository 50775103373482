import React, { useRef } from "react";
import { Color, Flex, Text } from "src/elements";
import { useHoverClickable } from "src/utils/hoverClickable";

interface Props {
  isDisabled?: boolean;
  isActive: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export const SidepanelButton = ({ isDisabled, isActive: _isActive, onClick, children }: Props) => {
  const ref = useRef(null);

  const { isHovered, isActive: isClicked } = useHoverClickable(ref);
  const isActive = _isActive || isClicked;

  return (
    <Flex
      ref={ref}
      width="100%"
      background={
        (isActive && Color.greenSmokeActive) ||
        (isHovered && Color.greenSmokeHover) ||
        Color.greenSmokeDisabled
      }
      justify="center"
      align="center"
      ratio="1"
      padding="5px 0"
      onClick={(!isDisabled && onClick) || undefined}
      pointerEvents={(isDisabled && "none") || "auto"}
    >
      <Text
        direction="top-bottom"
        variant="small1"
        color={
          (isDisabled && Color.spaceGray) ||
          (isActive && Color.primaryActive) ||
          Color.textSecondary
        }
      >
        {children}
      </Text>
    </Flex>
  );
};
