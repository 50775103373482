import { useEffect, useState } from "react";
import { Page, PageStatus } from "src/components";
import { MerchflowHeader } from "src/components/merchflows/MerchflowHeader/MerchflowHeader";
import { useModals } from "src/components/Modals";
import { Button, Color, Flex, Search, SubgridTable } from "src/elements";
import { useProjects } from "./store/hooks";
import { useLoaders } from "src/components/InlineLoader";
import { ProjectRow } from "./components/ProjectRow";

export const PageProjects = () => {
  const { projects, loadProjects } = useProjects();
  const { openModal } = useModals();
  const { hasLoader } = useLoaders();

  const [search, setSearch] = useState("");
  const isLoading = projects === undefined || hasLoader("GET_/projects");

  useEffect(() => {
    loadProjects();
  }, []);

  return (
    <Page>
      <MerchflowHeader showProjects showFrogger={false} />

      <Flex gap="10px" justify="between" align="center">
        <Flex gap="10px" align="center">
          <Button iconRight={{ name: "filter" }}>Filters</Button>
          <Search
            width="160px"
            search={search}
            setSearch={setSearch}
            placeholder="Search Projects"
          />
        </Flex>

        <Button
          color="greenSmoke"
          iconRight={{ name: "plus" }}
          onClick={() => openModal("ProjectModal")}
        >
          Create New Project
        </Button>
      </Flex>

      {isLoading && <PageStatus label="Loading projects..." icon="spinner" />}

      {projects === null && (
        <PageStatus label="Projects failed to load" icon="alert" color={Color.red} />
      )}

      {!isLoading && projects && (
        <SubgridTable
          columns={[
            {
              id: "project_id",
              header: "Project ID",
              justify: "right",
            },
            {
              id: "name",
              header: "Project Name",
            },
            {
              id: "go_live_date",
              header: "Go-Live Date",
            },
            {
              id: "deactivation_date",
              header: "Deactivation Date",
            },
            {
              id: "createdDate",
              header: "Created Date",
            },
            {
              id: "updatedDate",
              header: "Updated Date",
            },
            {
              id: "notes",
              header: "Notes",
              width: "1fr",
            },
            {
              id: "actions",
              header: "Actions",
            },
          ]}
          data={projects}
          rowRenderer={ProjectRow}
        />
      )}
    </Page>
  );
};
