const keyToken = "token";

export const getLocalStorageItem = (key: string) => localStorage.getItem(key);

export const setLocalStorageItem = (key: string, value: string) => localStorage.setItem(key, value);

export const deleteLocalStorageItem = (key: string) => localStorage.removeItem(key);

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const setLoginToken = (token: string) => setLocalStorageItem(keyToken, token);

export const getLoginToken = () => getLocalStorageItem(keyToken);

export const removeLoginToken = () => deleteLocalStorageItem(keyToken);
