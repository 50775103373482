import { RoutePostMerchflowCreatePlanogramTemplate } from "@CommonApi/merchflow/createPlanogramTemplate";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useApi } from "src/api";
import { useLoaders } from "src/components/InlineLoader";
import { merchflows_merchflowId_DetailsAtom } from "src/components/merchflows/MerchflowHeader/store/atoms";
import { useModals } from "src/components/Modals";
import { Box, Button, Color, Flex, Icon, Input, Modal, Text } from "src/elements";
import { createTemplateModalAtom } from "./atom";

export const CreateTemplateModal = () => {
  const data = useAtomValue(createTemplateModalAtom);
  if (!data) return null;

  return <CreateTemplateModalInner storeCode={data.storeCode} storePogId={data.storePogId} />;
};

const CreateTemplateModalInner = ({
  storePogId,
  storeCode,
}: {
  storeCode: string;
  storePogId: number;
}) => {
  const { postMerchflowCreatePlanogramTemplateApi } = useApi();
  const { closeModal: _closeModal } = useModals();
  const { hasLoader } = useLoaders();
  const merchflowDetails = useAtomValue(merchflows_merchflowId_DetailsAtom);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const isCreateTemplateLoading = hasLoader(RoutePostMerchflowCreatePlanogramTemplate);

  const closeModal = () => {
    _closeModal("CreateTemplateModal");
  };

  const saveTemplatePlanogram = async () => {
    const success = await postMerchflowCreatePlanogramTemplateApi({
      store_pog_id: storePogId,
      category: category,
      subcategory: subCategory,
      template_name: templateName,
    });

    if (!success) return;
    setIsSuccess(true);
  };

  useEffect(() => {
    if (!merchflowDetails) return;

    setCategory(merchflowDetails?.category_code);
    setSubCategory(merchflowDetails.retailer_category_code);
    setTemplateName(`STORE ID: ${storeCode} - STORE POG ID: ${storePogId}`);
  }, [merchflowDetails]);

  return (
    <Modal name="CreateTemplateModal" title="Create Template Planogram" width="700px">
      <Box padding="16px">
        <Flex gap="15px" borderRadius="3px" margin="0 0 20px 0">
          <Input title="Category" value={category} setValue={setCategory} isDisabled />

          <Input title="Sub-Category" value={subCategory} setValue={setSubCategory} isDisabled />
        </Flex>
        <Input
          title="Template Name"
          value={templateName}
          setValue={setTemplateName}
          isDisabled={isCreateTemplateLoading || isSuccess}
        />

        {isSuccess ? (
          <Flex
            column
            background={Color.greenSmoke}
            borderRadius="3px"
            gap="10px"
            padding="12px"
            justify="center"
            margin="20px 0 0 0"
          >
            <Icon name="checkmarkCircle" size={32} />
            <Text variant="h5" color={Color.primary}>
              Template Planogram saved successfully!
            </Text>
            <Text variant="body3" color={Color.primary}>
              To create a new merchflow using this template, go to Homepage and click{" "}
              <Text color={Color.primary}>
                <b>Create from Template.</b>
              </Text>
            </Text>
          </Flex>
        ) : (
          <Flex justify="center" gap="16px" margin="20px 0 0 0">
            <Button
              color="red"
              variant="inverted"
              onClick={closeModal}
              isDisabled={isCreateTemplateLoading}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={saveTemplatePlanogram}
              isLoading={isCreateTemplateLoading}
            >
              Save
            </Button>
          </Flex>
        )}
      </Box>
    </Modal>
  );
};
