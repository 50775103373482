import { useAtom } from "jotai";
import { useApi } from "src/api";
import { projectModalAtom, projectsPageAtom } from "./atoms";
import { Project } from "src/omni-common/projects";
import { useModals } from "src/components/Modals";

export const useProjects = () => {
  const { getProjectsApi } = useApi();
  const { openModal } = useModals();

  const [projects, setProjects] = useAtom(projectsPageAtom);
  const [, setEditableProject] = useAtom(projectModalAtom);

  const loadProjects = async () => {
    setProjects(await getProjectsApi());
  };

  const editProject = (project: Project) => {
    setEditableProject(project);
    openModal("ProjectModal");
  };

  return { projects, loadProjects, editProject };
};
