import { useState } from "react";
import { useApi } from "src/api";
import { Button } from "src/elements";
import { downloadFileFromUrl } from "src/utils/downloadFile";

interface Props {
  storePogId: number;
  isCompact?: boolean;
}

export const StorePogLogsButton = ({ storePogId, isCompact }: Props) => {
  const { getMerchflowStorePogLogsApi } = useApi();

  // Loading with state, because all stores page has many logs buttons, need to show spinner only for the one button.
  const [isLoading, setIsLoading] = useState(false);

  const downloadLogs = async (
    logsType:
      | "pogger_request_path"
      | "pogger_response_path"
      | "update_request_path"
      | "update_response_path",
  ) => {
    setIsLoading(true);
    const response = await getMerchflowStorePogLogsApi(storePogId);

    if (response && response[logsType]) {
      downloadFileFromUrl(response[logsType]);
    }
    setIsLoading(false);
  };

  return (
    <Button
      color="greenSmoke"
      variant="inverted"
      dropdown={[
        { label: "Pogger request", onClick: () => downloadLogs("pogger_request_path") },
        { label: "Pogger response", onClick: () => downloadLogs("pogger_response_path") },
        { label: "Update request", onClick: () => downloadLogs("update_request_path") },
        { label: "Update response", onClick: () => downloadLogs("update_response_path") },
      ]}
      isLoading={isLoading}
      iconRight={isCompact ? { name: "arrowDown" } : undefined}
    >
      {!isCompact ? "Logs" : undefined}
    </Button>
  );
};
