export const RoutePostMerchflowUploadVariantRestrictions = "/merchflow/upload-variant-restrictions";

export type PayloadPostMerchflowUploadVariantRestrictions = FormData;

export interface PayloadFormPostMerchflowUploadVariantRestrictions {
  csv_file: File;
  category_id: string;
}

export interface ResponsePostMerchflowUploadVariantRestrictions {
  result: object;
}
