import { useAtom } from "jotai";
import { Button, Flex, Modal } from "src/elements";
import { importStoreSummaryModalDataAtom } from "./store/atoms";
import { useEffect, useState } from "react";
import { FileUploader } from "src/components";
import { useLoaders } from "src/components/InlineLoader";
import { useMerchflowsApi } from "src/api/merchflows";
import { Category } from "@CommonApi/categories/list";
import { useAsyncState } from "../../hooks/useAsyncState";
import { RoutePostImportStoreSummary } from "@CommonApi/store-summary";

interface Props {
  category: Category;
}

export const ModalImportStoreSummary = () => {
  const [data, setData] = useAtom(importStoreSummaryModalDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) {
    return null;
  }

  return <ModalImportStoreSummaryInner category={data} />;
};

const ModalImportStoreSummaryInner = ({ category }: Props) => {
  const { hasLoader } = useLoaders();
  const { postMerchflowImportStoreSummaryApi } = useMerchflowsApi();

  const [storeSummaryFile, setStoreSummaryFile] = useState<File | null>(null);
  const [storeSummaryResponse, setStoreSummaryResponse] = useAsyncState<string | null>(null);

  const isUploadStoreSummaryLoading = hasLoader(RoutePostImportStoreSummary);

  const uploadStoreSummary = async () => {
    if (!storeSummaryFile) return;

    setStoreSummaryResponse(
      await postMerchflowImportStoreSummaryApi({
        file: storeSummaryFile,
        category_id: category.id,
      }),
    );
  };

  return (
    <Modal
      name="ModalImportStoreSummary"
      title={`Import Store Summary for Category: ${category.code}`}
      width="500px"
    >
      <Flex padding="10px" column gap="16px">
        <Flex gap="10px">
          <FileUploader
            fileLabel="Store Summary"
            fileType={"csv"}
            onChange={setStoreSummaryFile}
            isLoading={isUploadStoreSummaryLoading}
            onSuccess={storeSummaryResponse}
          />
        </Flex>

        <Button
          isDisabled={
            !storeSummaryFile || isUploadStoreSummaryLoading || Boolean(storeSummaryResponse)
          }
          onClick={uploadStoreSummary}
        >
          Upload
        </Button>
      </Flex>
    </Modal>
  );
};
