import { Planogram, PlanogramBay, Pog, PogNonCircular } from "../../types/merchflow/pog/pog";
import {
  getPlanogramItemDaysOfSupplyPercentage,
  getPlanogramItemMOSPercentage,
  getPlanogramItemWidth,
} from "./item";
import { getShelfWidth, getShelfYBottom } from "./shelf";

export const calculatePogReturnOnSpace = (pog: Pog) => {
  const sumTotal = {
    width: 0,
    salesAmount: 0,
  };

  const sumsByCDT1: {
    [cdt1: string]: {
      width: number;
      salesAmount: number;
    };
  } = {};

  for (const bay of pog.planogram.bays) {
    for (const shelf of bay.shelves) {
      for (const item of shelf.items) {
        if (!sumsByCDT1[item.cdt1]) {
          sumsByCDT1[item.cdt1] = {
            width: 0,
            salesAmount: 0,
          };
        }

        const itemSumWidthHorizontalFacings =
          getPlanogramItemWidth(item) * Math.ceil(item.facings / item.facingsRows);

        sumsByCDT1[item.cdt1].width += itemSumWidthHorizontalFacings;
        sumsByCDT1[item.cdt1].salesAmount += item.salesAmount;

        sumTotal.width += itemSumWidthHorizontalFacings;
        sumTotal.salesAmount += item.salesAmount;
      }
    }
  }

  let penalty = 0;

  for (const sumByCDT1 of Object.values(sumsByCDT1)) {
    const widthPercent = (sumByCDT1.width / sumTotal.width) * 100;
    const salesAmountPercent = (sumByCDT1.salesAmount / sumTotal.salesAmount) * 100;

    if (widthPercent < salesAmountPercent) {
      penalty += Math.abs(widthPercent - salesAmountPercent);
    }
  }

  if (isNaN(penalty)) {
    return null;
  }

  return 100 - penalty;
};

export const calculatePogCoreRange = (pog: Pog) => {
  const currentCoreRangeHash: Record<string, number> = {};
  const totalCoreRangeHash: Record<string, number> = {};

  pog.planogram.bays.forEach((bay) => {
    bay.shelves.forEach((shelf) => {
      shelf.items.forEach((item) => {
        if (item.inCoreRange) {
          currentCoreRangeHash[item.productCode] = 1;
          totalCoreRangeHash[item.productCode] = 1;
        }
      });
    });
  });

  pog.unrangedItems.forEach((unrangedItem) => {
    if (unrangedItem.inCoreRange) {
      totalCoreRangeHash[unrangedItem.productCode] = 1;
    }
  });

  const currentCoreRangeItems = Object.keys(currentCoreRangeHash).length;
  const totalCoreRangeItems = Object.keys(totalCoreRangeHash).length;

  if (
    totalCoreRangeItems === 0 ||
    Number.isNaN(currentCoreRangeItems) ||
    Number.isNaN(totalCoreRangeItems)
  ) {
    return null;
  }

  return (currentCoreRangeItems / totalCoreRangeItems) * 100;
};

export const calculatePogShelfAlignment = (pog: Pog) => {
  let totalBreakPoints = 0;
  let totalMisalignments = 0;
  const usedShelves: { [shelfId: string]: true } = {};

  for (let bayIndex = 0; bayIndex < pog.planogram.bays.length; bayIndex++) {
    const bay = pog.planogram.bays[bayIndex];
    const nextBay: PlanogramBay | undefined = pog.planogram.bays[bayIndex + 1];
    if (!nextBay) break;

    shelfAlignmentChecker: for (const shelf of bay.shelves) {
      const shelfBottom = getShelfYBottom(shelf);
      totalBreakPoints++;

      for (const nextBayShelf of nextBay.shelves) {
        // Shelf already used.
        if (usedShelves[nextBayShelf.uniqueId]) continue;

        // Shelf alignment found.
        if (getShelfYBottom(nextBayShelf) === shelfBottom) {
          usedShelves[nextBayShelf.uniqueId] = true;
          continue shelfAlignmentChecker;
        }
      }

      // All next bay shelves checked, misalignment found.
      totalMisalignments++;
    }
  }

  if (totalBreakPoints === 0) {
    return null;
  }

  return (1 - totalMisalignments / totalBreakPoints) * 100;
};

export const calculatePogDosMos = (pog: Pog) => {
  let totalItems = 0;
  let itemsBelowDosMos = 0;

  for (const bay of pog.planogram.bays) {
    for (const shelf of bay.shelves) {
      for (const item of shelf.items) {
        const dos = getPlanogramItemDaysOfSupplyPercentage(item);
        const mos = getPlanogramItemMOSPercentage(item);

        itemsBelowDosMos += dos < 100 || mos < 100 ? 1 : 0;
        totalItems++;
      }
    }
  }

  if (totalItems === 0 || isNaN(itemsBelowDosMos) || isNaN(totalItems)) {
    return null;
  }

  return (1 - itemsBelowDosMos / totalItems) * 100;
};

export const getPogShelf = (pog: Pog, shelfId: string) => getPlanogramShelf(pog.planogram, shelfId);

export const getPlanogramShelf = (planogram: Planogram, shelfId: string) => {
  for (const bay of planogram.bays) {
    for (const shelf of bay.shelves) {
      if (shelf.uniqueId === shelfId) {
        return shelf;
      }
    }
  }

  return null;
};

export const isPogHavingRangeRecommendations = (pog: Pog | PogNonCircular) => {
  const recommendedQuantities = getPogMinimumRecommendedQuantities(pog);

  for (const unrangedItem of pog.unrangedItems) {
    const recommendedQuantity = recommendedQuantities[unrangedItem.variant];
    if (recommendedQuantity !== undefined && unrangedItem.quantity > recommendedQuantity) {
      return true;
    }
  }

  return false;
};

export const getPogMinimumRecommendedQuantities = (pog: Pog | PogNonCircular) => {
  const recommendedQuantities: { [key: string]: number | undefined } = {};

  for (const bay of pog.planogram.bays) {
    for (const shelf of bay.shelves) {
      for (const item of shelf.items) {
        const previousRecommendedQuantity = recommendedQuantities[item.variant];

        if (
          previousRecommendedQuantity === undefined ||
          item.quantity < previousRecommendedQuantity
        ) {
          recommendedQuantities[item.variant] = item.quantity;
        }
      }
    }
  }

  return recommendedQuantities;
};

export const getPlanogramTotalSpace = (planogram: Planogram) => {
  let totalSpace = 0;

  for (const bay of planogram.bays) {
    for (const shelf of bay.shelves) {
      totalSpace += getShelfWidth(shelf);
    }
  }

  return totalSpace;
};
