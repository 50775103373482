import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtom, useSetAtom } from "jotai";
import { calculateContainerCurrentWidth } from "src/adapters/algoStep0";
import {
  algoS2FreezerItemLabelCdtsRegex,
  getContainerHeight,
  getContainerWidth,
} from "@CommonUtils/pog/container";
import { planogramHoveredContainerAtom } from "src/components/PlanogramExtensions/store/atoms";
import { InnerWrapper } from "../../common/InnerWrapper";
import { DragBounds, DragModifications, Scaleable, Snappable } from "../../store/types";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { MenuOption, useMenu } from "src/components/Menu";
import { Key } from "src/utils/keys";
import { uuid } from "src/utils";
import { Container } from "./Container";
import { getPlanogramTotalSpace } from "@CommonUtils/pog/pog";

interface Props extends Scaleable {
  bounds?: DragBounds;
  snappables: Snappable[];
  isEditable?: boolean;
  isContainersFullDetails: boolean;
  containersAtom: PrimitiveAtom<PlanogramContainer[]>;
  pogAtom: PrimitiveAtom<Pog>;
}

export const Containers = ({
  scaleX,
  scaleY,
  bounds,
  snappables,
  isEditable,
  isContainersFullDetails,
  containersAtom,
  pogAtom,
}: Props) => {
  const { openMenu } = useMenu();

  const [containers, setContainers] = useAtom(containersAtom);
  const [pog] = useAtom(pogAtom);
  const setHoveredContainer = useSetAtom(planogramHoveredContainerAtom);

  const getSnappables = (containerToIgnore: PlanogramContainer) => {
    const containersSnappables: Snappable[] = [];

    containers
      .filter((container) => container.uniqueId !== containerToIgnore.uniqueId)
      .forEach((container) => {
        containersSnappables.push({
          xLeft: container.xLeft,
          xRight: container.xRight,
          yBottom: container.yBottom,
          yTop: container.yTop,
        });
      });

    return [...snappables, ...containersSnappables];
  };

  const onModify = (container: PlanogramContainer, modifications: DragModifications) => {
    setContainers((containers) => {
      container.xRight = modifications.xRight;
      container.yBottom = modifications.yBottom;
      container.yTop = modifications.yTop;
      container.xLeft = modifications.xLeft;

      // Container has algo target width requirements.
      if (container.algoCurrentTargetWidth !== undefined) {
        container.algoCurrentTargetWidth = calculateContainerCurrentWidth(container, pog.planogram);
      }

      // Add modified container as last to the list to make sure the container gets priority in HTML structure.
      return [...containers.filter((c) => c.uniqueId !== container.uniqueId), container];
    });
  };

  const splitContainerHorizontally = (containerToSplit: PlanogramContainer) => {
    setContainers((containers) => {
      for (const container of containers) {
        // Split container.
        if (container.uniqueId === containerToSplit.uniqueId) {
          const originalContainerWidth = getContainerWidth(container);
          container.xRight = container.xLeft + originalContainerWidth / 2;
          container.algoCurrentTargetWidth = calculateContainerCurrentWidth(
            container,
            pog.planogram,
          );

          const splitContainer: PlanogramContainer = {
            ...container,
            uniqueId: uuid(),
            xLeft: container.xRight,
            xRight: container.xRight + originalContainerWidth / 2,
          };
          splitContainer.algoCurrentTargetWidth = calculateContainerCurrentWidth(
            splitContainer,
            pog.planogram,
          );

          // Add new container.
          containers.push(splitContainer);
          break;
        }
      }

      return [...containers];
    });
  };

  const splitContainerVertically = (containerToSplit: PlanogramContainer) => {
    setContainers((containers) => {
      for (const container of containers) {
        // Split container.
        if (container.uniqueId === containerToSplit.uniqueId) {
          const originalContainerHeight = getContainerHeight(container);
          container.yTop = container.yBottom + originalContainerHeight / 2;
          container.algoCurrentTargetWidth = calculateContainerCurrentWidth(
            container,
            pog.planogram,
          );

          const splitContainer: PlanogramContainer = {
            ...container,
            uniqueId: uuid(),
            yBottom: container.yTop,
            yTop: container.yTop + originalContainerHeight / 2,
          };
          splitContainer.algoCurrentTargetWidth = calculateContainerCurrentWidth(
            splitContainer,
            pog.planogram,
          );

          // Add new container.
          containers.push(splitContainer);
          break;
        }
      }

      return [...containers];
    });
  };

  const deleteContainer = (containerToDelete: PlanogramContainer) => {
    setContainers((containers) => {
      return [
        ...containers.filter((container) => container.uniqueId !== containerToDelete.uniqueId),
      ];
    });
  };

  const onContainerMenu = (
    event: React.MouseEvent<HTMLDivElement>,
    container: PlanogramContainer,
  ) => {
    const options: MenuOption[] = [
      {
        label: "Split Horizontally",
        keybind: {
          ctrl: true,
          shift: true,
          key: Key.X,
        },
        onClick: () => splitContainerHorizontally(container),
      },
      {
        label: "Split Vertically",
        keybind: {
          ctrl: true,
          shift: true,
          key: Key.Y,
        },
        onClick: () => splitContainerVertically(container),
      },
      {
        label: "Delete",
        keybind: {
          key: Key.DELETE,
        },
        onClick: () => deleteContainer(container),
      },
    ];

    openMenu({
      event,
      menu: {
        title: container.label,
        options,
      },
    });
  };

  const getContainers = () => {
    const planogramTotalSpace = getPlanogramTotalSpace(pog.planogram);

    const splitContainers: {
      [label: string]: { totalNo: number; currentNo: number; algoTargetWidthSum: number };
    } = {};

    for (const container of containers) {
      const splitContainer = splitContainers[container.label];
      if (splitContainer) {
        splitContainer.totalNo++;
        splitContainer.algoTargetWidthSum += container.algoCurrentTargetWidth || 0;
      } else {
        splitContainers[container.label] = {
          totalNo: 1,
          currentNo: 1,
          algoTargetWidthSum: container.algoCurrentTargetWidth || 0,
        };
      }
    }

    return containers.map((container, containerIndex) => {
      const splitContainer = splitContainers[container.label];
      const splitContainerNo = splitContainer.currentNo++;

      const isInvalid =
        container.algoMinTargetWidth != undefined &&
        splitContainer.algoTargetWidthSum < container.algoMinTargetWidth;

      let label = container.label;

      if (!isContainersFullDetails) {
        const cdts = algoS2FreezerItemLabelCdtsRegex.exec(container.label);
        if (cdts?.groups) {
          label = cdts.groups.cdt2;
        }
      }

      if (splitContainer.totalNo > 1) {
        label += ` ${splitContainerNo}`;
      }

      return (
        <Container
          key={containerIndex}
          container={{
            ...container,
            label,
            algoCurrentTargetWidth: splitContainer.algoTargetWidthSum,
          }}
          planogramTotalSpace={planogramTotalSpace}
          scaleX={scaleX}
          scaleY={scaleY}
          bounds={bounds}
          snappables={getSnappables(container)}
          isDraggable={isEditable}
          isResizeable={isEditable}
          isInvalid={isInvalid}
          isHoverSensitive={isEditable || !isContainersFullDetails}
          onModify={(modifications) => {
            onModify(container, modifications);
          }}
          onEnter={() => setHoveredContainer(container)}
          onLeave={() => setHoveredContainer(null)}
          onContext={(event) => onContainerMenu(event, container)}
        />
      );
    });
  };

  return <InnerWrapper className="containers">{getContainers()}</InnerWrapper>;
};
