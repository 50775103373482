import { MasterFixture } from "@CommonApi/inputs/master-fixtures/all";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";

export const getFixturesTableColumns = () => {
  const columns: SubgridColumn<MasterFixture>[] = [
    {
      id: "master_fixture_id",
      header: "Master Fixture ID",
      justify: "right",
    },
    {
      id: "upload_id",
      header: "Upload ID",
      justify: "right",
    },
    {
      id: "category",
      header: "Category",
    },
    {
      id: "subcategory",
      header: "Subcategory",
    },
    {
      id: "section_num",
      header: "Section Number",
      justify: "right",
    },
    {
      id: "shelf_num",
      header: "Shelf Number",
      justify: "right",
    },
    {
      id: "section_width",
      header: "Section Width",
      justify: "right",
    },
    {
      id: "section_height",
      header: "Section Height",
      justify: "right",
    },
    {
      id: "shelf_depth",
      header: "Shelf Depth",
      justify: "right",
    },
    {
      id: "shelf_y",
      header: "Shelf Y",
      justify: "right",
    },
    {
      id: "shelf_type",
      header: "Shelf Type",
    },
    {
      id: "shelf_thickness",
      header: "Shelf Thickness",
      justify: "right",
    },
    {
      id: "variant_restriction",
      header: "Variant Restriction",
    },
    {
      id: "notch_spacing",
      header: "Notch Spacing",
      justify: "right",
    },
    {
      id: "notch_num",
      header: "Notch Number",
      justify: "right",
    },
    {
      id: "first_notch_y",
      header: "First Notch Y",
      justify: "right",
    },
    {
      id: "peg_notch_spacing_x",
      header: "Peg Notch Spacing X",
      justify: "right",
    },
    {
      id: "peg_notch_spacing_y",
      header: "Peg Notch Spacing Y",
      justify: "right",
    },
    {
      id: "grill_height",
      header: "Grill Height",
      justify: "right",
    },
    {
      id: "created_by",
      header: "Created By",
      justify: "right",
    },
    {
      id: "created_at",
      header: "Created At",
    },
    {
      id: "shelf_slope",
      header: "Shelf Slope",
      justify: "right",
    },
    {
      id: "stopper_height",
      header: "Stopper Height",
      justify: "right",
    },
    {
      id: "peg_notch_offset_x",
      header: "Peg Notch Offset X",
      justify: "right",
    },
    {
      id: "peg_notch_offset_y",
      header: "Peg Notch Offset Y",
      justify: "right",
    },
    {
      id: "hang_cell_limit",
      header: "Hang Cell Limit",
      justify: "right",
    },
    {
      id: "textbox_x",
      header: "Textbox X",
      justify: "right",
    },
    {
      id: "textbox_y",
      header: "Textbox Y",
      justify: "right",
    },
    {
      id: "textbox_height",
      header: "Textbox Height",
      justify: "right",
    },
    {
      id: "textbox_width",
      header: "Textbox Width",
      justify: "right",
    },
    {
      id: "textbox_text",
      header: "Textbox Text",
    },
    {
      id: "shelf_key",
      header: "Shelf Key",
    },
    {
      id: "shelf_clone",
      header: "Shelf Clone",
    },
    {
      id: "shelf_display",
      header: "Shelf Display",
    },
    {
      id: "section_multiplier",
      header: "Section Multiplier",
      justify: "right",
    },
    {
      id: "section_combine_key",
      header: "Section Combine Key",
    },
    {
      id: "lead_in_right",
      header: "Lead In Right",
    },
    {
      id: "shelf_combine",
      header: "Shelf Combine",
    },
    {
      id: "peg_id",
      header: "Peg ID",
    },
    {
      id: "peg_start_x",
      header: "Peg Start X",
      justify: "right",
    },
    {
      id: "peg_start_y",
      header: "Peg Start Y",
      justify: "right",
    },
    {
      id: "peg_end_x",
      header: "Peg End X",
      justify: "right",
    },
    {
      id: "peg_end_y",
      header: "Peg End Y",
      justify: "right",
    },
    {
      id: "can_combine",
      header: "Can Combine",
      justify: "right",
    },
    {
      id: "merchant_fixture_id",
      header: "Merchant Fixture ID",
    },
    {
      id: "assembly",
      header: "Assembly",
    },
    {
      id: "max_notch_owa",
      header: "Max Notch OWA",
      justify: "right",
    },
    {
      id: "merchandisable_shelf",
      header: "Merchandisable Shelf",
    },
    {
      id: "notch_spacing_owa",
      header: "Notch Spacing OWA",
      justify: "right",
    },
    {
      id: "planogram_base_height",
      header: "Planogram Base Height",
      justify: "right",
    },
    {
      id: "planogram_height",
      header: "Planogram Height",
      justify: "right",
    },
    {
      id: "planogram_notch_offset",
      header: "Planogram Notch Offset",
      justify: "right",
    },
    {
      id: "planogram_notch_spacing",
      header: "Planogram Notch Spacing",
      justify: "right",
    },
    {
      id: "segment_width",
      header: "Segment Width",
      justify: "right",
    },
    {
      id: "shelf_adjustment_notch_offset_calc",
      header: "Shelf Adjustment Notch Offset Calc",
      justify: "right",
    },
    {
      id: "x",
      header: "X",
      justify: "right",
    },
    {
      id: "y",
      header: "Y",
      justify: "right",
    },
    {
      id: "z",
      header: "Z",
      justify: "right",
    },
    {
      id: "type",
      header: "Type",
      justify: "right",
    },
    {
      id: "merch",
      header: "Merch",
      justify: "right",
    },
    {
      id: "peg_drop",
      header: "Peg Drop",
      justify: "right",
    },
    {
      id: "peg_gap_x",
      header: "Peg Gap X",
      justify: "right",
    },
    {
      id: "peg_gap_y",
      header: "Peg Gap Y",
      justify: "right",
    },
    {
      id: "divider_width",
      header: "Divider Width",
      justify: "right",
    },
    {
      id: "divider_height",
      header: "Divider Height",
      justify: "right",
    },
    {
      id: "divider_depth",
      header: "Divider Depth",
      justify: "right",
    },
    {
      id: "divider_at_start",
      header: "Divider At Start",
      justify: "right",
    },
    {
      id: "divider_at_end",
      header: "Divider At End",
      justify: "right",
    },
    {
      id: "dividers_between_facings",
      header: "Dividers Between Facings",
      justify: "right",
    },
  ];

  return columns;
};
