import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useApi } from "src/api";
import { useLoaders } from "src/components/InlineLoader";
import { useModals } from "src/components/Modals";
import { Button, DateInput, Flex, Input, Modal } from "src/elements";
import { RoutePutProjects_projectId_ } from "src/omni-common/projects/_projectId_";
import { projectModalAtom } from "src/pages/Projects/store/atoms";
import { useProjects } from "src/pages/Projects/store/hooks";

export const ProjectModal = () => {
  const { postProjectsApi, putProjects_projectId_Api } = useApi();
  const { closeModal } = useModals();
  const { hasLoader } = useLoaders();
  const { loadProjects } = useProjects();

  const [editableProject, setEditableProject] = useAtom(projectModalAtom);
  const [name, setName] = useState(editableProject?.name || "");
  const [goLiveDate, setGoLiveDate] = useState<Date | null>(
    editableProject?.go_live_date ? new Date(editableProject.go_live_date) : null,
  );
  const [deactivationDate, setDeactivationDate] = useState<Date | null>(
    editableProject?.deactivation_date ? new Date(editableProject.deactivation_date) : null,
  );
  const [notes, setNotes] = useState(editableProject?.notes || "");
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    return () => {
      setEditableProject(null);
    };
  }, []);

  const saveProject = async () => {
    const project = {
      name,
      go_live_date: goLiveDate ? goLiveDate.toISOString() : null,
      deactivation_date: deactivationDate ? deactivationDate.toISOString() : null,
      notes,
    };

    let response = false;
    if (editableProject) {
      response = await putProjects_projectId_Api(editableProject.project_id, project);
    } else {
      response = await postProjectsApi(project);
    }

    if (response) {
      loadProjects();
      closeProjectModal();
    }
  };

  const closeProjectModal = () => {
    closeModal("ProjectModal");
  };

  const getTitle = () => {
    if (editableProject) {
      return `Edit Project ${editableProject.project_id} - ${name}`;
    } else {
      return `New Project ${name ? `- ${name}` : ""}`;
    }
  };

  return (
    <Modal name="ProjectModal" title={getTitle()} width="500px">
      <Flex width="100%" column padding="20px" gap="20px" justify="center">
        <Flex width="100%" column gap="20px">
          <Input
            title="Project Name"
            value={name}
            setValue={(value) => {
              setName(value);
              setIsModified(true);
            }}
            isRequired
          />

          <Flex gap="20px">
            <DateInput
              title="Go-Live Date"
              value={goLiveDate}
              setValue={(value) => {
                setGoLiveDate(value);
                setIsModified(true);
              }}
            />

            <DateInput
              title="Deactivation Date"
              value={deactivationDate}
              setValue={(value) => {
                setDeactivationDate(value);
                setIsModified(true);
              }}
            />
          </Flex>

          <Input
            title="Project Notes"
            value={notes}
            setValue={(value) => {
              setNotes(value);
              setIsModified(true);
            }}
          />
        </Flex>

        <Flex justify="center" gap="20px">
          <Button
            variant="inverted"
            color={isModified ? "red" : "yellow"}
            onClick={closeProjectModal}
          >
            Cancel
          </Button>

          <Button
            isDisabled={!name}
            isLoading={hasLoader("POST_/projects") || hasLoader("PUT_/projects/:projectId")}
            onClick={saveProject}
          >
            {editableProject ? "Save" : "Create New Project"}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
