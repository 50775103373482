import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import { useAtom, useSetAtom } from "jotai";
import React from "react";
import { MenuOption, useMenu } from "src/components/Menu";
import { usePlanogramBayActions, usePlanogramShelfActions } from "src/components/Planogram/types";
import { getShelfName, getShelfWidth } from "src/omni-common/utils/pog/shelf";
import { Key } from "src/utils/keys";
import styled from "styled-components";

import { PogAtom } from "../../store/types";
import { Color, Text } from "src/elements";
import { colorToRGBA } from "src/utils/colorToRgba";
import {
  planogramHoveredShelfAtom,
  planogramSelectedShelfAtom,
} from "src/components/PlanogramExtensions/store/atoms";
import { Grill } from "./Grill";
import { unrangedShelfIdAtom } from "../Panel/panels/UnrangedItems/store/atoms";

const shelfRoundness = 3;

export const ShelfName = styled(Text)`
  position: absolute;
  top: -5px;
  left: 0;
  font-size: 10px;
  color: ${Color.white};
`;

export const ShelfBottomElement = styled.div<{
  width: number;
  thickness: number;
  type: PlanogramShelf["type"];
  scaleX: number;
  scaleY: number;
  isNewShelf: boolean;
  isSelected: boolean;
}>`
  position: relative;
  width: ${({ width, scaleX }) => `calc(${width}px * ${scaleX})`};
  height: ${({ thickness, scaleY }) => `calc(${thickness}px * ${scaleY})`};
  border: ${({ type }) => type !== "RISER" && type !== "HANGCELL" && "1px solid black"};
  background: ${({ type, isNewShelf, isSelected }) =>
    (type === "RISER" && colorToRGBA(Color.red, 0.5)) ||
    (isNewShelf && Color.primary) ||
    (isSelected && "#ff0") ||
    "var(--shelf-color)"};
  box-shadow: ${({ type }) =>
    type !== "RISER" &&
    type !== "HANGCELL" &&
    `0px 6.8px 17px 0px rgba(179, 186, 197, 0.8),
    0px 2.7px 2.7px 0px rgba(77, 77, 77, 0.75),
    0px 1.3px 5.4px 0px rgba(64, 64, 64, 0.75) inset`};

  &:first-of-type {
    border-top-left-radius: ${shelfRoundness}px;
    border-bottom-left-radius: ${shelfRoundness}px;
  }

  &:last-of-type {
    border-top-right-radius: ${shelfRoundness}px;
    border-bottom-right-radius: ${shelfRoundness}px;
  }

  pointer-events: all;
`;

interface Props {
  shelf: PlanogramShelf;
  scaleX: number;
  scaleY: number;
  isSelected: boolean;
  showShelfName: boolean;
  pogAtom: PogAtom;
}

export const ShelfBottom = ({
  shelf,
  scaleX,
  scaleY,
  isSelected,
  pogAtom,
  showShelfName,
}: Props) => {
  const {
    moveShelf,
    deleteShelf,
    duplicateShelf,
    mergeLeft,
    mergeRight,
    unmergeLeft,
    unmergeRight,
    canBeMergedLeft,
    canBeMergedRight,
    canBeMovedDown,
    canBeMovedUp,
  } = usePlanogramShelfActions(pogAtom);
  const { validateBayShelves } = usePlanogramBayActions(pogAtom);
  const { openMenu } = useMenu();

  const [selectedShelf, setSelectedShelf] = useAtom(planogramSelectedShelfAtom);
  const setHoveredShelf = useSetAtom(planogramHoveredShelfAtom);
  const setUnrangedShelfId = useSetAtom(unrangedShelfIdAtom);

  const startDuplicateShelf = () => {
    if (selectedShelf) {
      validateBayShelves(selectedShelf.uniqueId);
    }

    duplicateShelf(shelf);
  };

  const viewUnranged = (shelf: PlanogramShelf) => {
    setUnrangedShelfId(shelf.uniqueId);
  };

  const onPointerEnter = () => {
    setHoveredShelf(shelf);
  };

  const onPointerLeave = () => {
    setHoveredShelf(null);
  };

  const onShelfMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    const options: MenuOption[] = [
      canBeMovedUp(shelf)
        ? {
            label: "Move up",
            keybind: {
              key: Key.UP,
            },
            onClick: () => moveShelf(shelf, shelf.notchNo + 1),
          }
        : null,
      canBeMovedDown(shelf)
        ? {
            label: "Move down",
            keybind: {
              key: Key.DOWN,
            },
            onClick: () => moveShelf(shelf, shelf.notchNo - 1),
          }
        : null,
      {
        label: "Duplicate",
        keybind: {
          key: Key.D,
        },
        onClick: startDuplicateShelf,
      },
      shelf.mergedLeft
        ? {
            label: "Unmerge left",
            keybind: {
              key: Key.LEFT,
            },
            onClick: () => unmergeLeft(shelf),
          }
        : null,
      canBeMergedLeft(shelf)
        ? {
            label: "Merge left",
            keybind: {
              key: Key.LEFT,
            },
            onClick: () => mergeLeft(shelf),
          }
        : null,
      shelf.mergedRight
        ? {
            label: "Unmerge right",
            keybind: {
              key: Key.RIGHT,
            },
            onClick: () => unmergeRight(shelf),
          }
        : null,
      canBeMergedRight(shelf)
        ? {
            label: "Merge right",
            keybind: {
              key: Key.RIGHT,
            },
            onClick: () => mergeRight(shelf),
          }
        : null,
      {
        label: "View Unranged",
        keybind: {
          key: Key.U,
        },
        onClick: () => viewUnranged(shelf),
      },
      {
        label: "Delete",
        keybind: {
          key: Key.DELETE,
        },
        onClick: () => {
          if (shelf.uniqueId === selectedShelf?.uniqueId) {
            setSelectedShelf(null);
          }
          deleteShelf(shelf);
        },
      },
    ].filter((option) => option !== null) as MenuOption[];

    openMenu({
      event,
      menu: {
        title: `Shelf: ${getShelfName(shelf)}`,
        options,
      },
    });
  };

  return (
    <ShelfBottomElement
      width={getShelfWidth(shelf)}
      thickness={shelf.thickness}
      type={shelf.type}
      scaleX={scaleX}
      scaleY={scaleY}
      isNewShelf={shelf.uniqueId === selectedShelf?.uniqueId && selectedShelf.isNew === true}
      isSelected={isSelected}
      onContextMenu={onShelfMenu}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      <Grill height={shelf.grillHeight} scaleY={scaleY} />
      {showShelfName && <ShelfName>Shelf: {getShelfName(shelf)}</ShelfName>}
    </ShelfBottomElement>
  );
};
