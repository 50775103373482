import { Pagination, RequiredPaginationQueryParams } from "@CommonTypes/backend/pagination";

export const RouteGetInputsProductsAll = "/inputs/products/all";

export type ParamsGetInputsProductsAll = RequiredPaginationQueryParams & {
  search?: string;
  is_oos?: boolean;
};

export type ResponseGetInputsProductsAll = Pagination<Product>;

export interface Product {
  product_id: number;
  upload_id: number | null;
  upc: string | null;
  /** external id for merchant use */
  product_code: string | null;
  name: string | null;
  size: string | null;
  brand: string | null;
  category: string | null;
  subcategory: string | null;
  unit_width: number | null;
  unit_height: number | null;
  unit_depth: number | null;
  tray_width: number | null;
  tray_height: number | null;
  tray_depth: number | null;
  case_width: number | null;
  case_height: number | null;
  case_depth: number | null;
  display_height: number | null;
  display_width: number | null;
  display_depth: number | null;
  tray_number_wide: number | null;
  tray_number_deep: number | null;
  tray_number_high: number | null;
  tray_total_number: number | null;
  case_number_wide: number | null;
  case_number_deep: number | null;
  case_number_high: number | null;
  case_total_number: number | null;
  color: number | null;
  /** desc4 from PSA */
  sub_commodity: string | null;
  /** desc9 from PSA */
  internal_comments: string | null;
  is_oos: boolean | null;
  /** flag2 from PSA */
  is_new_item: boolean | null;
  /** flag3 from PSA */
  is_disco_item: boolean | null;
  shape_id: string | null;
  manufacturer: string | null;
  is_core_range: boolean;
  category_id: number | null;
}
