/* eslint-disable no-param-reassign */
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";

import { PlanogramPegboard, PlanogramPegboardItem } from "@CommonTypes/merchflow/pog/pegboard";
import {
  Pog,
  PogNonCircular,
  Planogram,
  PlanogramBay,
  PlanogramBayNonCircular,
  PlanogramItem,
  PlanogramItemNonCircular,
  PlanogramNonCircular,
  UnrangedPlanogramItem,
  UnrangedPlanogramItemNonCircular,
  latestPogVersion,
} from "../types/merchflow/pog/pog";
import { PlanogramShelf, PlanogramShelfNonCircular } from "../types/merchflow/pog/shelf";
import {
  calculatePogCoreRange,
  calculatePogDosMos,
  calculatePogReturnOnSpace,
  calculatePogShelfAlignment,
  getPlanogramShelf,
} from "../utils/pog/pog";

export const calculatePogMetrics = (pog: Pog) => ({
  returnOnSpace: calculatePogReturnOnSpace(pog),
  coreRange: calculatePogCoreRange(pog),
  shelfAlignment: calculatePogShelfAlignment(pog),
  dosMos: calculatePogDosMos(pog),
});

export const adapterPogToPogNonCircular = (pog: Pog) => {
  const pogNonCircular: PogNonCircular = {
    version: pog.version,
    containers: pog.containers,
    relaxedConstraints: pog.relaxedConstraints,
    planogram: adapterPlanogramToPlanogramNonCircular(pog.planogram),
    unrangedItems: pog.unrangedItems.map((unrangedItem) => {
      const unrangedItemNonCircular: UnrangedPlanogramItemNonCircular = {
        ...unrangedItem,
        shelf: null,
      };

      return unrangedItemNonCircular;
    }),
    metrics: pog.metrics,
    deletedItems: pog.deletedItems?.map((item) => ({ ...item, shelf: null })),
  };

  return pogNonCircular;
};

export const adapterPlanogramToPlanogramNonCircular = (planogram: Planogram) => {
  const planogramNonCircular: PlanogramNonCircular = {
    width: planogram.width,
    height: planogram.height,
    bays: planogram.bays.map((bay) => {
      const bayNonCircular: PlanogramBayNonCircular = {
        uniqueId: bay.uniqueId,
        depth: bay.depth,
        baseHeight: bay.baseHeight,
        notchOffset: bay.notchOffset,
        notchSpacing: bay.notchSpacing,
        maxNotch: bay.maxNotch,
        xLeft: bay.xLeft,
        xRight: bay.xRight,
        yBottom: bay.yBottom,
        yTop: bay.yTop,
        shelves: bay.shelves.map((shelf) => {
          const shelfNonCircular: PlanogramShelfNonCircular = {
            ...shelf,
            bay: shelf.bay.uniqueId,
            mergedLeft: shelf.mergedLeft?.uniqueId || null,
            mergedRight: shelf.mergedRight?.uniqueId || null,
            items: shelf.items.map((item) => {
              const itemNonCircular: PlanogramItemNonCircular = {
                ...item,
                shelf: item.shelf?.uniqueId || null,
              };

              return itemNonCircular;
            }),
          };

          return shelfNonCircular;
        }),
        // Safely check that pegboards exist, because old pogs don't have it.
        pegboards: bay.pegboards
          ? bay.pegboards.map((pegboard) => ({
              ...pegboard,
              bay: bay.uniqueId,
              items: pegboard.items.map((item) => ({
                ...item,
                pegboard: pegboard.uniqueId,
              })),
            }))
          : [],
      };

      return bayNonCircular;
    }),
  };

  return planogramNonCircular;
};

export const adapterPlanogramNonCircularToPlanogram = (
  planogramNonCircular: PlanogramNonCircular,
) => {
  const planogram: Planogram = {
    width: planogramNonCircular.width,
    height: planogramNonCircular.height,
    bays: [],
  };

  planogram.bays = planogramNonCircular.bays.map((bayNonCircular) => ({
    ...bayNonCircular,
    planogram,
    shelves: [],
    pegboards: [],
  }));

  for (const bayNonCircular of planogramNonCircular.bays) {
    // Build shelves.
    for (const shelfNonCircular of bayNonCircular.shelves) {
      const bay = planogram.bays.find((bay) => bay.uniqueId === shelfNonCircular.bay)!;

      // Convert non-circular shelf to circular, assign correct bay to it.
      const shelf: PlanogramShelf = {
        ...shelfNonCircular,
        bay,
        mergedLeft: null,
        mergedRight: null,
        items: [],
      };

      // Convert non-circular item to circular, assign correct shelf to it.
      for (const shelfItemNonCircular of shelfNonCircular.items) {
        const shelfItem: PlanogramItem = {
          ...shelfItemNonCircular,
          shelf,
        };

        // Add item to the shelf.
        shelf.items.push(shelfItem);
      }

      // Add shelf to the bay.
      bay.shelves.push(shelf);
    }

    // Build pegboards.
    // Safely check that pegboards exist, because old pogs don't have it.
    if (bayNonCircular.pegboards) {
      for (const pegboardNonCircular of bayNonCircular.pegboards) {
        const bay = planogram.bays.find((bay) => bay.uniqueId === pegboardNonCircular.bay)!;

        const pegboard: PlanogramPegboard = {
          ...pegboardNonCircular,
          items: [],
          bay,
        };

        // Connect pegboard items to pegboards.
        for (const pegboardItemNonCircular of pegboardNonCircular.items) {
          const pegboardItem: PlanogramPegboardItem = {
            ...pegboardItemNonCircular,
            pegboard,
          };

          pegboard.items.push(pegboardItem);
        }

        bay.pegboards?.push(pegboard);
      }
    }
  }

  // Merge shelves back together correctly.
  for (const bayNonCircular of planogramNonCircular.bays) {
    for (const shelfNonCircular of bayNonCircular.shelves) {
      if (shelfNonCircular.mergedRight) {
        const shelf = getPlanogramShelf(planogram, shelfNonCircular.uniqueId);
        const shelfRight = getPlanogramShelf(planogram, shelfNonCircular.mergedRight);

        // Assign correct shelf objects to each other.
        if (shelf && shelfRight) {
          shelf.mergedRight = shelfRight;
          shelfRight.mergedLeft = shelf;
        }
      }
    }
  }

  return planogram;
};

export const adapterPogNonCircularToPog = (pogNonCircular: PogNonCircular) => {
  const pog: Pog = {
    version: pogNonCircular.version,
    containers: pogNonCircular.containers,
    relaxedConstraints: pogNonCircular.relaxedConstraints,
    planogram: adapterPlanogramNonCircularToPlanogram(pogNonCircular.planogram),
    unrangedItems: pogNonCircular.unrangedItems.map((itemNonCircular) => ({
      ...itemNonCircular,
      shelf: null,
    })),
    deletedItems: pogNonCircular.deletedItems?.map((item) => ({
      ...item,
      shelf: null,
    })),
    metrics: pogNonCircular.metrics,
  };

  return pog;
};

export const pogVersionAdapter = (pogNonCircular: PogNonCircular) => {
  const pog: Pog = {
    ...adapterPogNonCircularToPog(pogNonCircular),
    version: latestPogVersion,
  };

  // 2.9 - add relaxConstraint
  if (pog.relaxedConstraints === undefined) {
    pog.relaxedConstraints = [];
  }
  // 2.5 - add containers
  if (pog.containers === undefined) {
    pog.containers = [];
  }

  const upgradeBay = (bay: PlanogramBay) => {
    // 2.2 - add pegboards
    if (bay.pegboards === undefined) {
      bay.pegboards = [];
    }

    // 2.7 - remove hardcoded bayNo
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (bay.bayNo !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete bay["bayNo"];
    }

    // 2.10 - order shelves from top to bottom
    bay.shelves.sort((shelf1, shelf2) => (shelf1.notchNo > shelf2.notchNo ? -1 : 1));
  };

  const upgradeShelf = (shelf: PlanogramShelf) => {
    // 2.3 - add grillHeight
    if (shelf.grillHeight === undefined) {
      shelf.grillHeight = 0;
    }

    // 2.7 - remove hardcoded shelfNo
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (shelf.shelfNo !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete shelf["shelfNo"];
    }
  };

  const upgradeItem = (item: PlanogramItem) => {
    // 2.1 - add profit
    if (item.profit === undefined) {
      item.profit = null;
    }
    // 2.4 - add dividers
    if (item.dividerWidth === undefined) {
      item.dividerWidth = 0;
    }
    if (item.dividerHeight === undefined) {
      item.dividerHeight = 0;
    }
  };

  const upgradeUnrangedItem = (unrangedItem: UnrangedPlanogramItem) => {
    upgradeItem(unrangedItem);

    // 2.6 - add potential shelves
    if (unrangedItem.potentialShelves === undefined) {
      unrangedItem.potentialShelves = [];
    }

    // 2.8 - add reasons
    if (unrangedItem.reasons === undefined) {
      unrangedItem.reasons = [];

      // @ts-ignore
      if (unrangedItem.reason !== undefined) {
        // @ts-ignore
        unrangedItem.reasons.push(unrangedItem.reason);
        // @ts-ignore
        delete unrangedItem.reason;
      }
    }
  };

  const upgradeContainer = (container: PlanogramContainer) => {
    // 2.5 - add container shelf type
    if (container.shelfType === undefined) {
      container.shelfType = "REGULAR";
    }
  };

  for (const bay of pog.planogram.bays) {
    upgradeBay(bay);

    for (const shelf of bay.shelves) {
      upgradeShelf(shelf);

      for (const item of shelf.items) {
        upgradeItem(item);
      }
    }
  }

  for (const unrangedItem of pog.unrangedItems) {
    upgradeUnrangedItem(unrangedItem);
  }

  for (const container of pog.containers) {
    upgradeContainer(container);
  }

  return adapterPogToPogNonCircular(pog);
};
