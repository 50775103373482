import { routeToUrl } from "@CommonUtils/routes";
import { StepsNavigator } from "src/components";
import { StepsNavigatorStep } from "src/components/StepsNavigator/store/types";
import { Flex } from "src/elements";
import { pages } from "src/utils";

const getSteps = ({
  merchflowId,
  s0UserTriggerId,
  s1UserTriggerId,
  templateId,
  basePogId,
  activeStep,
}: Props) => {
  const steps: StepsNavigatorStep[] = [
    {
      title: "Details",
    },
    {
      title: "CDT0 Mudmap",
      isActive: activeStep === "S0",
      url:
        s0UserTriggerId &&
        routeToUrl(pages.merchflows_merchflowId_S0_s0UserTriggerId_, {
          merchflowId,
          s0UserTriggerId,
        }),
    },
    {
      title: "CDT1 Mudmap",
      isActive: activeStep === "S1",
      url:
        s1UserTriggerId &&
        routeToUrl(pages.merchflows_merchflowId_S1_s1UserTriggerId_, {
          merchflowId,
          s1UserTriggerId,
        }),
    },
    {
      title: "CDT2 Mudmap",
      isActive: activeStep === "S2",
      url:
        s1UserTriggerId &&
        templateId &&
        routeToUrl(pages.merchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_, {
          merchflowId,
          s1UserTriggerId,
          templateId,
        }),
    },
    {
      title: "Base Planogram",
      url:
        basePogId &&
        routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
          merchflowId,
          basePogId,
        }),
      isActive: activeStep === "BASE_POG",
    },
    {
      title: "Store Planograms",
      url: routeToUrl(pages.merchflows_merchflowId_StoresAll, {
        merchflowId,
      }),
      isActive: activeStep === "STORES",
    },
  ];

  return steps;
};

interface Props {
  merchflowId: number;
  s0UserTriggerId?: number;
  s1UserTriggerId?: number;
  templateId?: string;
  basePogId?: number;
  activeStep: "S0" | "S1" | "S2" | "BASE_POG" | "STORES";
}

export const MerchflowStepsNavigator = (props: Props) => (
  <Flex width="100%" margin="20px 0">
    <StepsNavigator steps={getSteps(props)} />
  </Flex>
);
