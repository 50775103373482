import { UnrangedPlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { useAtom } from "jotai";
import React, { useRef } from "react";
import { ProductImage } from "src/components/Planogram/components/Item/Image";
import { Item } from "src/components/Planogram/components/Item/Item";
import { onUnrangedItemDragStart } from "src/components/Planogram/components/Item/store/utils";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { PogAtom } from "src/components/Planogram/store/types";
import { Button, Color, Flex, Icon, Text } from "src/elements";
import styled from "styled-components";

import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { usePogDeleteProductModal } from "src/modals/PogDeleteProduct/store/hooks";
import { useHoverClickable } from "src/utils/hoverClickable";
import { Tooltip } from "src/components";
import { UnrangedItemSortFilter } from "../UnrangedItems";
import { getPlanogramItemHeight } from "@CommonUtils/pog/item";
import { getPlanogramShelf } from "@CommonUtils/pog/pog";
import { getShelfHeight } from "@CommonUtils/pog/shelf";

const UnrangedItemElement = styled.div<{
  item: UnrangedPlanogramItem;
  isEditable: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  height: 52px;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  border: ${({ item }) =>
    `1px solid ${
      (item.newItemFlag && Color.success) || (item.inCoreRange && Color.danger) || Color.greenSmoke
    }`};
  border-radius: 3px;
  user-select: none;
  cursor: grab;
`;

interface Props {
  item: UnrangedPlanogramItem;
  scaleX: number;
  scaleY: number;
  extraInformation: UnrangedItemSortFilter;
  isRecommended: boolean;
  pogAtom: PogAtom;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"] | null;
  potentialShelfId: string;
}

export const UnrangedItem = ({
  item,
  scaleX,
  scaleY,
  extraInformation,
  isRecommended,
  pogAtom,
  availableStores,
  potentialShelfId,
}: Props) => {
  const { openPogDeleteProductModal } = usePogDeleteProductModal();

  const [pog] = useAtom(pogAtom);
  const [highlightMode] = useAtom(highlightsModeAtom);

  const refUnrangedItem = useRef<HTMLDivElement>(null);
  const refItem = useRef<HTMLDivElement>(null);

  const { isHovered } = useHoverClickable(refUnrangedItem);

  const onPointerDown = (event: React.MouseEvent<HTMLDivElement>) => {
    onUnrangedItemDragStart({
      event,
      refUnrangedItem,
      refItemContainer: refItem,
      item,
    });
  };

  const potentialShelf = getPlanogramShelf(pog.planogram, potentialShelfId);

  const isItemBiggerThanPotentialShelf = potentialShelf
    ? getShelfHeight(potentialShelf) - getPlanogramItemHeight(item) < 0
      ? true
      : false
    : false;

  const unrangedReasons = [
    potentialShelf && isItemBiggerThanPotentialShelf
      ? `Item height ${getPlanogramItemHeight(item)} is taller than shelf height ${getShelfHeight(
          potentialShelf,
        )}`
      : "",
    ...item.reasons,
  ].filter(Boolean);

  return (
    <UnrangedItemElement
      ref={refUnrangedItem}
      item={item}
      isEditable={!highlightMode}
      onPointerDown={onPointerDown}
    >
      <Flex maxWidth="min-content" align="center" gap="8px" flexGrow={1}>
        <ProductImage productCode={item.productCode} fixedSize="42px" />

        <Flex gap="10px" align="center">
          <Text variant="small1" color={Color.textMain}>
            {item.productCode}
          </Text>

          <Flex width="20px" height="20px" justify="center" align="center">
            <Icon name="info" size={14} color={Color.spaceGray} />

            <Tooltip width="170px" textAlign="left" whiteSpace="wrap">
              <Flex column gap="5px">
                {item.name && (
                  <Text variant="small2" color={Color.white}>
                    <Text variant="small1" color={Color.white}>
                      Name:&nbsp;
                    </Text>

                    {item.name}
                  </Text>
                )}

                {item.categoryCode && (
                  <Text variant="small2" color={Color.white}>
                    <Text variant="small1" color={Color.white}>
                      Category:&nbsp;
                    </Text>

                    {item.categoryCode}
                  </Text>
                )}

                {item.brand && (
                  <Text variant="small2" color={Color.white}>
                    <Text variant="small1" color={Color.white}>
                      Brand:&nbsp;
                    </Text>

                    {item.brand}
                  </Text>
                )}

                {item.variant && (
                  <Text variant="small2" color={Color.white}>
                    <Text variant="small1" color={Color.white}>
                      Variant:&nbsp;
                    </Text>

                    {item.variant}
                  </Text>
                )}

                {item.cdt1 && (
                  <Text variant="small2" color={Color.white}>
                    <Text variant="small1" color={Color.white}>
                      CDT1:&nbsp;
                    </Text>

                    {item.cdt1}
                  </Text>
                )}

                {item.cdt2 && (
                  <Text variant="small2" color={Color.white}>
                    <Text variant="small1" color={Color.white}>
                      CDT2:&nbsp;
                    </Text>

                    {item.cdt2}
                  </Text>
                )}

                {item.cdt3 && (
                  <Text variant="small2" color={Color.white}>
                    <Text variant="small1" color={Color.white}>
                      CDT3:&nbsp;
                    </Text>

                    {item.cdt3}
                  </Text>
                )}

                <Text variant="small2" color={Color.white}>
                  <Text variant="small1" color={Color.white}>
                    Movement:&nbsp;
                  </Text>

                  {item.quantity.toFixed(2)}
                </Text>

                <Text variant="small2" color={Color.white}>
                  <Text variant="small1" color={Color.white}>
                    Sales:&nbsp;
                  </Text>

                  {item.salesAmount.toFixed(2)}
                </Text>

                <Text variant="small2" color={Color.white}>
                  <Text variant="small1" color={Color.white}>
                    Profit:&nbsp;
                  </Text>

                  {item.profit !== null ? item.profit.toFixed(2) : "-"}
                </Text>

                {unrangedReasons.length > 0 && (
                  <>
                    <Text variant="small2" color={Color.white}>
                      <Text variant="small1" color={Color.white}>
                        Reasons:&nbsp;
                      </Text>
                    </Text>

                    {
                      // Trim redundant information from reason to minimize the text.
                      unrangedReasons.map((reason) => {
                        const cleanReason = reason.includes("not selected since")
                          ? reason.split("not selected since")[1].trim()
                          : reason;
                        return (
                          <Text variant="small2" color={Color.white}>
                            &bull; {cleanReason.slice(0, 1).toUpperCase() + cleanReason.slice(1)}
                          </Text>
                        );
                      })
                    }
                  </>
                )}
              </Flex>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>

      {extraInformation !== "potentialShelf" && (
        <Flex padding="3px 5px" background={Color.lightGray} borderRadius="10px">
          <Text variant="small2">
            {(
              (extraInformation === "minOnShelf" && item.minOnShelf) ||
              (extraInformation === "salesAmount" && item.salesAmount) ||
              (extraInformation === "quantity" && item.quantity) ||
              0
            ).toFixed(2)}
          </Text>
        </Flex>
      )}

      <Flex gap="10px" align="center">
        {availableStores && isHovered && (
          <Button
            size="small"
            color="red"
            variant="borderless"
            iconRight={{ name: "delete", size: 14 }}
            onClick={() =>
              openPogDeleteProductModal({
                item,
                availableStores,
              })
            }
          />
        )}

        {isRecommended && <Icon name="thumbsUp" size={14} color={Color.primary} />}

        {item.newItemFlag && (
          <Flex width="10px" height="10px" borderRadius="100%" background={Color.success} />
        )}
      </Flex>

      <Flex visibility="hidden" position="absolute">
        <Item
          refItemContainer={refItem}
          item={item}
          scaleX={scaleX}
          scaleY={scaleY}
          isEditable={!highlightMode}
          pogAtom={pogAtom}
          setIsModified={() => {}}
          availableStores={availableStores}
        />
      </Flex>
    </UnrangedItemElement>
  );
};
