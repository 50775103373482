import { useAtom } from "jotai";
import { Button, Flex, Modal, Textarea } from "src/elements";
import { merchflowNotesModalAtom } from "./store/atoms";
import { useEffect, useState } from "react";
import { useModals } from "src/components/Modals";
import { ConfirmModalProps } from "../ModalConfirm/store/types";
import { useApi } from "src/api";
import { useLoaders } from "src/components/InlineLoader";
import { RoutePutMerchflows_merchflowId_Notes } from "@CommonApi/merchflows/_merchflowId_/notes";

export const MerchflowNotesModal = () => {
  const [data, setData] = useAtom(merchflowNotesModalAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  return data ? <MerchflowNotesModalInner {...data} /> : null;
};

interface Props {
  merchflowId: number;
  notes: string;
  onSave?: (notes: string) => void;
}

const MerchflowNotesModalInner = ({ merchflowId, notes: _notes, onSave }: Props) => {
  const { closeModal: _closeModal, openConfirmModal } = useModals();
  const { putMerchflowNotesApi } = useApi();
  const { hasLoader } = useLoaders();

  const [notes, setNotes] = useState(_notes);
  const [isModified, setIsModified] = useState(false);
  const isSaveLoading = hasLoader(RoutePutMerchflows_merchflowId_Notes);

  const closeModalSafe = () => {
    if (isModified) {
      openConfirmModal(getConfirmationModalProps());
    } else {
      closeModal();
    }
  };

  const closeModal = () => {
    _closeModal("MerchflowNotesModal");
  };

  const saveNotes = async () => {
    const success = await putMerchflowNotesApi(merchflowId, { notes });
    if (success) {
      if (onSave) onSave(notes);
      closeModal();
    }
  };

  const getConfirmationModalProps = () => {
    return {
      title: "Discard Unsaved Changes?",
      description:
        "Are you sure you want to discard your changes? All unsaved notes will be permanently lost.",
      buttons: [
        {
          label: "Continue Editing",
          color: "primary",
          variant: "inverted",
        },
        {
          label: "Discard Changes",
          color: "red",
          variant: "default",
          onClick: closeModal,
        },
      ],
    } satisfies ConfirmModalProps;
  };

  return (
    <Modal
      name="MerchflowNotesModal"
      title={`Merchflow ${merchflowId} notes`}
      width="500px"
      confirmModal={isModified && getConfirmationModalProps()}
    >
      <Flex column padding="10px" gap="10px">
        <Textarea
          title="Notes"
          value={notes}
          setValue={(notes) => {
            setNotes(notes);
            setIsModified(true);
          }}
          height="200px"
        />

        <Flex gap="10px">
          <Button
            color={isModified ? "red" : "primary"}
            variant="inverted"
            width="50%"
            onClick={closeModalSafe}
          >
            {isModified ? "Cancel" : "Back"}
          </Button>

          <Button width="50%" onClick={saveNotes} isEnabled={isModified} isLoading={isSaveLoading}>
            Save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
