const keyRedirectUrl = "redirect-url";

export const getSessionStorageItem = (key: string) => sessionStorage.getItem(key);

export const setSessionStorageItem = (key: string, value: string) =>
  sessionStorage.setItem(key, value);

export const deleteSessionStorageItem = (key: string) => sessionStorage.removeItem(key);

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const getRedirectUrl = () => getSessionStorageItem(keyRedirectUrl);

export const setRedirectUrl = (url: string) => setSessionStorageItem(keyRedirectUrl, url);

export const deleteRedirectUrl = () => deleteSessionStorageItem(keyRedirectUrl);
