import { colorToRGBA } from "src/utils/colorToRgba";
import React from "react";
import { Color } from "src/elements/Color/Color";
import styled from "styled-components";

const InputContainerElement = styled.div<{
  width: string;
  isHovered?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
  isBorderless?: boolean;
}>`
  width: ${({ width }) => width};
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${Color.white};
  border-style: solid;
  border-radius: ${({ isBorderless }) => (isBorderless && "0px") || "4px"};
  border-width: ${({ isBorderless }) => (isBorderless && "0px") || "1px"};
  transition-property: border-color, box-shadow;
  transition-duration: 100ms;
  transition-timing-function: ease;
  pointer-events: ${({ isDisabled }) => isDisabled && "none"};

  border-color: ${({ isHovered, isFocused, isDisabled }) =>
    (isDisabled && Color.textDisabled) ||
    (isFocused && Color.primary) ||
    (isHovered && Color.spaceGrayHover) ||
    Color.spaceGray};

  box-shadow: ${({ isHovered, isFocused }) =>
    (isFocused && `0px 0px 7px ${colorToRGBA(Color.primary, 0.3)}`) ||
    (isHovered && `0px 0px 7px ${colorToRGBA(Color.spaceGray, 0.3)}`)};

  ::placeholder {
    color: ${({ isDisabled }) => (isDisabled && Color.textDisabled) || Color.spaceGray};
  }
`;

interface Props {
  width?: string;
  isHovered: boolean;
  isFocused: boolean;
  isDisabled?: boolean;
  isBorderless?: boolean;
  children: React.ReactNode;
}

export const InputContainer = ({
  width = "100%",
  isHovered,
  isFocused,
  isDisabled,
  isBorderless,
  children,
}: Props) => {
  return (
    <InputContainerElement
      className="input-container"
      width={width}
      isHovered={isHovered}
      isFocused={isFocused}
      isDisabled={isDisabled}
      isBorderless={isBorderless}
    >
      {children}
    </InputContainerElement>
  );
};
