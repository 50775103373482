import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import { getPogShelf } from "@CommonUtils/pog/pog";
import { getShelfName } from "@CommonUtils/pog/shelf";
import { PrimitiveAtom, useAtom, useAtomValue, useSetAtom } from "jotai";

import { usePlanogramBayActions, usePlanogramShelfActions } from "src/components/Planogram/types";
import {
  planogramSelectedShelfAtom,
  planogramSidepanelTabAtom,
} from "src/components/PlanogramExtensions/store/atoms";
import { Button, Color, Flex, SmallNumberInput, Text } from "src/elements";
import { DIMENSION } from "src/utils/formatDimension";

interface Props {
  shelf: PlanogramShelf;
  pogAtom: PrimitiveAtom<Pog>;
}

export const EditShelf = ({ shelf, pogAtom }: Props) => {
  const {
    getShelfBayNo,
    deleteShelf,
    setShelfDepth,
    setShelfThickness,
    setShelfNotchNo,
    moveShelfToBayNo,
  } = usePlanogramShelfActions(pogAtom);
  const { validateBayShelves, addNewShelf } = usePlanogramBayActions(pogAtom);

  const pog = useAtomValue(pogAtom);
  const [selectedShelf, setSelectedShelf] = useAtom(planogramSelectedShelfAtom);
  const setSidepanelTab = useSetAtom(planogramSidepanelTabAtom);

  const deleteNewShelf = () => {
    setSelectedShelf(null);

    if (selectedShelf?.isNew) {
      deleteShelf(shelf);
    }
  };

  const saveNewShelf = () => {
    setSelectedShelf(null);
    validateBayShelves(shelf.uniqueId);
  };

  const startAddingNewFixture = () => {
    setSelectedShelf(getPogShelf(pog, addNewShelf(1)));
    setSidepanelTab("ADD_EDIT_FIXTURE");
  };

  return (
    <Flex column flexGrow>
      <Flex width="100%" gap="10px" padding="10px" column flexGrow>
        <Text variant="small1" color={Color.primary}>
          Shelf: {getShelfName(shelf)}
        </Text>

        <SmallNumberInput
          label="Bay:"
          variant="sidebuttons"
          textVariant="small1"
          textColor={Color.textMain}
          width="20px"
          value={getShelfBayNo(shelf)}
          setValue={(bayNo) => moveShelfToBayNo(shelf, bayNo)}
          min={1}
          max={pog.planogram.bays.length}
        />

        <SmallNumberInput
          label="Notch:"
          variant="sidebuttons"
          textVariant="small1"
          textColor={Color.textMain}
          width="20px"
          value={shelf.notchNo}
          setValue={(notchNo) => setShelfNotchNo(shelf, notchNo)}
          min={1}
          max={shelf.bay.maxNotch}
        />

        <SmallNumberInput
          label="Depth:"
          value={shelf.depth / DIMENSION.INCH}
          setValue={(depth) => setShelfDepth(shelf, depth * DIMENSION.INCH)}
          textVariant="small1"
          textColor={Color.textMain}
        />

        <SmallNumberInput
          label="Thickness:"
          value={shelf.thickness / DIMENSION.INCH}
          setValue={(thickness) => setShelfThickness(shelf, thickness * DIMENSION.INCH)}
          textVariant="small1"
          textColor={Color.textMain}
        />
      </Flex>

      {selectedShelf?.isNew ? (
        <Flex padding="10px" width="100%" justify="between">
          <Button variant="inverted" color="red" onClick={deleteNewShelf}>
            Cancel
          </Button>

          <Button color="primary" onClick={saveNewShelf}>
            Save
          </Button>
        </Flex>
      ) : (
        <Flex padding="10px">
          <Button width="100%" variant="inverted" onClick={startAddingNewFixture}>
            Add New Shelf
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
