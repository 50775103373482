import { FilterConfig } from "@CommonTypes/backend/FilterConfig";
import { FlowReview } from "@CommonTypes/backend/FlowReview";

export const RoutePutMerchflowsFilterConfig_filterConfigId_ =
  "/merchflows/filter-config/:filterConfigId";

export type PayloadPutMerchflowsFilterConfig_filterConfigId_ = {
  filter_config: Omit<FilterConfig, "stores"> & {
    stores: Omit<FilterConfig["stores"][0], "ffUploadId" | "pmUploadId" | "posUploadId">[];
  };
};

export type ResponsePutMerchflowsFilterConfig_filterConfigId_ = FlowFilterConfig;

export const RouteGetMerchflowsFilterConfig_filterConfigId_ =
  RoutePutMerchflowsFilterConfig_filterConfigId_;

export type ResponseGetMerchflowsFilterConfig_filterConfigId_ = FlowReview;

export interface FlowFilterConfig {
  id: number;
  filter_config: FilterConfig;
  created_at: Date | null;
  review_id: number;
  base_pm_path: string | null;
  base_ff_path: string | null;
}
