const fileExtensionsToMimeType = {
  psa: "text/psa",
};

export const downloadFile = ({
  name,
  extension,
  data,
}: {
  name: string;
  extension: "psa";
  data: string;
}) => {
  const file = document.createElement("a");
  file.style.display = "none";

  file.setAttribute(
    "href",
    `data:${fileExtensionsToMimeType[extension]};charset=utf-8,${encodeURIComponent(data)}`,
  );
  file.setAttribute("download", `${name}.${extension}`);

  document.body.appendChild(file);
  file.click();
  document.body.removeChild(file);
};

export const downloadFileFromUrl = async (url: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Failed to fetch file");

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const fileNamePieces = url.split("/");
    const fileName = fileNamePieces[fileNamePieces.length - 1].split("?")[0];

    const file = document.createElement("a");
    file.style.display = "none";
    file.setAttribute("href", blobUrl);
    file.setAttribute("download", fileName);

    document.body.appendChild(file);
    file.click();
    document.body.removeChild(file);

    // Cleanup
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Download failed:", error);
  }
};
