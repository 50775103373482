import { useEffect, useRef } from "react";
import { useHighlightsApi } from "src/api/merchflows/highlights";
import { Page, Planogram } from "src/components";
import { useToasts } from "src/components/Toasts";
import { Button, Color, Flex } from "src/elements";
import {
  adapterPogNonCircularToPog,
  adapterPogToPogNonCircular,
} from "src/omni-common/adapters/pog";

import { usePlayground } from "./store/atoms";

import { useLoaders } from "src/components/InlineLoader";
import { PogAtom } from "src/components/Planogram/store/types";
import { PlanogramMetrics } from "src/components/PlanogramExtensions/PlanogramMetrics";
import { PlanogramItemSearcher } from "src/components/PlanogramExtensions/PlanogramItemSearcher";
import { PlanogramImagesMode } from "src/components/PlanogramExtensions/PlanogramImagesMode";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";
import { useApi } from "src/api";
import { RoutePutMerchflowStorePog_storePogId_ } from "@CommonApi/merchflow/store/pog/_storePogId_";
import { RoutePostMerchflows_MerchflowId_ExportsSingle } from "@CommonApi/merchflows/_merchflowId_/exports/single";

const merchflowId = 3905;
const storePogId = 13896;

export const PagePlayground = () => {
  const { getBaseHighlightsApi } = useHighlightsApi();
  const { putMerchflowStorePogApi, postExportSinglePogApi } = useApi();
  const { hasLoader } = useLoaders();
  const { containersAtom, pogAtom, pog, setPog } = usePlayground();
  const { toast } = useToasts();

  const refFile = useRef<HTMLInputElement>(null);

  const openFileUpload = () => {
    refFile.current?.click();
  };

  const onFileUpload = async (file: File) => {
    try {
      setPog(adapterPogNonCircularToPog(JSON.parse(await file.text())));
    } catch (error) {
      toast({
        title: "Invalid POG file",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        error,
      });
    }
  };

  useEffect(() => {
    getBaseHighlightsApi(1);
  }, []);

  const onExport = async () => {
    if (pog) {
      await putMerchflowStorePogApi({
        storePogId,
        payload: { pog: adapterPogToPogNonCircular(pog) },
      });
      await postExportSinglePogApi(merchflowId, {
        store_pog_id: storePogId,
        type: "psa",
      });
    }
  };

  return (
    <Page>
      <Flex direction="column" borderColor={Color.lightGrayHover} borderRadius="4px">
        <Flex justify="right" padding="10px">
          <Button onClick={openFileUpload}>Upload POG</Button>
        </Flex>
      </Flex>

      <Flex justify="between">
        <PlanogramItemSearcher />

        <Flex gap="10px" align="center">
          <PlanogramImagesMode />
        </Flex>
      </Flex>

      {pog !== null && (
        <>
          <Flex width="100%" direction="column" gap="10px" flexGrow={1} minHeight="0">
            <Planogram
              pogAtom={pogAtom as PogAtom}
              containersAtom={containersAtom}
              isEditable
              isPog
              isPanelVisible
              merchflowId={merchflowId}
              isWithBorder
            />

            <PlanogramHovererDetails />
          </Flex>

          <Flex justify="between" align="center">
            <PlanogramMetrics pog={pog} />

            <Flex gap="10px">
              <Button
                onClick={onExport}
                isLoading={
                  hasLoader(RoutePostMerchflows_MerchflowId_ExportsSingle) ||
                  hasLoader(RoutePutMerchflowStorePog_storePogId_)
                }
              >
                Export
              </Button>
            </Flex>
          </Flex>
        </>
      )}

      <input
        ref={refFile}
        type="file"
        accept="application/json"
        style={{ display: "none" }}
        onChange={(event) => {
          if (event.target.files !== null && event.target.files.length > 0) {
            onFileUpload(event.target.files[0]);
          }

          // Clear input to be able to reupload same file.
          event.target.value = "";
          event.target.files = null;
        }}
      />
    </Page>
  );
};
