import { useEffect } from "react";
import { Page, PageStatus } from "src/components";

import { useModals } from "src/components/Modals";
import { Button, Color, Flex, SubgridTable, Text } from "src/elements";
import { RouteGetUsersList } from "@CommonApi/users/list";
import { useLoaders } from "src/components/InlineLoader";
import { useUsersPage } from "./store/hooks";

export const PageUsers = () => {
  const { openModal } = useModals();
  const { hasLoader } = useLoaders();

  const { users, loadUsers } = useUsersPage();
  const isUsersLoading = users === undefined || hasLoader(RouteGetUsersList);

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <Page>
      <Flex padding="10px 0 0 0" justify="between" align="center">
        <Text variant="h3">Users</Text>

        <Button onClick={() => openModal("AddUserModal")}>Add user</Button>
      </Flex>

      {!users && isUsersLoading && <PageStatus label="Loading users..." icon="spinner" />}

      {users === null && <PageStatus label="Users not found" icon="alert" color={Color.red} />}

      {users && (
        <SubgridTable
          columns={[
            { id: "id", header: "ID", width: "100px" },
            { id: "firstName", header: "First name", width: "200px" },
            { id: "lastName", header: "Last name", width: "200px" },
            { id: "email", header: "E-mail" },
          ]}
          data={users}
        />
      )}
    </Page>
  );
};
