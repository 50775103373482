import { Color, Flex, Icon, Text } from "src/elements";
import { IconType } from "src/elements/Icon";

interface Props {
  label: string;
  icon: IconType;
  color?: string;
}

export const PageStatus = ({ label, icon, color }: Props) => (
  <Flex width="100%" height="100%" justify="center" align="center" gap="10px" column>
    <Icon name={icon} size={40} color={color} />

    <Text variant="h3" color={color || Color.spaceGray}>
      {label}
    </Text>
  </Flex>
);
