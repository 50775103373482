import { useAtom } from "jotai";
import {
  merchflowHeaderFroggerVersionAtom,
  merchflowHeaderProjectAtom,
  merchflows_merchflowId_DetailsAtom,
} from "./atoms";
import { useApi } from "src/api";

export const useMerchflowHeader = () => {
  const { getMerchflowDetailsApi, getProjectApi } = useApi();

  const [merchflowDetails, setMerchflowDetails] = useAtom(merchflows_merchflowId_DetailsAtom);
  const [project, setProject] = useAtom(merchflowHeaderProjectAtom);
  const [froggerVersion, setFroggerVersion] = useAtom(merchflowHeaderFroggerVersionAtom);

  const loadDetails = async (merchflowId: number) => {
    if (merchflowDetails?.id !== merchflowId) {
      setMerchflowDetails(await getMerchflowDetailsApi(merchflowId));
    }
  };

  const loadProject = async (projectId: number) => {
    if (project?.project_id !== projectId) {
      setProject(await getProjectApi(projectId));
    }
  };

  const saveNotes = (notes: string) => {
    if (!merchflowDetails) return;
    setMerchflowDetails({ ...merchflowDetails, notes });
  };

  return {
    merchflowDetails,
    project,
    setProject,
    loadDetails,
    loadProject,
    saveNotes,
    froggerVersion,
    setFroggerVersion,
  };
};
