import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "src/api";

import { RouteGetMerchflowBasePog_basePogId_ } from "@CommonApi/merchflow/base-pog/_basePogId_";
import { RouteGetMerchflowStorePog_storePogId_ } from "@CommonApi/merchflow/store/pog/_storePogId_";
import { RouteGetAllStoreSummary } from "@CommonApi/store-summary";
import { FlowReview } from "@CommonTypes/backend/FlowReview";
import { Page, PageStatus } from "src/components";
import { useLoaders } from "src/components/InlineLoader";
import { omniAtom } from "src/utils/atoms";
import { PdfPlanogramPage } from "./PdfPlanogramPage";

const pogAtom = omniAtom({
  key: "pages/pogExportPdf/pog",
  value: null as null | Pog,
});

export type StoreDetail = Pick<FlowReview, "category_code" | "retailer_category_code"> & {
  store: string;
};

export const PageMerchflows_ExportPdf_StorePogId = () => {
  const { storePogId: _storePogId, basePogId: _basePogId } = useParams<{
    storePogId?: string;
    basePogId?: string;
  }>();
  const storePogId = Number(_storePogId);
  const basePogId = Number(_basePogId);

  const {
    getMerchflowStorePogApi,
    getMerchflowBasePogApi,
    getMerchflowByFilterConfigIdApi,
    getMerchflowDetailsApi,
  } = useApi();
  const { hasLoader } = useLoaders();

  const [storeDetail, setStoreDetail] = useState<StoreDetail | null>(null);
  const [pog, setPog] = useAtom(pogAtom);

  const loadPog = async () => {
    let response;
    let storeDetail: StoreDetail | null = null;
    if (storePogId) {
      response = await getMerchflowStorePogApi(storePogId);
      const merchflowDetail = await getMerchflowByFilterConfigIdApi(
        response?.filter_config_id || 0,
      );
      storeDetail = {
        store: response?.store || "",
        category_code: merchflowDetail?.category_code || "",
        retailer_category_code: merchflowDetail?.retailer_category_code || "",
      };
    } else if (basePogId) {
      response = await getMerchflowBasePogApi(basePogId);
      const merchflowDetail = await getMerchflowDetailsApi(response?.merchflow_id || 0);
      storeDetail = {
        store: response?.store_code || "",
        category_code: merchflowDetail?.category_code || "",
        retailer_category_code: merchflowDetail?.retailer_category_code || "",
      };
    }

    if (response && response.pog) {
      setPog(adapterPogNonCircularToPog(response.pog));
      setStoreDetail(storeDetail);
    }
  };

  const isLoading =
    hasLoader(RouteGetMerchflowStorePog_storePogId_) ||
    hasLoader(RouteGetAllStoreSummary) ||
    hasLoader(RouteGetMerchflowBasePog_basePogId_);

  useEffect(() => {
    loadPog();

    return () => {
      setPog(null);
    };
  }, []);

  return (
    <Page hasSidebar={false}>
      {isLoading && <PageStatus label="Loading pog pdf..." icon="spinner" />}
      {!isLoading && pog && (
        <PdfPlanogramPage pogAtom={pogAtom as PrimitiveAtom<Pog>} storeDetail={storeDetail} />
      )}
    </Page>
  );
};
