import { Button, Flex, Input, Modal } from "src/elements";
import { useState } from "react";
import { useApi } from "src/api";
import { useModals } from "src/components/Modals";
import { useLoaders } from "src/components/InlineLoader";
import { RoutePostUsers } from "@CommonApi/users";
import { useUsersPage } from "src/pages/Users/store/hooks";

export const AddUserModal = () => {
  const { closeModal } = useModals();
  const { postAddUserApi } = useApi();
  const { hasLoader } = useLoaders();
  const { loadUsers } = useUsersPage();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isAddUserSuccessful, setIsAddUserSuccessful] = useState(false);
  const isAddUserLoading = hasLoader(RoutePostUsers);

  const togglePasswordVsibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const addUser = async () => {
    const response = await postAddUserApi({
      firstName,
      lastName,
      email,
      password,
    });

    if (response) {
      setIsAddUserSuccessful(true);

      setTimeout(() => {
        loadUsers();
        closeModal("AddUserModal");
      }, 1000);
    }
  };

  return (
    <Modal name="AddUserModal" title="Add User" width="300px">
      <Flex padding="20px" column gap="20px">
        <Input value={firstName} setValue={setFirstName} title="First name" placeholder="John" />

        <Input value={lastName} setValue={setLastName} title="Last name" placeholder="Doe" />

        <Input
          value={email}
          setValue={setEmail}
          title="E-mail"
          placeholder="example@omnistream.co"
        />

        <Input
          type={isPasswordVisible ? "text" : "password"}
          value={password}
          setValue={setPassword}
          title="Password"
          placeholder="Password"
          rightIcon={{
            name: isPasswordVisible ? "eyeOn" : "eyeOff",
            onClick: togglePasswordVsibility,
          }}
        />

        <Flex justify="center">
          <Button
            isDisabled={!firstName || !lastName || !email || !password}
            isLoading={isAddUserLoading}
            isSuccessful={isAddUserSuccessful}
            onClick={addUser}
          >
            Add User
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
