import { useEffect } from "react";
import { useUser } from "src/atoms/user";
import { Color, Flex, Icon, Input, Text, VerticalDivider } from "src/elements";

import { Tooltip } from "src/components";
import {
  ResponseGetMerchflows_merchflowId_Details,
  RouteGetMerchflows_merchflowId_Details,
} from "@CommonApi/merchflows/_merchflowId_/details";
import { useLoaders } from "src/components/InlineLoader";
import { useMerchflowNotesModal } from "src/modals/MerchflowNotes/store/hooks";
import { useMerchflowHeader } from "./store/hooks";
import { isDev } from "src/utils/env";

const InformationBlock = ({ title, value }: { title: string; value?: string | number | null }) => (
  <Flex column>
    <Text variant="small1" color={Color.primary}>
      {title}:
    </Text>

    <Text variant="h5" color={Color.primaryActive} isLoading={value === undefined}>
      {value}
    </Text>
  </Flex>
);

const MerchflowIdBlock = ({
  merchflowId,
  details,
}: {
  merchflowId: number;
  details: ResponseGetMerchflows_merchflowId_Details | null;
}) => {
  const { openMerchflowNotesModal: _openMerchflowNotesModal } = useMerchflowNotesModal();
  const { hasLoader } = useLoaders();
  const { saveNotes } = useMerchflowHeader();

  const isDetailsLoading = hasLoader(RouteGetMerchflows_merchflowId_Details);

  const openMerchflowNotesModal = () => {
    if (!details) return;

    _openMerchflowNotesModal({
      merchflowId,
      notes: details.notes || "",
      onSave: (notes) => saveNotes(notes),
    });
  };

  return (
    <Flex column>
      <Text variant="caption1" color={Color.primary}>
        Merchflow ID:
      </Text>

      <Flex gap="5px" align="center">
        <Text variant="body1" color={Color.primaryActive} testId="merchflowId">
          {merchflowId}
        </Text>

        {isDetailsLoading && <Icon name="spinner" size={14} />}

        {!isDetailsLoading && details && (
          <Flex onClick={openMerchflowNotesModal}>
            <Icon name="info" size={14} />
            <Tooltip>{details.notes} (Click to edit)</Tooltip>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

interface Props {
  merchflowId?: number;
  projectId?: number;
  showProjects?: boolean;
  showFrogger?: boolean;
  s0UserTriggerId?: number;
  s1UserTriggerId?: number;
  basePogId?: number;
  storeCode?: string;
  storePogId?: number;
  templateId?: string | null;
}

export const MerchflowHeader = ({
  merchflowId,
  projectId,
  showProjects,
  showFrogger = true,
  s0UserTriggerId,
  s1UserTriggerId,
  basePogId,
  storeCode,
  storePogId,
  templateId,
}: Props) => {
  const { user } = useUser();
  const { merchflowDetails, project, loadDetails, loadProject, froggerVersion, setFroggerVersion } =
    useMerchflowHeader();

  useEffect(() => {
    if (merchflowId) {
      loadDetails(merchflowId);
    }
  }, [merchflowId]);

  useEffect(() => {
    if (projectId) {
      loadProject(projectId);
    }
  }, [projectId]);

  return (
    <Flex justify="between" padding="10px" borderColor={Color.greenSmokeActive} borderRadius="3px">
      <Flex gap="15px">
        <InformationBlock title="Retailer" value={user.merchantName} />
        <VerticalDivider color={Color.greenSmokeActive} />

        {projectId && (
          <>
            <Flex align="center">
              <Text variant="h4" color={Color.primary} textTransform="uppercase">
                Pog Sets
              </Text>
            </Flex>

            <VerticalDivider color={Color.greenSmokeActive} />

            <InformationBlock title="Project ID" value={projectId} />

            <VerticalDivider color={Color.greenSmokeActive} />

            <InformationBlock title="Project Name" value={project?.name} />
          </>
        )}

        {showProjects && (
          <Flex align="center">
            <Text variant="h4" color={Color.primary} textTransform="uppercase">
              Projects
            </Text>
          </Flex>
        )}

        {merchflowId && (
          <>
            <InformationBlock title="Category" value={merchflowDetails?.retailer_category_code} />
            <VerticalDivider color={Color.greenSmokeActive} />

            <MerchflowIdBlock merchflowId={merchflowId} details={merchflowDetails} />
            <VerticalDivider color={Color.greenSmokeActive} />
          </>
        )}

        {s0UserTriggerId !== undefined && (
          <>
            <InformationBlock title="S0 ID" value={s0UserTriggerId} />

            <VerticalDivider color={Color.greenSmokeActive} />
          </>
        )}

        {s1UserTriggerId !== undefined && (
          <>
            <InformationBlock title="S1 ID" value={s1UserTriggerId} />
            <VerticalDivider color={Color.greenSmokeActive} />
          </>
        )}

        {templateId !== undefined && (
          <>
            <InformationBlock title="Template ID" value={templateId} />
            <VerticalDivider color={Color.greenSmokeActive} />
          </>
        )}

        {basePogId && (
          <>
            <InformationBlock title="Base Planogram ID" value={basePogId} />
            <VerticalDivider color={Color.greenSmokeActive} />
          </>
        )}

        {storeCode && (
          <>
            <InformationBlock title="Store Code" value={storeCode} />
            <VerticalDivider color={Color.greenSmokeActive} />
          </>
        )}

        {storePogId && (
          <>
            <InformationBlock title="Store Planoram ID" value={storePogId} />
            <VerticalDivider color={Color.greenSmokeActive} />
          </>
        )}
      </Flex>

      {isDev() && showFrogger && (
        <Flex>
          <Input
            value={froggerVersion}
            setValue={setFroggerVersion}
            title="Frogger version"
            width="120px"
          />
        </Flex>
      )}
    </Flex>
  );
};
