import { PayloadPutStores_storeId_Status } from "@CommonApi/stores/_storeId_/status";
import { RouteGetStoresList } from "@CommonApi/stores/list";
import { useAtom } from "jotai";
import { useApi } from "src/api";
import { useLoaders } from "src/components/InlineLoader";
import { usePaginationPage } from "src/elements/Pagination";
import { storesPageAtom } from "./atoms";

export const useStoresPage = () => {
  const { getStoresListApi, putStores_storeId_StatusApi } = useApi();
  const { hasLoader } = useLoaders();

  const [page, setPage] = usePaginationPage();
  const [stores, setStores] = useAtom(storesPageAtom);

  const isLoading = stores === undefined || (stores === null && hasLoader(RouteGetStoresList));

  const loadStores = async () => {
    setStores(await getStoresListApi({ page, size: 11 }));
  };

  const updateStoreStatus = async (
    storeId: number,
    status: PayloadPutStores_storeId_Status["status"],
  ) => {
    const response = await putStores_storeId_StatusApi(storeId, { status });

    if (response) {
      setStores((stores) =>
        stores
          ? {
              ...stores,
              data: stores.data.map((store) =>
                store.id === storeId ? { ...store, status } : store,
              ),
            }
          : stores,
      );
    }
  };

  return { stores, isLoading, page, setPage, loadStores, updateStoreStatus };
};
