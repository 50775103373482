import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { latestPogVersion, Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, atom, useAtom } from "jotai";
import { useMemo } from "react";
import { Planogram } from "src/components";
import { Button, Color, Flex, Skeleton, Text, Icon } from "src/elements";
import { pages, uuid } from "src/utils";
import { PlanogramMetrics } from "src/components/PlanogramExtensions/PlanogramMetrics";
import { ResponseGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_ } from "@CommonApi/merchflows/_merchflowId_/s2/_s1UserTriggerId_/s2/_templateId_";
import { routeToUrl } from "@CommonUtils/routes";

interface Props {
  merchflowId: number;
  s2Solution?: ResponseGetMerchflows_merchflowId_S2_s1UserTriggerId_Templates_templateId_[0];
}

export const OneQuadrantView = ({ merchflowId, s2Solution }: Props) => {
  const pogAtom = useMemo(() => {
    return atom(
      s2Solution?.status === "DONE" ? adapterPogNonCircularToPog(s2Solution.solution) : null,
    );
  }, [s2Solution]);

  const containersAtom = useMemo(() => {
    return atom(s2Solution?.status === "DONE" ? s2Solution.solution.containers : null);
  }, [s2Solution]);

  const [pog] = useAtom(pogAtom);
  const [containers] = useAtom(containersAtom);

  const isLoading = s2Solution?.status === "NOT_STARTED" || s2Solution?.status === "IN_PROGRESS";

  return (
    <Flex key={uuid()} padding="10px" gap="10px" column>
      <Flex width="100%" flexGrow>
        {isLoading && <Skeleton width="100%" height="100%" />}

        {s2Solution?.status === "ERROR" && (
          <Flex justify="center" align="center" column gap="10px" width="100%" height="100%">
            <Icon name="alert" color={Color.red} size={32} />

            <Text variant="h4" color={Color.red}>
              Mudmap generation failed
            </Text>
          </Flex>
        )}

        {s2Solution?.status === "DONE" && pog && containers && (
          <Planogram
            pogAtom={pogAtom as unknown as PrimitiveAtom<Pog>}
            containersAtom={containersAtom as unknown as PrimitiveAtom<PlanogramContainer[]>}
            isMetricsRecalculated={false}
          />
        )}
      </Flex>

      {s2Solution !== undefined && (
        <Flex justify="between">
          <PlanogramMetrics pog={pog} size="small" isLoading={isLoading} />

          <Button
            size="small"
            color="greenSmoke"
            isEnabled={s2Solution.status === "DONE"}
            url={
              s2Solution.status === "DONE" &&
              routeToUrl(pages.merchflows_merchflowId_S2Solution_s2SolutionId_, {
                merchflowId,
                s2SolutionId: s2Solution.s2_solution_id,
              })
            }
          >
            View
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
