import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtom } from "jotai";

import { Highlights } from "./panels/Highlights/Highlights";
import { ProductDetails } from "./panels/ProductDetails/ProductDetails";
import { UnrangedItems } from "./panels/UnrangedItems/UnrangedItems";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import {
  planogramSelectedItemAtom,
  planogramSidepanelTabAtom,
} from "src/components/PlanogramExtensions/store/atoms";
import { unrangedShelfIdAtom } from "./panels/UnrangedItems/store/atoms";
import { useEffect, useRef, useState } from "react";
import { Color, Flex, Icon } from "src/elements";
import styled from "styled-components";
import { useHoverClickable } from "src/utils/hoverClickable";
import { SidepanelButton } from "./panels/NewShelf/components/SidepanelButton";
import { ShelvesDetails } from "./panels/ShelvesDetails/ShelvesDetails";
import { NewShelf } from "./panels/NewShelf/NewShelf";

const SidepanelToggleElement = styled(Flex)<{ isExpanded: boolean }>`
  width: 100%;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
`;

const SidepanelToggle = ({
  isExpanded,
  setIsExpanded,
}: {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}) => {
  const refToggle = useRef(null);
  const { isHovered, isActive } = useHoverClickable(refToggle);

  const togglePanel = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <SidepanelToggleElement
      ref={refToggle}
      isExpanded={isExpanded}
      background={
        (isActive && Color.greenSmokeActive) ||
        (isHovered && Color.greenSmokeHover) ||
        Color.greenSmokeDisabled
      }
      onClick={togglePanel}
    >
      <Icon
        name={isExpanded ? "chevronRight" : "chevronLeft"}
        color={((isHovered || isActive) && Color.primaryActive) || Color.primary}
      />
    </SidepanelToggleElement>
  );
};

interface Props {
  scaleX: number;
  scaleY: number;
  pogAtom: PrimitiveAtom<Pog>;
  merchflowId?: number;
  currentStoreCode?: string;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"] | null;
}

export const PlanogramPanel = ({
  scaleX,
  scaleY,
  pogAtom,
  merchflowId,
  currentStoreCode,
  availableStores,
}: Props) => {
  const [tab, setTab] = useAtom(planogramSidepanelTabAtom);
  const [unrangedShelfId] = useAtom(unrangedShelfIdAtom);
  const [selectedItem] = useAtom(planogramSelectedItemAtom);

  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (unrangedShelfId) {
      setTab("UNRANGED_ITEMS");
    }
  }, [unrangedShelfId]);

  return (
    <Flex>
      {isExpanded && (
        <Flex width="320px" borderLeft={`1px solid ${Color.primary}`}>
          {tab === "UNRANGED_ITEMS" && (
            <UnrangedItems
              pogAtom={pogAtom}
              scaleX={scaleX}
              scaleY={scaleY}
              merchflowId={merchflowId}
              currentStoreCode={currentStoreCode}
              availableStores={availableStores}
            />
          )}

          {tab === "PRODUCT_DETAILS" && <ProductDetails pogAtom={pogAtom} />}

          {tab === "HIGHLIGHTS" && <Highlights merchflowId={merchflowId} pogAtom={pogAtom} />}

          {tab === "FIXTURES_DETAILS" && <ShelvesDetails pogAtom={pogAtom} />}

          {tab === "ADD_EDIT_FIXTURE" && <NewShelf pogAtom={pogAtom} />}
        </Flex>
      )}

      <Flex
        width="28px"
        column
        align="between"
        borderLeft={`1px solid ${Color.primary}`}
        background={Color.greenSmokeDisabled}
      >
        <Flex column>
          <SidepanelButton
            isActive={tab === "PRODUCT_DETAILS"}
            isDisabled={selectedItem === null}
            onClick={() => setTab("PRODUCT_DETAILS")}
          >
            Product
          </SidepanelButton>

          <SidepanelButton
            isActive={tab === "UNRANGED_ITEMS"}
            onClick={() => setTab("UNRANGED_ITEMS")}
          >
            Unranged Items
          </SidepanelButton>

          <SidepanelButton isActive={tab === "HIGHLIGHTS"} onClick={() => setTab("HIGHLIGHTS")}>
            Highlights
          </SidepanelButton>

          <SidepanelButton
            isActive={tab === "FIXTURES_DETAILS"}
            onClick={() => setTab("FIXTURES_DETAILS")}
          >
            Fixtures
          </SidepanelButton>

          <SidepanelButton
            isActive={tab === "ADD_EDIT_FIXTURE"}
            onClick={() => setTab("ADD_EDIT_FIXTURE")}
          >
            Add / Edit Fixture
          </SidepanelButton>
        </Flex>

        <SidepanelToggle isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      </Flex>
    </Flex>
  );
};
