import { colorToRGBA } from "src/utils/colorToRgba";
import { Button, Color, Flex, HorizontalDivider, Scroller, Text } from "src/elements";
import { Transition, zIndexLevel } from "src/utils";
import styled from "styled-components";
import { RadiosSection } from "./components/RadiosSection";
import { useAtom } from "jotai";
import { filtersSidebarIsVisibleAtom } from "./store/atoms";
import React, { useEffect, useState } from "react";
import { FiltersSidebarFilterSection } from "./store/types";
import { ButtonsSection } from "./components/ButtonsSection";

const Dimmer = styled(Flex)<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndexLevel.MODALS - 1};
  background-color: rgba(0, 0, 0, 0.2);
  cursor: default;
  transition: opacity ${Transition.medium};
  pointer-events: ${({ isVisible }) => !isVisible && "none"};
  opacity: ${({ isVisible }) => !isVisible && "0"};
  backdrop-filter: blur(1px);
`;

const SidebarElement = styled(Flex)<{ isVisible: boolean }>`
  position: fixed;
  width: 230px;
  height: 100vh;
  left: 0;
  bottom: 0;
  flex-direction: column;
  background-color: ${Color.white};
  z-index: ${zIndexLevel.MODALS};
  border-right: 1px solid ${Color.primary};
  box-shadow: 0 0 10px ${colorToRGBA(Color.primary, 0.5)};
  transition:
    transform ${Transition.medium},
    opacity ${Transition.medium};
  transform: ${({ isVisible }) => !isVisible && "translateX(-110%)"};
  opacity: ${({ isVisible }) => !isVisible && "0"};
`;

interface Props<T> {
  filters: FiltersSidebarFilterSection<T>[];
  data: T[] | null;
  setFilteredData: (data: T[] | null) => void;
  setPage?: (page: number) => void;
}

export const FiltersSidebar = <T extends object>({
  filters: _filters,
  data,
  setFilteredData,
  setPage,
}: Props<T>) => {
  const [isVisible, setIsVisible] = useAtom(filtersSidebarIsVisibleAtom);
  const [filters, setFilters] = useState(_filters);
  const [filteredDataCache, setFilteredDataCache] = useState(data);

  useEffect(() => {
    setFilteredDataCache(getFilteredData(data));
  }, [data, filters]);

  const getFilteredData = (data: T[] | null) =>
    data?.filter((item) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filters.every((filter) => filter.filterFunction(item, filter.value as any)),
    ) || null;

  useEffect(() => {
    setFilteredData(getFilteredData(data));
  }, [data]);

  const allFiltersExpanded = filters.find((section) => section.isExpanded !== true) === undefined;

  const clearFilters = () => {
    setFilters((filters) =>
      filters.map((section) => ({
        ...section,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        value: _filters.find((filter) => filter.id === section.id)?.value as any,
      })),
    );
  };

  const toggleExpandCollapseFilters = () => {
    setFilters((filters) =>
      filters.map((section) => ({
        ...section,
        isExpanded: !allFiltersExpanded,
      })),
    );
  };

  const applyFilters = () => {
    setFilteredData(filteredDataCache);
    closeSidebar();
    if (setPage) setPage(1);
  };

  const closeSidebar = () => {
    setIsVisible(false);
  };

  return (
    <>
      <Dimmer onClick={closeSidebar} isVisible={isVisible} />

      <SidebarElement isVisible={isVisible}>
        <Flex width="100%" minHeight="55px" align="center" padding="10px" justify="between">
          <Text variant="h5" color={Color.textMain}>
            Filters
          </Text>

          <Button
            color="greenSmoke"
            size="small"
            variant="inverted"
            onClick={toggleExpandCollapseFilters}
          >
            {allFiltersExpanded ? "Collapse all" : "Expand all"}
          </Button>
        </Flex>

        <Flex minHeight="0" flexGrow={1} gap="15px" column>
          <Scroller size="thin" gutter="stable">
            <Flex padding="0 0 0 10px" gap="5px" column>
              {filters.map((filterSection, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <HorizontalDivider />}

                  {filterSection.style === "radios" ? (
                    <RadiosSection
                      id={filterSection.id}
                      title={filterSection.title}
                      filters={filters}
                      setFilters={setFilters}
                    />
                  ) : (
                    <ButtonsSection
                      id={filterSection.id}
                      title={filterSection.title}
                      filters={filters}
                      setFilters={setFilters}
                    />
                  )}
                </React.Fragment>
              ))}
            </Flex>
          </Scroller>
        </Flex>

        <Flex padding="10px" gap="10px">
          <Button width="50%" color="red" variant="inverted" onClick={clearFilters}>
            Clear All
          </Button>

          <Button width="50%" color="greenSmoke" onClick={applyFilters}>
            Show {filteredDataCache?.length || "0"}
          </Button>
        </Flex>
      </SidebarElement>
    </>
  );
};
