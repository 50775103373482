import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { Flex, Text, Color } from "src/elements";
import { Draggable } from "../Draggable/Draggable";
import { DragBounds } from "../../store/types";

interface Props {
  container: PlanogramContainer;
  planogramTotalSpace: number;
  scaleX: number;
  scaleY: number;
  bounds?: DragBounds;
  snappables: DragBounds[];
  isDraggable?: boolean;
  isResizeable?: boolean;
  isInvalid?: boolean;
  isHoverSensitive?: boolean;
  onModify: (modification: DragBounds) => void;
  onEnter: () => void;
  onLeave: () => void;
  onContext: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const Container = ({
  container,
  planogramTotalSpace,
  scaleX,
  scaleY,
  bounds,
  snappables,
  isDraggable,
  isResizeable,
  isInvalid,
  isHoverSensitive,
  onModify,
  onEnter,
  onLeave,
  onContext,
}: Props) => {
  return (
    <Draggable
      uniqueId={container.uniqueId}
      testId="container"
      xLeft={container.xLeft}
      xRight={container.xRight}
      yBottom={container.yBottom}
      yTop={container.yTop}
      scaleX={scaleX}
      scaleY={scaleY}
      color={container.color}
      bounds={bounds}
      snappables={snappables}
      isDraggable={isDraggable}
      isResizeable={isResizeable}
      isHoverSensitive={isHoverSensitive}
      onModify={onModify}
      onEnter={onEnter}
      onLeave={onLeave}
      onContext={onContext}
      overflow="hidden"
    >
      <Flex direction="column" justify="center">
        <Text variant="caption2" color={isInvalid ? Color.red : Color.white} textAlign="center">
          {container.label.replaceAll(/['()]/g, "")}
        </Text>

        {container.algoCurrentTargetWidth !== undefined &&
          container.algoMinTargetWidth !== undefined && (
            <>
              <Text variant="caption2" color={isInvalid ? Color.red : Color.primary}>
                <Text variant="caption2" color="inherit" testId="container-algo-width">
                  {container.algoCurrentTargetWidth}
                </Text>

                <Text variant="caption2" color="inherit">
                  /
                </Text>

                <Text variant="caption2" color="inherit">
                  {container.algoMinTargetWidth}
                </Text>
              </Text>

              <Text variant="caption2" color={Color.white}>
                {parseFloat(
                  ((container.algoCurrentTargetWidth * 100) / planogramTotalSpace).toFixed(1),
                )}
                %
              </Text>
            </>
          )}
      </Flex>
    </Draggable>
  );
};
