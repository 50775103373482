import { useAtomValue } from "jotai";
import {
  planogramHoveredContainerAtom,
  planogramHoveredItemAtom,
  planogramHoveredShelfAtom,
} from "../store/atoms";
import { Flex } from "src/elements";
import { ItemDetails } from "./components/ItemDetails";
import { ContainerDetails } from "./components/ContainerDetails";
import { ShelfDetails } from "./components/ShelfDetails";
import { relaxedItemsAtom } from "src/components/Planogram/store/atoms";
import { RelaxedItemConstraint } from "@CommonTypes/merchflow/pog/pog";

export const PlanogramHovererDetails = () => {
  const hoveredItem = useAtomValue(planogramHoveredItemAtom);
  const hoveredContainer = useAtomValue(planogramHoveredContainerAtom);
  const hoveredShelf = useAtomValue(planogramHoveredShelfAtom);
  const relaxedItems = useAtomValue(relaxedItemsAtom);

  const relaxedItemsMapping = relaxedItems?.reduce(
    (total, cur) => {
      total[cur.entity.itemId] = cur;
      return total;
    },
    {} as Record<string, RelaxedItemConstraint>,
  );
  const showMagnitude = Boolean(relaxedItems?.some((item) => item.magnitude !== "fully_relaxed"));

  return (
    <Flex minHeight="24px" gap="5px" align="center" overflow="hidden">
      {hoveredItem && (
        <ItemDetails
          item={hoveredItem}
          relaxedItemsMapping={relaxedItemsMapping}
          showMagnitude={showMagnitude}
        />
      )}

      {hoveredContainer && <ContainerDetails container={hoveredContainer} />}

      {hoveredShelf && <ShelfDetails shelf={hoveredShelf} />}
    </Flex>
  );
};
