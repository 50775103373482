import React from "react";
import { Color, Grid } from "src/elements";
import styled from "styled-components";

const RowElement = styled(Grid)`
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  top: 0;

  &:nth-of-type(even) {
    background-color: ${Color.lightGrayDisabled};
  }
`;

interface Props {
  className?: string;
  backgroundHovered?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const SubgridRow = React.forwardRef<HTMLDivElement, Props>(({ children, ...props }, ref) => (
  <RowElement ref={ref} {...props}>
    {children}
  </RowElement>
));
