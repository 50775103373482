import { Page, PageStatus, Planogram } from "src/components";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { useParams } from "react-router-dom";
import { MerchflowStepsNavigator } from "src/components/merchflows/MerchflowStepsNavigator/MerchflowStepsNavigator";
import { Button, Color, Flex, Select } from "src/elements";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { useApi } from "src/api";
import { useAsyncState } from "src/hooks/useAsyncState";
import { adapterPogNonCircularToPog, adapterPogToPogNonCircular } from "@CommonAdapters/pog";
import { sleep } from "src/utils/sleep";
import { pagesS0PogAtom, pagesS0ContainersAtom } from "./store/atoms";
import { useNavigation } from "src/utils/navigation";
import { pages } from "src/utils";
import { useLoaders } from "src/components/InlineLoader";
import { RoutePostMerchflows_merchflowId_S1 } from "@CommonApi/merchflows/_merchflowId_/s1";
import { ResponseGetMerchflows_merchflowId_S0_s0UserTriggerId_ } from "@CommonApi/merchflows/_merchflowId_/s0/_s0UserTriggerId_";
import { useMerchflowHeader } from "src/components/merchflows/MerchflowHeader/store/hooks";

export const PageS0 = () => {
  const { merchflowId: _merchflowId, s0UserTriggerId: _s0UserTriggerId } = useParams();
  const merchflowId = Number(_merchflowId);
  const s0UserTriggerId = Number(_s0UserTriggerId);

  const { getMerchflowS0Api, postStartMerchflowS1Api } = useApi();
  const { navigate } = useNavigation();
  const { hasLoader } = useLoaders();
  const { froggerVersion } = useMerchflowHeader();

  const [solutionIndex, setSolutionIndex] = useState(0);
  const [data, setData] = useAsyncState<ResponseGetMerchflows_merchflowId_S0_s0UserTriggerId_>();

  const [pog, setPog] = useAtom(pagesS0PogAtom);
  const [containers, setContainers] = useAtom(pagesS0ContainersAtom);

  const isAnyGenerating = data?.find(
    (s0Solution) => s0Solution.status === "NOT_STARTED" || s0Solution.status === "IN_PROGRESS",
  );
  const isGenerating = data
    ? data[solutionIndex].status === "NOT_STARTED" || data[solutionIndex].status == "IN_PROGRESS"
    : false;
  const isDone = data ? data[solutionIndex].status === "DONE" : false;
  const isError = data ? data[solutionIndex].status === "ERROR" : false;
  const isGeneratingS1 = hasLoader(RoutePostMerchflows_merchflowId_S1);

  // Effect to load s0 solutions.
  useEffect(() => {
    loadS0();

    return () => {
      setPog(null);
      setContainers(null);
    };
  }, []);

  // Effect to update pog and containers to show correct s0 solution on screen.
  useEffect(() => {
    if (data) {
      if (data[solutionIndex].status === "DONE") {
        setPog(adapterPogNonCircularToPog({ ...data[solutionIndex].solution, containers: [] }));
        setContainers(data[solutionIndex].solution.containers);
      }
    }
  }, [data, solutionIndex]);

  const loadS0 = async () => {
    const response = await getMerchflowS0Api(merchflowId, s0UserTriggerId);
    setData(
      response?.sort((s0Solution1, s0Solution2) =>
        s0Solution1.template_code < s0Solution2.template_code ? -1 : 1,
      ),
    );

    // Keep checking for s0 after 5s cycles.
    if (
      response?.find(
        (s0Solution) => s0Solution.status === "NOT_STARTED" || s0Solution.status === "IN_PROGRESS",
      )
    ) {
      await sleep(5000);
      loadS0();
    }
  };

  const startGeneratingS1 = async () => {
    if (!pog || !containers || !data || isAnyGenerating || data[solutionIndex].status !== "DONE")
      return;

    const response = await postStartMerchflowS1Api(merchflowId, {
      s0_user_trigger_id: s0UserTriggerId,
      s0_solution_id: data[solutionIndex].s0_solution_id,
      s0_solution: { ...adapterPogToPogNonCircular(pog), containers },
      frogger_version: froggerVersion,
    });

    if (response) {
      navigate(`${pages.merchflows_merchflowId_S1_s1UserTriggerId_}`, {
        merchflowId,
        s1UserTriggerId: response.s1_user_trigger_id,
      });
    }
  };

  return (
    <Page>
      <MerchflowHeader merchflowId={merchflowId} s0UserTriggerId={s0UserTriggerId} />

      <MerchflowStepsNavigator
        merchflowId={merchflowId}
        s0UserTriggerId={s0UserTriggerId}
        activeStep="S0"
      />

      <Flex>
        <Select
          width="170px"
          title="Template"
          options={
            data
              ? data.map((s0Solution, index) => ({
                  label: s0Solution.template_code,
                  value: index,
                }))
              : []
          }
          value={solutionIndex}
          setValue={setSolutionIndex}
          isDisabled={!data}
          isLoading={data === undefined}
        />
      </Flex>

      <Flex flexGrow>
        {data === null && (
          <PageStatus label="CDT0 mudmap not found" icon="alert" color={Color.red} />
        )}

        {isGenerating && <PageStatus label="Generating CDT0 mudmap..." icon="spinner" />}

        {isError && (
          <PageStatus label="CDT0 mudmap failed to generate" icon="alert" color={Color.red} />
        )}

        {isDone && (
          <Planogram
            pogAtom={pagesS0PogAtom}
            containersAtom={pagesS0ContainersAtom}
            isEditable
            isPanelVisible
            isWithBorder
          />
        )}
      </Flex>

      <Flex justify="right">
        <Button
          isEnabled={!isAnyGenerating && isDone}
          isLoading={isGeneratingS1}
          onClick={startGeneratingS1}
        >
          Generate Base
        </Button>
      </Flex>
    </Page>
  );
};
