import { colorToRGBA } from "src/utils/colorToRgba";
import { ReactNode } from "react";

import { Flex } from "../Box/Box";
import { Text } from "../Text/Text";
import { Icon, IconType } from "../Icon/Icon";
import { Color } from "../Color/Color";

interface Props {
  width?: string;
  status?: "success" | "warning" | "error";
  children?: ReactNode;
}

export const InlineMessage = ({ width, status = "success", children }: Props) => {
  const color =
    (status === "success" && Color.primary) || (status === "warning" && Color.yellow) || Color.red;

  const icon: IconType = (status === "success" && "checkmarkCircle") || "alert";

  return (
    <Flex minHeight="26px" width={width}>
      {children && (
        <Flex
          width={width}
          background={colorToRGBA(color, 0.3)}
          borderColor={color}
          borderRadius="3px"
          align="center"
          padding="5px"
          gap="5px"
        >
          <Icon name={icon} color={color} size={14} />

          <Text variant="caption1" color={color}>
            {children}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
