import { colorToRGBA } from "src/utils/colorToRgba";
import React, { useRef } from "react";
import { MouseButton } from "src/types/mouseButtons";
import { Transition } from "src/utils";
import styled from "styled-components";

import { DragBounds, DragModifications, Scaleable, Snappable } from "../../store/types";
import { ResizeHandles } from "./components/ResizeHandles";
import { moveCoordinatesChecker } from "./utils/coordinatesChecker";

const DraggableElement = styled.div<{
  xLeft: number;
  xRight: number;
  yBottom: number;
  yTop: number;
  scaleX: number;
  scaleY: number;
  overflow?: "hidden";
  isHoverSensitive?: boolean;
  isDraggable?: boolean;
  direction?: "move" | "vertical";
}>`
  position: absolute;
  left: ${({ xLeft, scaleX }) => `var(--x, calc(${xLeft}px * ${scaleX}))`};
  bottom: ${({ yBottom, scaleY }) => `var(--y, calc(${yBottom}px * ${scaleY}))`};
  width: ${({ xLeft, xRight, scaleX }) => `var(--width, calc(${xRight - xLeft}px * ${scaleX}))`};
  height: ${({ yBottom, yTop, scaleY }) => `var(--height, calc(${yTop - yBottom}px * ${scaleY}))`};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ color }) => color && colorToRGBA(color, 0.6)};
  border: ${({ color }) => color && `1px solid ${colorToRGBA(color, 0.2)}`};
  border-radius: 2px;

  transition: opacity ${Transition.hover};
  transform: translateX(var(--drag-x, 0px)) translateY(var(--drag-y, 0px));
  overflow: ${({ overflow }) => overflow};

  // Required to activate the GPU on demand.
  &[data-dragging],
  &[data-resizing] {
    will-change: transform;
    z-index: 1;
  }

  ${({ isHoverSensitive }) =>
    isHoverSensitive &&
    `
    pointer-events: all;
      
    &:hover {
      z-index: 1;
    }
  `}

  ${({ isDraggable, direction }) =>
    isDraggable &&
    `
      cursor: ${direction === "vertical" ? "n-resize" : "grab"};
  `}

  ${({ isDraggable, color }) =>
    isDraggable &&
    color &&
    `
      &:hover, &[data-dragging], &[data-resizing] {
         will-change: transform;
         box-shadow: 0 0 12px ${colorToRGBA(color, 0.5)};
      }
  `}
`;

interface Props extends DragBounds, Scaleable {
  uniqueId?: string;
  testId?: string;
  bounds?: DragBounds;
  color?: string;
  isDraggable?: boolean;
  isResizeable?: boolean;
  isHoverSensitive?: boolean;
  moveBy?: number;
  snappables: Snappable[];
  direction?: "move" | "vertical";
  overflow?: "hidden";
  children?: React.ReactNode;
  onModify: (modification: DragModifications) => void;
  onEnter?: () => void;
  onLeave?: () => void;
  onContext?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClick?: () => void;
}

export const Draggable = ({
  uniqueId,
  testId,
  xLeft,
  xRight,
  yBottom,
  yTop,
  scaleX,
  scaleY,
  bounds = {
    xLeft,
    xRight,
    yBottom,
    yTop,
  },
  color,
  isDraggable,
  isResizeable,
  isHoverSensitive,
  moveBy = 1,
  snappables,
  direction = "move",
  overflow,
  children,
  onModify,
  onEnter,
  onLeave,
  onContext,
  onClick,
}: Props) => {
  const refDraggable = useRef<HTMLDivElement>(null);
  const refStartX = useRef(0);
  const refStartY = useRef(0);

  const refXLeft = useRef(xLeft);
  const refXRight = useRef(xRight);
  const refYBottom = useRef(yBottom);
  const refYTop = useRef(yTop);

  const onDragStart = (event: React.PointerEvent) => {
    event.stopPropagation();
    event.preventDefault();

    // Not draggable.
    if (!isDraggable || !refDraggable.current) return;

    // Dragging only with left mouse button.
    if (event.button !== MouseButton.PRIMARY) return;

    if (onClick) {
      onClick();
    }

    // Save original cursor coordinates to calculate differences on drag.
    refStartX.current = event.screenX;
    refStartY.current = event.screenY;

    // GPU go brrrr!
    refDraggable.current.setAttribute("data-dragging", "");

    // Events for dragging.
    document.body.setAttribute("data-dragging", direction);
    document.addEventListener("pointermove", onDrag);
    document.addEventListener("pointerup", onDragStop, { once: true });
  };

  const onDragStop = () => {
    // Lets end GPU misery for now.
    refDraggable.current?.removeAttribute("data-dragging");

    // Remove temporary transformation.
    refDraggable.current?.removeAttribute("style");

    // Clear drag events.
    document.body.removeAttribute("data-dragging");
    document.removeEventListener("pointermove", onDrag);

    // Update coordinates in atom state.
    if (onModify) {
      onModify({
        xLeft: refXLeft.current,
        xRight: refXRight.current,
        yBottom: refYBottom.current,
        yTop: refYTop.current,
      });
    }
  };

  const onDrag = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    // Raw drag difference on screen.
    const dragDifferenceYRaw = refStartY.current - event.screenY;
    const dragDifferenceXRaw = event.screenX - refStartX.current;

    // Find difference based on how much can be dragged in minimum.
    const dragDifferenceYScaled =
      Math.round(Math.round(dragDifferenceYRaw / scaleY) / moveBy) * moveBy;
    const dragDifferenceXScaled =
      Math.round(Math.round(dragDifferenceXRaw / scaleX) / moveBy) * moveBy;

    const {
      xLeft: xLeftNew,
      xRight: xRightNew,
      yBottom: yBottomNew,
      yTop: yTopNew,
    } = moveCoordinatesChecker({
      xLeft: xLeft + dragDifferenceXScaled,
      xRight: xRight + dragDifferenceXScaled,
      yBottom: yBottom + dragDifferenceYScaled,
      yTop: yTop + dragDifferenceYScaled,
      bounds,
      snappables,
      scaleX,
      scaleY,
    });

    // Save new coordinates to update on drag stop.
    refXLeft.current = xLeftNew;
    refXRight.current = xRightNew;
    refYBottom.current = yBottomNew;
    refYTop.current = yTopNew;

    // Move draggable on the screen.
    refDraggable.current?.setAttribute(
      "style",
      `
      --drag-x: calc(${refXLeft.current - xLeft}px * ${scaleX});
      --drag-y: calc(${refYBottom.current - yBottom}px * ${-scaleY});
      `,
    );
  };

  return (
    <DraggableElement
      ref={refDraggable}
      data-id={uniqueId}
      test-id={testId}
      xLeft={xLeft}
      xRight={xRight}
      yBottom={yBottom}
      yTop={yTop}
      scaleX={scaleX}
      scaleY={scaleY}
      color={color}
      isDraggable={isDraggable}
      isHoverSensitive={isHoverSensitive}
      direction={direction}
      overflow={overflow}
      onPointerDown={onDragStart}
      onPointerEnter={onEnter}
      onPointerLeave={onLeave}
      onContextMenu={onContext}
    >
      {children}
      {isResizeable && (
        <ResizeHandles
          xLeft={xLeft}
          xRight={xRight}
          yBottom={yBottom}
          yTop={yTop}
          scaleX={scaleX}
          scaleY={scaleY}
          refDraggable={refDraggable}
          bounds={bounds}
          snappables={snappables}
          onModify={onModify}
        />
      )}
    </DraggableElement>
  );
};
