import { PogNonCircular, TemplatePogThumbnail } from "@CommonTypes/merchflow/pog/pog";

export const RouteGetMerchflows_merchflowId_Templates = "/merchflows/:merchflowId/templates";

export type ResponseGetMerchflows_merchflowId_Templates = BasePogTemplate[];

export interface BasePogTemplate {
  template_id: string;
  total_base_pog: number;
  is_warning: boolean;
  total_favourite_base_pog: number;
  base_pog: {
    base_pog_id: number;
    base_pog_data: PogNonCircular | null;
    base_pog_status: "NOT_STARTED" | "IN_PROGRESS" | "DONE" | "ERROR";
    thumbnail: TemplatePogThumbnail;
  };
}
