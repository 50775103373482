import { PlanogramBay, Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtom, useAtomValue, useSetAtom } from "jotai";

import React from "react";
import { useMenu } from "src/components/Menu";
import { Key } from "src/utils/keys";
import styled from "styled-components";

import {
  planogramSelectedShelfAtom,
  planogramSidepanelTabAtom,
} from "src/components/PlanogramExtensions/store/atoms";
import { Color, Flex, Text } from "src/elements";
import { Scaleable } from "../../store/types";
import { usePlanogramBayActions } from "../../types";
import { getPogShelf } from "@CommonUtils/pog/pog";

const BayElement = styled(Flex)<{
  xLeft: number;
  xRight: number;
  yBottom: number;
  yTop: number;
  scaleX: number;
  scaleY: number;
}>`
  flex-direction: column;
  position: absolute;
  left: ${({ xLeft, scaleX }) => `calc(${xLeft}px * ${scaleX})`};
  bottom: ${({ yBottom, scaleY }) => `calc(${yBottom}px * ${scaleY})`};
  width: ${({ xLeft, xRight, scaleX }) => `calc(${xRight - xLeft}px * ${scaleX})`};
  height: ${({ yBottom, yTop, scaleY }) => `calc(${yTop - yBottom}px * ${scaleY})`};
  pointer-events: all;

  &:not(:first-of-type) {
    border-left: 1px dashed rgba(0, 0, 0, 0.7);
  }

  &:not(:last-of-type) {
    border-right: 1px dashed rgba(0, 0, 0, 0.7);
  }
`;

const BaseHeight = styled(Flex)<{
  baseHeight: number;
  scaleY: number;
}>`
  width: 100%;
  height: ${({ baseHeight, scaleY }) => `calc(${baseHeight}px * ${scaleY})`};
  background: linear-gradient(180deg, rgba(177, 163, 145, 1) 0%, rgba(154, 139, 120, 1) 100%);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.3);
  opacity: 0.7;
  overflow: hidden;
`;

const NotchOffset = styled(Flex)<{
  notchOffset: number;
  scaleY: number;
}>`
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: ${({ notchOffset, scaleY }) => `calc(${notchOffset}px * ${scaleY})`};
`;

const NotchLines = styled(Flex)`
  position: relative;
  flex-grow: 1;
`;

const NotchLine = styled.div<{
  notchSpacing: number;
  notchNo: number;
  scaleY: number;
}>`
  width: 100%;
  height: 2px;
  background-color: ${Color.textDisabled};
  position: absolute;
  left: 0;
  bottom: ${({ notchNo, notchSpacing, scaleY }) =>
    `calc((${notchNo - 1} * ${notchSpacing}px) * ${scaleY})`};
  opacity: 0.25;
  pointer-events: none;
`;

interface Props extends Scaleable {
  bay: PlanogramBay;
  isEditable: boolean;
  pogAtom: PrimitiveAtom<Pog>;
  children?: React.ReactNode;
}

export const Bay = ({
  bay,
  bay: { uniqueId, xLeft, xRight, yBottom, yTop, baseHeight, maxNotch, notchOffset, notchSpacing },
  scaleX,
  scaleY,
  isEditable,
  pogAtom,
  children,
}: Props) => {
  const { addNewShelf, getBayNo, validateBayShelves } = usePlanogramBayActions(pogAtom);
  const { openMenu } = useMenu();

  const pog = useAtomValue(pogAtom);
  const [selectedShelf, setSelectedShelf] = useAtom(planogramSelectedShelfAtom);
  const setPlanogramSidepanelTab = useSetAtom(planogramSidepanelTabAtom);

  const startAddNewShelf = (event: React.MouseEvent<HTMLDivElement>) => {
    if (selectedShelf) {
      validateBayShelves(selectedShelf.uniqueId);
    }

    setSelectedShelf(
      getPogShelf(pog, addNewShelf(getBayNo(bay), getNewShelfYBottomFromCursor(event))),
    );
  };

  const getNewShelfYBottomFromCursor = (event: React.MouseEvent<HTMLDivElement>) => {
    const cursorYTop = event.clientY;
    const { top, height } = (event.target as HTMLDivElement).getBoundingClientRect();

    return (
      Math.min(
        bay.maxNotch,
        Math.round((height - (cursorYTop - top)) / scaleY / bay.notchSpacing + 1),
      ) - 1
    );
  };

  const openBayMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isEditable) return;

    const options = [
      {
        label: "New Shelf",
        keybind: {
          key: Key.N,
        },
        onClick: () => startAddNewShelf(event),
      },
    ];

    openMenu({
      event,
      menu: {
        title: `Bay ${getBayNo(bay)}`,
        options,
      },
    });
  };

  const unselectSelectedShelf = () => {
    if (selectedShelf !== null) {
      setPlanogramSidepanelTab("FIXTURES_DETAILS");
      setSelectedShelf(null);
    }
  };

  return (
    <BayElement
      data-id={uniqueId}
      xLeft={xLeft}
      xRight={xRight}
      yBottom={yBottom}
      yTop={yTop}
      scaleX={scaleX}
      scaleY={scaleY}
    >
      <NotchLines onContextMenu={openBayMenu} onClick={unselectSelectedShelf} cursor="default">
        {Array.from(Array(maxNotch).keys()).map((notchIndex) => (
          <NotchLine
            key={notchIndex}
            notchSpacing={notchSpacing}
            notchNo={notchIndex + 1}
            scaleY={scaleY}
          />
        ))}
      </NotchLines>

      <NotchOffset notchOffset={notchOffset} scaleY={scaleY}>
        <BaseHeight baseHeight={baseHeight} scaleY={scaleY}>
          <Text variant="caption1" color="#867763" textTransform="uppercase" whiteSpace="nowrap">
            Bay {getBayNo(bay)}
          </Text>
        </BaseHeight>
      </NotchOffset>

      <React.Fragment>{children}</React.Fragment>
    </BayElement>
  );
};
