import { useEffect, useState } from "react";
import { Page } from "src/components";
import {
  Icon,
  Button,
  Color,
  Flex,
  Link,
  Pagination,
  Repeater,
  Scroller,
  Skeleton,
  Text,
} from "src/elements";
import styled from "styled-components";

import {
  RouteGetMerchflows_MerchflowId_Stores_StoreCode_,
  StoreVersionsView,
} from "@CommonApi/merchflows/_merchflowId_/stores/_storeCode_";
import { useLoaders } from "src/components/InlineLoader";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { PogOverview, pogOverviewHeight, pogOverviewWidth } from "./components/PogOverview";
import { useStores_storeCodeData } from "./hooks";
import { StoresGridLegend } from "../../components/merchflows/Legends";
import { useAtomValue, useSetAtom } from "jotai";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { useApi } from "src/api";
import { RouteGetMerchflow_merchflowId_FlowUpdateChangeSummary } from "@CommonApi/merchflow/_merchflowId_/flow-update/change-summary";
import { useMerchflowFlowUpdateChangeSummaryModal } from "src/modals/ChangeSummary/store/hooks";
import { StorePogsExporter } from "src/components/StorePogsExporter/StorePogsExporter";
import {
  OpenStorePlanogramReviewModal,
  useStorePlanogramReviewModal,
} from "src/modals/StorePlanogramReview/store/hooks";
import { RoutePutMerchflows_MerchflowId_StoresApprovals } from "@CommonApi/merchflows/_merchflowId_/stores/approvals";
import { pages } from "src/utils";
import { merchflows_merchflowId_StoresAllHistoryAtom } from "../Merchflows_merchflowId_StoresAll/store/atoms";
import { isPogHavingRangeRecommendations } from "@CommonUtils/pog/pog";
import { routeToUrl } from "@CommonUtils/routes";

const PogsContainer = styled(Flex)`
  border: 1px solid ${Color.lightGrayHover};
  border-radius: 3px;
  flex-grow: 1;
  min-height: 0;
`;

const dataPerRequest = 3;

export const PageMerchflows_merchflowId_Stores_storeCode = () => {
  const { getMerchflowFlowUpdateChangeSummaryApi, putMerchflowApprovalsApi } = useApi();
  const { hasLoader } = useLoaders();
  const { openMerchflowFlowUpdateChangeSummaryModal } = useMerchflowFlowUpdateChangeSummaryModal();
  const { openStorePlanogramReviewModal } = useStorePlanogramReviewModal();

  const storesAllHistory = useAtomValue(merchflows_merchflowId_StoresAllHistoryAtom);
  const setHighlightsMode = useSetAtom(highlightsModeAtom);
  const [selection, setSelection] = useState<number[]>([]);
  const [isApprovalSuccessful, setIsApprovalSuccessful] = useState(false);
  const { setPage, page, data, loadStorePogs, merchflowId, storeCode } = useStores_storeCodeData();

  const isDataLoading = hasLoader(RouteGetMerchflows_MerchflowId_Stores_StoreCode_);
  const isApprovalLoading = hasLoader(RoutePutMerchflows_MerchflowId_StoresApprovals);

  useEffect(() => {
    loadStorePogs(page);
  }, [page]);

  useEffect(() => {
    setHighlightsMode(false);
  }, []);

  const getBackToAllStoresUrl = () => {
    return (
      storesAllHistory[merchflowId] ||
      routeToUrl(pages.merchflows_merchflowId_StoresAll, { merchflowId })
    );
  };

  const onClickStorePog = (store: StoreVersionsView) => {
    if (!data || !store.storePog) return;

    openStorePlanogramReviewModal({
      merchflowId,
      storePogId: store.storePog.storePogId,
      storePlanograms: data.data.reduce(
        (storesWithPogs, row) => {
          if (!row.storePog?.storePogData) {
            return storesWithPogs;
          }

          storesWithPogs.push({
            templateId: row.templateId || "",
            storeCode,
            basePogId: row.basePog?.basePogId || 0,
            projectId: row.project_id,
            storePog: {
              storePogId: row.storePog.storePogId,
              storePogData: row.storePog.storePogData,
              isApproved: row.storePog.isApproved,
            },
          });

          return storesWithPogs;
        },
        [] as OpenStorePlanogramReviewModal["storePlanograms"],
      ),
    });
  };

  const approveSelectedStorePogs = async () => {
    if (
      await putMerchflowApprovalsApi(merchflowId, {
        approved: selection.map((storePogId) => ({ store_pog_id: storePogId })),
      })
    ) {
      setSelection([]);
      loadStorePogs(page);
      setIsApprovalSuccessful(true);

      setTimeout(() => {
        setIsApprovalSuccessful(false);
      }, 1000);
    }
  };

  const unapproveSelectedStorePogs = async () => {
    if (
      await putMerchflowApprovalsApi(merchflowId, {
        unapproved: selection.map((storePogId) => ({
          store_pog_id: storePogId,
        })),
      })
    ) {
      setSelection([]);
      loadStorePogs(page);
      setIsApprovalSuccessful(true);

      setTimeout(() => {
        setIsApprovalSuccessful(false);
      }, 1000);
    }
  };

  const openChangeSummary = async () => {
    if (selection.length !== 2) return;

    const changeSummary = await getMerchflowFlowUpdateChangeSummaryApi(merchflowId, {
      old_pog_id: Math.min(...selection),
      new_pog_id: Math.max(...selection),
    });

    if (changeSummary) {
      setSelection([]);
      openMerchflowFlowUpdateChangeSummaryModal(changeSummary);
    }
  };

  return (
    <Page>
      <MerchflowHeader merchflowId={merchflowId} storeCode={storeCode} />

      <Flex minHeight="0" flexGrow column gap="5px">
        <Flex gap="10px" justify="between" align="end">
          <Flex>
            <Link to={getBackToAllStoresUrl()}>
              <Flex height="24px" align="center" gap="5px">
                <Icon name="arrowLeft" />

                <Text variant="small1" color={Color.textMain}>
                  Back to All Stores
                </Text>
              </Flex>
            </Link>
          </Flex>

          <Flex gap="10px">
            <Button
              isLoading={hasLoader(RouteGetMerchflow_merchflowId_FlowUpdateChangeSummary)}
              isDisabled={selection.length !== 2}
              onClick={openChangeSummary}
            >
              Change Summary
            </Button>

            <Button
              isLoading={isApprovalLoading}
              isSuccessful={isApprovalSuccessful}
              dropdown={[
                {
                  label: "Approve selected",
                  onClick: approveSelectedStorePogs,
                  isDisabled: selection.length === 0,
                },
                {
                  label: "Unapprove selected",
                  onClick: unapproveSelectedStorePogs,
                  isDisabled: selection.length === 0,
                },
              ]}
            >
              Approvals
            </Button>

            <StorePogsExporter merchflowId={merchflowId} storePogsIds={selection} />
          </Flex>
        </Flex>

        <PogsContainer>
          <Scroller>
            <Flex padding="20px" gap="20px" column>
              {isDataLoading && (
                <Repeater times={dataPerRequest}>
                  <Skeleton width={pogOverviewWidth} height={pogOverviewHeight} />
                </Repeater>
              )}

              {!isDataLoading &&
                data !== null &&
                data.data.map((store: StoreVersionsView, i) => {
                  const inProgress =
                    store.storePog.status === "NOT_STARTED" ||
                    store.storePog.status === "POGGERIZE_IN_PROGRESS" ||
                    store.storePog.status === "POGGER_IN_PROGRESS" ||
                    store.storePog.status === "UPDATE_IN_PROGRESS";

                  return (
                    <PogOverview
                      key={i}
                      status={
                        (store.storePog.status === "ERROR" && "ERROR") ||
                        (inProgress && "IN_PROGRESS") ||
                        (store.storePog.isApproved && "SUCCESS") ||
                        "BORDER"
                      }
                      label={`Store POG: ${store.storePog.storePogId}`}
                      pog={store.storePog.storePogData}
                      selectionId={store.storePog.storePogId}
                      selection={selection}
                      setSelection={setSelection}
                      warning={
                        store.storePog.storePogData &&
                        isPogHavingRangeRecommendations(store.storePog.storePogData) &&
                        "Range recommendation"
                      }
                      thumbnail={store.storePog.thumbnail}
                      statusMessage={
                        (store.storePog.status === "ERROR" && store.storePog.error) ||
                        (inProgress && "Loading planogram...")
                      }
                      onClick={
                        store.storePog.status === "DONE" ? () => onClickStorePog(store) : undefined
                      }
                    />
                  );
                })}
            </Flex>
          </Scroller>
        </PogsContainer>

        <Flex justify="between">
          <StoresGridLegend />

          <Pagination page={page} totalPages={1} setPage={setPage} isLoading={isDataLoading} />
        </Flex>
      </Flex>
    </Page>
  );
};
