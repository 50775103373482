import { OneStoreView } from "@CommonTypes/merchflow/OneStoreView";
import { routeToUrl } from "@CommonUtils/routes";
import { Dispatch, SetStateAction } from "react";
import { Metric, Tooltip } from "src/components";
import { Checkbox, Color, Flex, Icon, Link, Text } from "src/elements";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";
import { OpenBasePlanogramReviewModal } from "src/modals/BasePlanogramReview/store/hooks";
import { OpenStorePlanogramReviewModal } from "src/modals/StorePlanogramReview/store/hooks";
import { StorePogLogsButton } from "src/pages/Merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_/components/StorePogLogsButton";
import { pages } from "src/utils";
import { getCurrentUrl } from "src/utils/history";
import styled from "styled-components";

const ApprovedBubble = styled(Flex)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${Color.primary};
`;

export const getStoresAllTableColumns = ({
  merchflowId,
  setStoresAllHistory,
  selection,
  setSelection,
  isAllSelected,
  setIsAllSelected,
  openBasePlanogramReviewModal,
  openStorePlanogramReviewModal,
  data,
}: {
  merchflowId: number;
  setStoresAllHistory: Dispatch<
    SetStateAction<{
      [merchflowId: number]: string;
    }>
  >;
  selection: number[];
  setSelection: (selection: number[]) => void;
  isAllSelected: boolean;
  setIsAllSelected: (isAllSelected: boolean) => void;
  openBasePlanogramReviewModal: (data: OpenBasePlanogramReviewModal) => void;
  openStorePlanogramReviewModal: (data: OpenStorePlanogramReviewModal) => void;
  data: OneStoreView[];
}) => {
  return [
    {
      id: "selection",
      header: "selection",
      justify: "center",
      headerRenderer: (
        <Checkbox width="min-content" isChecked={isAllSelected} setIsChecked={setIsAllSelected} />
      ),
      renderer: ({ row: { store_pog } }) => (
        <Checkbox
          width="min-content"
          isChecked={
            store_pog !== undefined
              ? isAllSelected
                ? selection.includes(store_pog.store_pog_id)
                  ? false
                  : true
                : selection.includes(store_pog.store_pog_id)
              : false
          }
          setIsChecked={() => {
            if (store_pog !== undefined) {
              if (selection.includes(store_pog.store_pog_id)) {
                setSelection(selection.filter((s) => s !== store_pog.store_pog_id));
              } else {
                setSelection([...selection, store_pog.store_pog_id]);
              }
            }
          }}
          isDisabled={store_pog === undefined}
        />
      ),
    },
    {
      id: "store",
      header: "Store Code",
      renderer: ({ row }) => (
        <Link
          to={`${routeToUrl(pages.merchflows_merchflowId_Stores_storeCode_, {
            merchflowId,
            storeCode: row.store,
          })}`}
          onClick={() => {
            setStoresAllHistory((history) => ({
              ...history,
              [merchflowId]: getCurrentUrl(),
            }));
          }}
        >
          <Text variant="small2">{row.store}</Text>
        </Link>
      ),
    },
    {
      id: "status",
      header: "Store Status",
      justify: "center",
      renderer: ({ row }) => (
        <>
          {row.approved_store_pog_amount === 1 && <Icon name="checkmarkCircle" size={14} />}

          {row.approved_store_pog_amount > 1 && <Icon name="alert" color={Color.red} size={14} />}
        </>
      ),
    },
    {
      id: "template",
      header: "Template",
      subColumns: [
        {
          id: "templateId",
          header: "ID",
          justify: "center",
          renderer: ({ row }) =>
            row.base_pog && (
              <Link
                to={`${routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
                  merchflowId,
                  basePogId: row.base_pog.base_pog_id,
                })}`}
                onClick={(event) => {
                  event.preventDefault();
                  if (row.base_pog) {
                    openBasePlanogramReviewModal({
                      merchflowId,
                      basePogId: row.base_pog.base_pog_id,
                      basePog: row.base_pog.base_pog_data,
                    });
                  }
                }}
              >
                <Text variant="small2">{row.base_pog.base_pog_id}</Text>
              </Link>
            ),
        },
      ],
    },
    {
      id: "planogram",
      header: "Latest Planogram",
      subColumns: [
        {
          id: "planogramId",
          header: "ID",
          justify: "center",
          renderer: ({ row }) =>
            row.store_pog && (
              <Link
                to={`${routeToUrl(pages.merchflows_merchflowId_Stores_storeCode_Pogs_storePogId_, {
                  merchflowId,
                  storeCode: row.store,
                  storePogId: row.store_pog.store_pog_id,
                })}`}
                onClick={(event) => {
                  event.preventDefault();
                  if (!row.store_pog.store_pog_data) return;

                  openStorePlanogramReviewModal({
                    merchflowId,
                    storePogId: row.store_pog.store_pog_id,
                    storePlanograms: data.reduce(
                      (storesWithPogs, row) => {
                        if (!row.store_pog?.store_pog_data) {
                          return storesWithPogs;
                        }

                        storesWithPogs.push({
                          templateId: row.template_id,
                          storeCode: row.store,
                          basePogId: row.base_pog?.base_pog_id || 0,
                          storePog: {
                            storePogId: row.store_pog.store_pog_id,
                            storePogData: row.store_pog.store_pog_data,
                            isApproved: false,
                          },
                        });

                        return storesWithPogs;
                      },
                      [] as OpenStorePlanogramReviewModal["storePlanograms"],
                    ),
                  });
                }}
              >
                <Text variant="small2">{row.store_pog.store_pog_id}</Text>
              </Link>
            ),
        },
        {
          id: "planogramApproved",
          header: "Approved",
          justify: "center",
          renderer: ({ row }) => (
            <>
              {row.approved_store_pog_amount === 1 && (
                <Flex height="min-content" minHeight="14px" align="center">
                  <ApprovedBubble />
                </Flex>
              )}
            </>
          ),
        },
        {
          id: "planogramStatus",
          header: "Status",
          justify: "center",
          renderer: ({ row }) => {
            switch (row.store_pog.status) {
              case "NOT_STARTED":
              case "POGGERIZE_IN_PROGRESS":
              case "POGGER_IN_PROGRESS":
              case "UPDATE_IN_PROGRESS":
                return <Icon name="spinner" size={14} />;
              case "DONE":
                return <Icon name="checkmarkCircle" size={14} />;
              case "ERROR":
                return (
                  <Flex cursor="pointer">
                    <Icon name="warningCircle" size={14} color={Color.red} />
                    {row.store_pog.error && (
                      <Tooltip width="300px" whiteSpace="wrap" isClickable>
                        {row.store_pog.error}
                      </Tooltip>
                    )}
                  </Flex>
                );
            }
          },
        },
      ],
    },
    {
      id: "returnOnSpace",
      header: "Return on Space",
      width: "120px",
      justify: "center",
      renderer: ({ row }) =>
        row.store_pog && (
          <Metric percent={row.store_pog.store_pog_data?.metrics?.returnOnSpace} isInline />
        ),
    },
    {
      id: "coreRange",
      header: "Core Range",
      width: "120px",
      justify: "center",
      renderer: ({ row }) =>
        row.store_pog && (
          <Metric percent={row.store_pog.store_pog_data?.metrics?.coreRange} isInline />
        ),
    },
    {
      id: "shelfAlignment",
      header: "Shelf Alignment",
      width: "120px",
      justify: "center",
      renderer: ({ row }) =>
        row.store_pog && (
          <Metric percent={row.store_pog.store_pog_data?.metrics?.shelfAlignment} isInline />
        ),
    },
    {
      id: "dosMos",
      header: "DOS/MOS",
      width: "120px",
      justify: "center",
      renderer: ({ row }) =>
        row.store_pog && (
          <Metric percent={row.store_pog.store_pog_data?.metrics?.dosMos} isInline />
        ),
    },
    {
      id: "size",
      header: "Size",
      justify: "center",
      renderer: ({ row }) => row.store_pog.store_pog_data?.planogram.bays.length,
    },
    {
      id: "logs",
      header: "Logs",
      justify: "center",
      renderer: ({ row }) => (
        <StorePogLogsButton storePogId={row.store_pog.store_pog_id} isCompact />
      ),
    },
  ] satisfies SubgridColumn<OneStoreView>[];
};
