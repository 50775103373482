import { ResponseGetMerchflows_merchflowId_Details } from "@CommonApi/merchflows/_merchflowId_/details";
import { Project } from "src/omni-common/projects";
import { omniAtom } from "src/utils/atoms";

const prefix = "merchflowHeader";

export const merchflows_merchflowId_DetailsAtom = omniAtom({
  prefix,
  key: "details",
  value: null as null | ResponseGetMerchflows_merchflowId_Details,
});

export const merchflowHeaderFroggerVersionAtom = omniAtom({
  prefix,
  key: "froggerVersion",
  value: "",
});

export const merchflowHeaderProjectAtom = omniAtom({
  prefix,
  key: "project",
  value: undefined as Project | null | undefined,
});
