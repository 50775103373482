export const RouteGetProjects = "/projects";

export type ResponseGetProjects = Project[];

export const RoutePostProjects = "/projects";

export type PayloadPostProjects = Omit<Project, "project_id">;

export interface Project {
  project_id: number;
  name: string;
  go_live_date: string | null;
  deactivation_date: string | null;
  notes: string;
}
