import { Category } from "@CommonApi/categories/list";
import { RoutePostMerchflowUploadVariantRestrictions } from "@CommonApi/merchflow/upload-variant-restrictions";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useApi } from "src/api";
import { FileUploader } from "src/components";
import { useLoaders } from "src/components/InlineLoader";
import { Button, Flex, Modal } from "src/elements";
import { useAsyncState } from "../../hooks/useAsyncState";
import { importVariantRestrictionModalDataAtom } from "./store/atoms";
interface Props {
  category: Category;
}

export const ModalImportVariantRestriction = () => {
  const [data, setData] = useAtom(importVariantRestrictionModalDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) {
    return null;
  }

  return <ModalImportVariantRestrictionInner category={data} />;
};

const ModalImportVariantRestrictionInner = ({ category }: Props) => {
  const { hasLoader } = useLoaders();
  const { postMerchflowUploadVariantRestrictionsApi } = useApi();

  const [variantRestrictionFile, setVariantRestrictionFile] = useState<File | null>(null);
  const [variantRestrictionResponse, setVariantRestrictionResponse] = useAsyncState<object | null>(
    null,
  );

  const isUploadVariantRestrictionLoading = hasLoader(RoutePostMerchflowUploadVariantRestrictions);

  const uploadVariantRestriction = async () => {
    if (!variantRestrictionFile) return;

    setVariantRestrictionResponse(
      await postMerchflowUploadVariantRestrictionsApi({
        csv_file: variantRestrictionFile,
        category_id: String(category.id),
      }),
    );
  };

  return (
    <Modal
      name="ModalImportVariantRestriction"
      title={`Import Variant Restriction for Category: ${category.code}`}
      width="500px"
    >
      <Flex padding="10px" column gap="16px">
        <Flex gap="10px">
          <FileUploader
            fileLabel="Variant Restriction"
            fileType={"csv"}
            onChange={setVariantRestrictionFile}
            isLoading={isUploadVariantRestrictionLoading}
          />
        </Flex>

        <Button
          isDisabled={
            !variantRestrictionFile ||
            isUploadVariantRestrictionLoading ||
            Boolean(variantRestrictionResponse)
          }
          onClick={uploadVariantRestriction}
        >
          Upload
        </Button>
      </Flex>
    </Modal>
  );
};
