import { Pagination } from "@CommonTypes/backend/pagination";

export const RouteGetAllStoreSummary = "/store-summary";

export const RoutePostImportStoreSummary = "/store-summary/import";

export interface PayloadPostStoreSummary {
  file: File;
  category_id: number;
}

export type ResponseGetAllStoreSummary = Pagination<StoreSummary>;

export interface StoreSummary {
  id: number;
  upload_id: number | null;
  retailer_category_code: string | null;
  retailer: string | null;
  category_code: string | null;
  store: string | null;
  store_code: string | null;
  state: string | null;
  template_id: string | null;
  cluster: string | null;
  pog_id: string | null;
  bay: number | null;
  total_bays: number | null;
  section_height: number | null;
  section_width: number | null;
  template: string | null;
  template_type: string | null;
  extra_info_1: string | null;
  extra_info_2: string | null;
  exclude: boolean | null;
  store_name: string | null;
  created_at: Date;
  status: boolean | null;
  category_id: number | null;
  filename: string | null;
  base_pog_flag: boolean | null;
  base_store_code: string | null;
}
