import React, { SyntheticEvent, useEffect, useRef } from "react";
import { AnchorLayerState } from "./store/types";
import { Flex } from "../Box/Box";
import styled from "styled-components";
import { zIndexLevel } from "src/utils";

const AnchorLayerElement = styled(Flex)<{
  visibleState: AnchorLayerState;
}>`
  position: fixed;
  top: ${({ visibleState }) => (visibleState ? `${visibleState.yTop}px` : undefined)};
  left: ${({ visibleState }) => (visibleState ? `${visibleState.xLeft}px` : undefined)};
  visibility: ${({ visibleState }) => (visibleState ? "visible" : "hidden")};
  pointer-events: auto;
`;

const AnchorLayerCloser = styled(Flex)<Pick<Props, "isUsingClick">>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${zIndexLevel.ABSOLUTE_LAYER};
  pointer-events: ${({ isUsingClick }) => (isUsingClick ? "auto" : "none")};
  cursor: default;
`;

interface Props {
  refTrigger?: React.MutableRefObject<HTMLElement | null>;
  refLayer: React.RefObject<HTMLDivElement | null>;
  isRendering: boolean;
  layerState: AnchorLayerState;
  isUsingParentWidth?: boolean;
  isUsingClick?: boolean;
  hideAnchorLayer: () => void;
  children: React.ReactNode;
}

export const AnchorLayer = ({
  refLayer,
  isRendering,
  layerState,
  hideAnchorLayer,
  isUsingClick,
  isUsingParentWidth,
  refTrigger,
  children,
}: Props) => {
  const refContainer = useRef<HTMLDivElement>(null);

  // When using parent, use container as trigger.
  useEffect(() => {
    if (refTrigger && refContainer.current) {
      refTrigger.current = refContainer.current as HTMLElement;
    }
  }, [refTrigger, refContainer, refContainer.current]);

  return (
    <Flex ref={refContainer}>
      {isRendering ? (
        <AnchorLayerCloser
          isUsingClick={isUsingClick}
          onClick={(event: SyntheticEvent) => {
            event.stopPropagation();
            hideAnchorLayer();
          }}
        >
          <AnchorLayerElement
            minWidth={isUsingParentWidth ? layerState?.parentWidth : undefined}
            ref={refLayer}
            visibleState={layerState}
          >
            {children}
          </AnchorLayerElement>
        </AnchorLayerCloser>
      ) : null}
    </Flex>
  );
};
