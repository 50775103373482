import { FlowReview } from "@CommonTypes/backend/FlowReview";

export const RouteGetMerchflows_merchflowId_Details = "/merchflows/:merchflowId/details";

export type ResponseGetMerchflows_merchflowId_Details = Omit<FlowReview, "config"> & {
  config: {
    option: MerchFlowsReviewCoreRange;
  };
  latest_base_pog_id: number | null;
};

export const enum MerchFlowsReviewCoreRange {
  ALL = "all",
  NONE = "none",
  PARTIAL = "partial",
  CSV = "csv",
}
