export const routeToUrl = (
  route: string,
  params?: { [key: string]: string | number },
  searchParams?: { [key: string]: string | number | undefined },
) => {
  let url = route;

  const cleanedParams: { [key: string]: string } = {};

  for (const key in params) {
    cleanedParams[key] = String(params[key]);
  }

  Object.keys(cleanedParams).forEach((param) => {
    url = url.replace(`:${param}`, cleanedParams[param]);
  });

  if (searchParams) {
    url += "?";

    for (const [key, value] of Object.entries(searchParams)) {
      if (value !== undefined) {
        url += `${key}=${value}&`;
      }
    }

    url = url.replace(/&$/, "");
    url = url.replace(/\?$/, "");
  }

  return url;
};
