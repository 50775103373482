import { useAtom } from "jotai";

import { loadersAtom } from "./atoms";
import { Loader } from "./types";

export const useLoaders = () => {
  const [inlineLoaders, setInlineLoaders] = useAtom(loadersAtom);

  const addLoader = (loader: Loader) => {
    setInlineLoaders((loaders) => [...loaders, loader]);
  };

  const removeLoader = (loader: Loader) => {
    setInlineLoaders((loaders) => loaders.filter((l) => l !== loader));
  };

  const hasLoader = (loader: Loader) => {
    return inlineLoaders.includes(loader);
  };

  return {
    addLoader,
    removeLoader,
    hasLoader,
  };
};
