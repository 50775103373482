import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtom } from "jotai";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "src/api";

import { Page, PageStatus, Planogram } from "src/components";
import { omniAtom } from "src/utils/atoms";
import { emptyContainersAtom } from "../merchandise/store/atoms";
import { useLoaders } from "src/components/InlineLoader";
import { RouteGetMerchflowStorePog_storePogId_ } from "@CommonApi/merchflow/store/pog/_storePogId_";
import { RouteGetMerchflowBasePog_basePogId_ } from "@CommonApi/merchflow/base-pog/_basePogId_";
import { Flex } from "src/elements";

const pogAtom = omniAtom({
  key: "pages/pogThumbnail/pog",
  value: null as null | Pog,
});

export const PageMerchflowsPogThumbnail = () => {
  const { basePogId: _basePogId, storePogId: _storePogId } = useParams<{
    basePogId?: string;
    storePogId?: string;
  }>();
  const basePogId = Number(_basePogId);
  const storePogId = Number(_storePogId);

  const { getMerchflowBasePogApi, getMerchflowStorePogApi } = useApi();
  const { hasLoader } = useLoaders();

  const [pog, setPog] = useAtom(pogAtom);
  const isLoading =
    hasLoader(RouteGetMerchflowStorePog_storePogId_) ||
    hasLoader(RouteGetMerchflowBasePog_basePogId_);

  useEffect(() => {
    loadPog();

    return () => {
      setPog(null);
    };
  }, []);

  const loadPog = async () => {
    const response = basePogId
      ? await getMerchflowBasePogApi(basePogId)
      : await getMerchflowStorePogApi(storePogId);

    if (response && response.pog) {
      setPog(adapterPogNonCircularToPog(response.pog));
    }
  };

  return (
    <Page>
      {isLoading && (
        <PageStatus
          label={`Loading ${basePogId ? "base" : "store"} planogram thumbnail...`}
          icon="spinner"
        />
      )}

      {!isLoading && pog && (
        <Flex width="377px" height="222px">
          <Planogram
            pogAtom={pogAtom as PrimitiveAtom<Pog>}
            containersAtom={emptyContainersAtom}
            isRenderingAsImage
            isPog
            isImageSentToConsole
          />
        </Flex>
      )}
    </Page>
  );
};
