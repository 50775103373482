import hexToRgba from "hex-to-rgba";
import rgbHex from "rgb-hex";

export const colorToRGBA = (hexOrRGB: string, alpha: number) => {
  if (hexOrRGB.includes("rgb")) {
    hexOrRGB = rgbHex(hexOrRGB);
  }

  return hexToRgba(hexOrRGB, alpha);
};
