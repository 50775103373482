import { useCallback, useEffect, useRef, useState } from "react";
import { Key } from "src/utils/keys";
import styled from "styled-components";

import { useAtom } from "jotai";
import { Color, Flex, Text } from "src/elements";
import { AnchorLayer } from "src/elements/AnchorLayer/AnchorLayer";
import { useAnchorLayer } from "src/elements/AnchorLayer/store/hooks";
import { Option } from "./components/Option";
import { menuAtom } from "./store/atoms";
import { MenuOption } from "./store/types";
import { colorToRGBA } from "src/utils/colorToRgba";

const MenuElement = styled(Flex)`
  flex-direction: column;
  background-color: ${colorToRGBA(Color.textMain, 0.7)};
  backdrop-filter: blur(3px);
  border-radius: 3px;
  min-width: 100px;
`;

const MenuHeader = styled(Flex)`
  padding: 7px 7px;
  border-bottom: 1px solid ${colorToRGBA(Color.white, 0.6)};
`;

const MenuContent = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 2px;
  gap: 4px;
`;

export const Menu = () => {
  const [optionSelected, setOptionSelected] = useState<MenuOption | null>(null);
  const [menu, setMenu] = useAtom(menuAtom);
  const refMenu = useRef(menu);

  const {
    isRendering,
    isUsingClick,
    layerState,
    showAnchorLayerFromEventTarget,
    showAnchorLayerFromEvent,
    hideAnchorLayer,
    refLayer,
  } = useAnchorLayer({ isUsingClick: true });

  useEffect(() => {
    refMenu.current = menu;

    if (menu) {
      if (menu.isRelativeToElement) {
        showAnchorLayerFromEventTarget(menu.event);
      } else {
        showAnchorLayerFromEvent(menu.event);
      }
    }
  }, [menu]);

  const closeMenu = () => {
    setMenu(null);
    hideAnchorLayer();
  };

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (!refMenu.current) return;

    event.preventDefault();
    event.stopImmediatePropagation();

    if (
      event.repeat ||
      event.key === Key.CTRL ||
      event.key === Key.SHIFT ||
      event.key === Key.ALT ||
      event.key === Key.META
    )
      return;

    for (const option of refMenu.current.options) {
      if (
        option.keybind?.key.toLowerCase() === event.key.toLowerCase() &&
        (option.keybind?.ctrl || false) === event.ctrlKey &&
        (option.keybind?.shift || false) === event.shiftKey
      ) {
        return setOptionSelected(option);
      }
    }
  }, []);

  const onKeyUp = useCallback((event: KeyboardEvent) => {
    if (!refMenu.current) return;

    event.preventDefault();
    event.stopImmediatePropagation();

    if (event.key === Key.ESCAPE) {
      return closeMenu();
    }

    for (const option of refMenu.current.options) {
      if (
        option.keybind?.key.toLowerCase() === event.key.toLowerCase() &&
        (option.keybind?.ctrl || false) === event.ctrlKey &&
        (option.keybind?.shift || false) === event.shiftKey
      ) {
        option.onClick();
        setOptionSelected(null);
        return closeMenu();
      }
    }
  }, []);

  useEffect(() => {
    if (menu) {
      document.addEventListener("keydown", onKeyDown);
      document.addEventListener("keyup", onKeyUp);

      return () => {
        document.removeEventListener("keydown", onKeyDown);
        document.removeEventListener("keyup", onKeyUp);
      };
    }
  }, [menu, onKeyDown, onKeyUp]);

  return (
    <AnchorLayer
      refLayer={refLayer}
      layerState={layerState}
      isRendering={isRendering}
      isUsingClick={isUsingClick}
      hideAnchorLayer={closeMenu}
    >
      {menu && (
        <MenuElement>
          {menu.title && (
            <MenuHeader>
              <Text variant="h4" color={Color.white}>
                {menu.title}
              </Text>
            </MenuHeader>
          )}

          <MenuContent>
            {menu.options.map((option, i) => (
              <Option
                key={i}
                option={{
                  ...option,
                  onClick: () => {
                    option.onClick();
                    closeMenu();
                  },
                }}
                isSelected={
                  option.keybind?.key !== undefined &&
                  optionSelected?.keybind?.key !== undefined &&
                  option.keybind?.key === optionSelected?.keybind?.key &&
                  option.keybind?.ctrl === optionSelected?.keybind?.ctrl
                }
              />
            ))}
          </MenuContent>
        </MenuElement>
      )}
    </AnchorLayer>
  );
};
