import { PlanogramItem, Pog, UnrangedPlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { PogAtom } from "src/components/Planogram/store/types";
import { findPogShelf, isPlanogramItemMatching } from "src/components/Planogram/store/utils";
import { Button, Color, Flex, Scroller, Search, Text } from "src/elements";

import styled from "styled-components";

import { Dropper } from "../../../Item/Dropper";
import { onDropOverToUnrangedItems } from "../../../Item/store/utils";
import { UnrangedItem } from "./components/UnrangedItem";
import { usePogNewProductModal } from "src/modals/PogNewProduct/store/hooks";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { useLoaders } from "src/components/InlineLoader";
import { RouteGetMerchflowsReviewFlows_merchflowId_ } from "@CommonApi/merchflows/review/flows/_merchflowId_";
import { usePOG } from "src/components/Planogram/store/actions";
import { Filterable } from "src/components/Filterable/Filterable";
import {
  unrangedFilterCdt1Atom,
  unrangedFilterCdt2Atom,
  unrangedFilterCoreRangeAtom,
  unrangedFilterItemsAtom,
  unrangedFilterNewItemAtom,
  unrangedFilterVariantAtom,
  unrangedShelfIdAtom,
} from "./store/atoms";
import { getShelfName } from "@CommonUtils/pog/shelf";

export type UnrangedItemViewFilter = "ALL" | "UNRANGED_MUDMAP" | "UNRANGED_SHELF";

export type UnrangedItemSortFilter = "minOnShelf" | "quantity" | "salesAmount" | "potentialShelf";

const sortByOptions: { label: string; value: UnrangedItemSortFilter }[] = [
  { label: "MOS", value: "minOnShelf" },
  { label: "Movement", value: "quantity" },
  { label: "Sales", value: "salesAmount" },
  { label: "Shelf", value: "potentialShelf" },
];

const UnrangedItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 10px;
  flex-grow: 1;
  position: relative;

  // Render draggable item placeholder as unranged item placeholder.
  [data-placeholder] {
    height: 52px;
    max-height: 52px;
    width: 100%;

    .item-dropper-container {
      display: none;
    }

    .planogram-item {
      height: 100%;
      width: 100%;
      box-shadow: none;

      > * {
        display: none;
      }

      ::after {
        display: flex;
      }
    }
  }
`;

interface Props {
  merchflowId?: number;
  currentStoreCode?: string;
  scaleX: number;
  scaleY: number;
  pogAtom: PogAtom;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"] | null;
}

export const UnrangedItems = ({
  merchflowId,
  currentStoreCode,
  scaleX,
  scaleY,
  pogAtom,
  availableStores,
}: Props) => {
  const { openPogNewProductModal } = usePogNewProductModal();
  const { hasLoader } = useLoaders();
  const { moveProductToUnrangedItems } = usePOG(pogAtom);

  const [pog] = useAtom(pogAtom);
  const [highlightsMode] = useAtom(highlightsModeAtom);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<UnrangedItemSortFilter>("quantity");
  const [sortByDirection, setSortByDirection] = useState<"ASCENDING" | "DESCENDING">("DESCENDING");

  const [unrangedShelfId, setUnrangedShelfId] = useAtom(unrangedShelfIdAtom);
  const [filterItems, setFilterItems] = useAtom(unrangedFilterItemsAtom);
  const [filterVariant, setFilterVariant] = useAtom(unrangedFilterVariantAtom);
  const [filterCdt1, setFilterCdt1] = useAtom(unrangedFilterCdt1Atom);
  const [filterCdt2, setFilterCdt2] = useAtom(unrangedFilterCdt2Atom);
  const [filterCoreRange, setFilterCoreRange] = useAtom(unrangedFilterCoreRangeAtom);
  const [filterNewItem, setFilterNewItem] = useAtom(unrangedFilterNewItemAtom);

  const [optionsVariants, setOptionsVariants] = useState<{ label: string; value: string }[]>([]);
  const [optionsCdt1, setOptionsCdt1] = useState<{ label: string; value: string }[]>([]);
  const [optionsCdt2, setOptionsCdt2] = useState<{ label: string; value: string }[]>([]);
  const [optionsCoreRange, setOptionsCoreRange] = useState<{ label: string; value: boolean }[]>([]);
  const [optionsNewItem, setOptionsNewItem] = useState<{ label: string; value: boolean }[]>([]);

  // On unranged shelf selected, switch items filter to unranged shelf and sort by shelves.
  useEffect(() => {
    if (unrangedShelfId) {
      setFilterItems("UNRANGED_SHELF");
      setSortBy("potentialShelf");
    }
  }, [unrangedShelfId]);

  // Clear unranged shelf id, if items filter was switched off from unranged shelf.
  useEffect(() => {
    if (filterItems !== "UNRANGED_SHELF") {
      setUnrangedShelfId(null);
    }
  }, [filterItems]);

  const refUnrangedItems = useRef<HTMLDivElement>(null);
  const isAvailableStoresLoading = hasLoader(RouteGetMerchflowsReviewFlows_merchflowId_);

  const filteredAndSortedUnrangedItems = useMemo(
    () =>
      filterAndSortUnrangedItems({
        pog,
        search,
        filterItems,
        filterVariant,
        filterCdt1,
        filterCdt2,
        filterCoreRange,
        filterNewItem,
        sortBy,
        sortByDirection,
        unrangedShelfId,
      }),
    [
      pog,
      search,
      filterItems,
      filterVariant,
      filterCdt1,
      filterCdt2,
      filterCoreRange,
      filterNewItem,
      sortBy,
      sortByDirection,
      unrangedShelfId,
    ],
  );

  useEffect(() => {
    const optionsVariant: Set<string> = new Set();
    const optionsCdt1: Set<string> = new Set();
    const optionsCdt2: Set<string> = new Set();
    const optionsCoreRange: Set<boolean> = new Set();
    const optionsNewItem: Set<boolean> = new Set();

    for (const unrangedItem of filteredAndSortedUnrangedItems) {
      optionsVariant.add(unrangedItem.variant);
      optionsCdt1.add(unrangedItem.cdt1);
      optionsCdt2.add(unrangedItem.cdt2);
      optionsCoreRange.add(unrangedItem.inCoreRange);
      optionsNewItem.add(unrangedItem.newItemFlag);
    }

    setOptionsVariants(
      Array.from(optionsVariant)
        .sort()
        .map((variant) => ({ label: variant, value: variant })),
    );
    setOptionsCdt1(
      Array.from(optionsCdt1)
        .sort()
        .map((cdt1) => ({ label: cdt1, value: cdt1 })),
    );
    setOptionsCdt2(
      Array.from(optionsCdt2)
        .sort()
        .map((cdt2) => ({ label: cdt2, value: cdt2 })),
    );
    setOptionsCoreRange(
      Array.from(optionsCoreRange)
        .sort()
        .map((inCoreRange) => ({ label: String(inCoreRange).toUpperCase(), value: inCoreRange })),
    );
    setOptionsNewItem(
      Array.from(optionsNewItem)
        .sort()
        .map((newItemFlag) => ({ label: String(newItemFlag).toUpperCase(), value: newItemFlag })),
    );
  }, [filteredAndSortedUnrangedItems]);

  const minimumRecommendedQuantities = useMemo(() => {
    const recommendedQuantities: { [key: string]: number } = {};

    for (const bay of pog.planogram.bays) {
      for (const shelf of bay.shelves) {
        for (const item of shelf.items) {
          if (item.variant === "") continue;

          if (
            recommendedQuantities[item.variant] === undefined ||
            item.quantity < recommendedQuantities[item.variant]
          ) {
            recommendedQuantities[item.variant] = item.quantity;
          }
        }
      }
    }

    return recommendedQuantities;
  }, [pog]);

  const onDropOver = () => {
    onDropOverToUnrangedItems({
      refUnrangedItems,
      onDrop,
    });
  };

  const onDrop = (item: PlanogramItem) => {
    moveProductToUnrangedItems(item);
  };

  const openNewProductModal = () => {
    if (availableStores && currentStoreCode) {
      openPogNewProductModal({ currentStoreCode, pog, availableStores });
    }
  };

  const getShelfLabel = (shelfId: string) => {
    const shelf = findPogShelf({ pog, shelfId });

    if (!shelf) {
      return "Shelf: Others";
    }

    return `Shelf: ${getShelfName(shelf)}`;
  };

  return (
    <>
      <Flex width="100%" column gap="10px" flexGrow={1} minHeight="0">
        <Flex padding="10px 10px 0 10px">
          <Search search={search} setSearch={setSearch} />
        </Flex>

        <Scroller gutter="stable">
          <Flex padding="0 10px" margin="0 0 10px 0" gap="5px 10px" flexWrap="wrap">
            <Filterable
              label="Items"
              options={
                [
                  { label: "All", value: "ALL" },
                  { label: "Unranged at mudmap", value: "UNRANGED_MUDMAP" },
                  ...((unrangedShelfId
                    ? [
                        {
                          label: "Unranged Shelf",
                          value: "UNRANGED_SHELF",
                        },
                      ]
                    : []) satisfies { label: string; value: UnrangedItemViewFilter }[]),
                ] satisfies { label: string; value: UnrangedItemViewFilter }[]
              }
              value={filterItems}
              setValue={setFilterItems}
              defaultValue="ALL"
            />

            <Filterable
              label="Variant"
              options={[{ label: "All", value: null }, ...optionsVariants]}
              value={filterVariant}
              setValue={setFilterVariant}
              defaultValue={null}
            />

            <Filterable
              label="CDT1"
              options={[{ label: "All", value: null }, ...optionsCdt1]}
              value={filterCdt1}
              setValue={setFilterCdt1}
              defaultValue={null}
            />

            <Filterable
              label="CDT2"
              options={[{ label: "All", value: null }, ...optionsCdt2]}
              value={filterCdt2}
              setValue={setFilterCdt2}
              defaultValue={null}
            />

            <Filterable
              label="Core Range"
              options={[{ label: "All", value: null }, ...optionsCoreRange]}
              value={filterCoreRange}
              setValue={setFilterCoreRange}
              defaultValue={null}
            />

            <Filterable
              label="New Item"
              options={[{ label: "All", value: null }, ...optionsNewItem]}
              value={filterNewItem}
              setValue={setFilterNewItem}
              defaultValue={null}
            />

            <Filterable
              label="Sort by"
              direction={sortByDirection}
              setDirection={setSortByDirection}
              options={sortByOptions}
              value={sortBy}
              setValue={setSortBy}
            />
          </Flex>

          <UnrangedItemsContent ref={refUnrangedItems} className="unranged-items">
            <Dropper
              side="left"
              width="100%"
              height="100%"
              onDropOver={onDropOver}
              onDrop={onDrop}
            />

            {filteredAndSortedUnrangedItems.map((unrangedItem, i) => {
              const isRecommended =
                minimumRecommendedQuantities[unrangedItem.variant] !== undefined &&
                unrangedItem.quantity > minimumRecommendedQuantities[unrangedItem.variant];

              return (
                <React.Fragment key={i}>
                  {sortBy === "potentialShelf" &&
                    (i === 0 ||
                      filteredAndSortedUnrangedItems[i - 1].potentialShelf !==
                        unrangedItem.potentialShelf) && (
                      <Text variant="small1" color={Color.primaryActive}>
                        {getShelfLabel(unrangedItem.potentialShelf)}
                      </Text>
                    )}

                  <UnrangedItem
                    item={unrangedItem}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    isRecommended={isRecommended}
                    extraInformation={sortBy}
                    pogAtom={pogAtom}
                    availableStores={availableStores}
                    potentialShelfId={unrangedItem.potentialShelf}
                  />
                </React.Fragment>
              );
            })}

            {filteredAndSortedUnrangedItems.length === 0 && (
              <Flex width="100%" flexGrow={1} justify="center" align="center">
                <Text variant="h4" color={Color.spaceGray}>
                  No Unranged Items
                </Text>
              </Flex>
            )}
          </UnrangedItemsContent>
        </Scroller>

        <Flex className="add-new-product" width="100%" padding="0 10px 10px 10px">
          <Button
            width="100%"
            color="greenSmoke"
            onClick={openNewProductModal}
            isDisabled={highlightsMode || !merchflowId || !currentStoreCode}
            isLoading={isAvailableStoresLoading}
          >
            Add New Product
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

type FilterAndSortParams = {
  pog: Pog;
  search: string;
  filterItems: UnrangedItemViewFilter;
  filterVariant: string | null;
  filterCdt1: string | null;
  filterCdt2: string | null;
  filterCoreRange: boolean | null;
  filterNewItem: boolean | null;
  sortBy: UnrangedItemSortFilter;
  sortByDirection: "ASCENDING" | "DESCENDING";
  unrangedShelfId: string | null;
};

export const filterAndSortUnrangedItems = ({
  pog,
  search,
  filterItems,
  filterVariant,
  filterCdt1,
  filterCdt2,
  filterCoreRange,
  filterNewItem,
  sortBy,
  sortByDirection,
  unrangedShelfId,
}: FilterAndSortParams) => {
  const seen: string[] = [];

  const filteredAndTransformedItems = pog.unrangedItems.reduce(
    (
      unrangedItems: (UnrangedPlanogramItem & { potentialShelf: string })[],
      unrangedItemWithPotentialShelves,
    ) => {
      // Expand potential shelves or assign "Others"
      const potentialShelves =
        unrangedItemWithPotentialShelves.potentialShelves &&
        unrangedItemWithPotentialShelves.potentialShelves.length > 0
          ? unrangedItemWithPotentialShelves.potentialShelves
          : ["Others"];

      for (const potentialShelf of potentialShelves) {
        const unrangedItem = { ...unrangedItemWithPotentialShelves, potentialShelf };

        // Filter logic
        if (sortBy !== "potentialShelf" && seen.includes(unrangedItem.productCode)) {
          continue; // Skip duplicates unless sorting by potential shelf
        }
        if (sortBy !== "potentialShelf") {
          seen.push(unrangedItem.productCode);
        }
        if (!isPlanogramItemMatching(unrangedItem, search)) {
          continue; // Skip items not matching search
        }
        if (
          (filterItems === "UNRANGED_MUDMAP" &&
            !unrangedItem.reasons.includes("Couldn't make room for the item in the mudmap")) ||
          (filterItems === "UNRANGED_SHELF" &&
            (unrangedShelfId === null || unrangedItem.potentialShelf !== unrangedShelfId))
        ) {
          continue; // Skip based on the filter criteria
        }

        // Variants filter.
        if (filterVariant !== null && unrangedItem.variant !== filterVariant) {
          continue;
        }

        // CDT1 filter.
        if (filterCdt1 !== null && unrangedItem.cdt1 !== filterCdt1) {
          continue;
        }

        // CDT2 filter.
        if (filterCdt2 !== null && unrangedItem.cdt2 !== filterCdt2) {
          continue;
        }

        // Core range filter.
        if (filterCoreRange !== null && unrangedItem.inCoreRange !== filterCoreRange) {
          continue;
        }

        // New item filter.
        if (filterNewItem !== null && unrangedItem.newItemFlag !== filterNewItem) {
          continue;
        }

        // Add item if all conditions are met
        unrangedItems.push(unrangedItem);
      }

      return unrangedItems;
    },
    [],
  );

  // Sorting logic
  return filteredAndTransformedItems.sort((item1, item2) => {
    if (sortBy === "potentialShelf") {
      const item1Shelf = findPogShelf({ pog, shelfId: item1.potentialShelf });
      const item2Shelf = findPogShelf({ pog, shelfId: item2.potentialShelf });

      if (item1Shelf && item2Shelf) {
        const shelfComparison = getShelfName(item1Shelf).localeCompare(getShelfName(item2Shelf));
        return sortByDirection === "DESCENDING" ? -shelfComparison : shelfComparison;
      }

      if (!item1Shelf && !item2Shelf) return 0;
      return item1Shelf ? -1 : 1;
    }

    const comparison = item1[sortBy] < item2[sortBy] ? -1 : item1[sortBy] > item2[sortBy] ? 1 : 0;
    return sortByDirection === "DESCENDING" ? -comparison : comparison;
  });
};
