import { useAtom } from "jotai";
import { Button, Flex, Modal, Select } from "src/elements";
import { importPsaModalDataAtom } from "./store/atoms";
import { useEffect, useState } from "react";
import { FileUploader } from "src/components";
import { useLoaders } from "src/components/InlineLoader";
import {
  ResponsePostMerchflowUploadPsa,
  RoutePostMerchflowUploadPsa,
} from "@CommonApi/merchflow/upload-psa";
import { useMerchflowsApi } from "src/api/merchflows";
import { ResponseGetMerchflowStores, RouteGetMerchflowStores } from "@CommonApi/merchflow/stores";
import { Category } from "@CommonApi/categories/list";
import { useAsyncState } from "../../hooks/useAsyncState";

interface Props {
  category: Category;
  categoryId: number;
}

export const ModalImportPsa = () => {
  const [data, setData] = useAtom(importPsaModalDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) {
    return null;
  }

  return <ModalImportPsaInner category={data} categoryId={data.id} />;
};

const ModalImportPsaInner = ({ category, categoryId }: Props) => {
  const { hasLoader } = useLoaders();
  const { postMerchflowUploadPsaApi, getMerchflowStoresApi } = useMerchflowsApi();

  const [file, setFile] = useState<File | null>(null);
  const [response, setResponse] = useAsyncState<ResponsePostMerchflowUploadPsa | null>(null);

  const [storeId, setStoreId] = useState<string | null>(null);
  const [stores, setStores] = useAsyncState<ResponseGetMerchflowStores>(undefined);

  const isStoresLoading = hasLoader(RouteGetMerchflowStores);
  const isUploadPsaLoading = hasLoader(RoutePostMerchflowUploadPsa);

  useEffect(() => {
    setStores(
      getMerchflowStoresApi({
        retailerCategoryCode: category.category_group,
        categoryCode: category.code,
      }),
    );

    return () => {
      setStores(undefined);
    };
  }, []);

  const uploadPsa = async () => {
    if (!file) return;
    if (!storeId) return;

    setResponse(
      await postMerchflowUploadPsaApi({
        psa_file: file,
        category_id: String(categoryId),
        store_id: storeId,
        return_also_base_pog: String(true),
      }),
    );
  };

  return (
    <Modal name="ModalImportPsa" title={`Import PSA for Category: ${category.code}`} width="500px">
      <Flex padding="10px" column gap="16px">
        <Flex gap="10px">
          <FileUploader
            fileLabel="Space Management"
            fileType={"psa"}
            onChange={setFile}
            isLoading={isUploadPsaLoading}
            onSuccess={
              response &&
              `
            upload_id: ${response.upload_id}, 
            uploaded_symbols: ${response.uploaded_symbols},
            uploaded_lines: ${response.uploaded_lines},
            uploaded_stores: ${response.uploaded_stores}
            `
            }
          />
        </Flex>

        <Select
          options={(stores?.data || []).map((store) => ({
            value: store.store,
            label: `${store.store_code} (id: ${store.id})`,
          }))}
          isSearchable
          isRequired
          isLoading={isStoresLoading}
          title="Select store"
          placeholder="store"
          value={storeId}
          setValue={setStoreId}
          isDisabled={isUploadPsaLoading || Boolean(response)}
        />

        <Button
          isDisabled={!file || !storeId || isUploadPsaLoading || Boolean(response)}
          onClick={uploadPsa}
        >
          Upload
        </Button>
      </Flex>
    </Modal>
  );
};
