import { useEffect, useState } from "react";

import { Checkbox, Modal, Button, Color, Flex, Scroller, Grid } from "src/elements";
import { useAtom } from "jotai";
import { categoriesModalStoreAtom } from "./store/atoms";
import { ResponseGetStoresList } from "@CommonApi/stores/list";
import { useAsyncState } from "src/hooks/useAsyncState";
import { ResponseGetCategoriesList, RouteGetCategoriesList } from "@CommonApi/categories/list";
import { useApi } from "src/api";
import { useLoaders } from "src/components/InlineLoader";
import {
  ResponseGetStoresCategoriesList,
  RouteGetStoresCategoriesList,
} from "@CommonApi/stores/categories/list";
import { PageStatus } from "src/components";
import { RoutePutStores_storeId_BindAll } from "@CommonApi/stores/_storeId_/bind-all";
import { useModals } from "src/components/Modals";

export const StoreCategoriesModal = () => {
  const [store, setStore] = useAtom(categoriesModalStoreAtom);

  useEffect(() => {
    return () => {
      setStore(null);
    };
  }, []);

  return store ? <StoreCategoriesModalInner store={store} /> : null;
};

interface SelectedCategory {
  id: number;
  code: string;
  isChecked: boolean;
}

interface Props {
  store: ResponseGetStoresList["data"][0];
}

const StoreCategoriesModalInner = ({ store }: Props) => {
  const { getCategoriesListApi, getStoreCategoriesListApi, putStores_storeId_CategoriesApi } =
    useApi();
  const { closeModal } = useModals();
  const { hasLoader } = useLoaders();

  const [allCategories, setAllCategories] = useAsyncState<ResponseGetCategoriesList>();
  const [storeCategories, setStoreCategories] = useAsyncState<ResponseGetStoresCategoriesList>();
  const [categories, setCategories] = useState<SelectedCategory[]>([]);

  const isCategoriesLoading =
    allCategories === undefined ||
    storeCategories === null ||
    hasLoader(RouteGetCategoriesList) ||
    hasLoader(RouteGetStoresCategoriesList);
  const isSaveLoading = hasLoader(RoutePutStores_storeId_BindAll);

  useEffect(() => {
    setAllCategories(getCategoriesListApi());
    setStoreCategories(getStoreCategoriesListApi({ storeId: store.id }));
  }, []);

  useEffect(() => {
    restoreOriginalCategories();
  }, [allCategories, storeCategories]);

  const setCategoryChecked = (id: number, isChecked: boolean) => {
    setCategories((categories) =>
      categories.map((category) => (category.id === id ? { ...category, isChecked } : category)),
    );
  };

  const selectAllCategories = () => {
    setCategories((categories) => categories.map((category) => ({ ...category, isChecked: true })));
  };

  const restoreOriginalCategories = () => {
    if (allCategories && storeCategories) {
      const newCategories: SelectedCategory[] = [];

      for (const category of allCategories) {
        for (const subcategory of category.categories) {
          newCategories.push({
            id: subcategory.id,
            code: subcategory.code,
            isChecked:
              storeCategories.find((storeCategory) => storeCategory.id === subcategory.id) !==
              undefined,
          });
        }
      }

      setCategories(newCategories);
    }
  };

  const saveCategories = async () => {
    const response = await putStores_storeId_CategoriesApi(store.id, {
      categories: categories
        .filter((category) => category.isChecked)
        .map((category) => category.id),
    });

    if (response) {
      closeModal("StoreCategoriesModal");
    }
  };

  return (
    <Modal name="StoreCategoriesModal" title={`${store.code} Categories`} width="900px">
      <Flex column padding="10px" minHeight="300px">
        <Flex column gap="10px" flexGrow>
          <Flex
            column
            flexGrow
            background={Color.lightGray}
            borderColor={Color.lightGrayHover}
            borderRadius="3px"
          >
            {isCategoriesLoading && (
              <Flex flexGrow align="center">
                <PageStatus label="Loading categories..." icon="spinner" />
              </Flex>
            )}

            {!isCategoriesLoading && (
              <Scroller gutter="stable both-edges">
                <Grid
                  columns="repeat(auto-fit, minmax(250px, 1fr));"
                  gap="20px"
                  padding="20px 10px"
                  flexGrow
                  align="top"
                  alignContent="baseline"
                >
                  {categories.map((category) => (
                    <Checkbox
                      label={category.code}
                      isChecked={category.isChecked}
                      setIsChecked={(isChecked) => setCategoryChecked(category.id, isChecked)}
                      isCheckboxLeft
                    />
                  ))}
                </Grid>
              </Scroller>
            )}
          </Flex>

          <Flex gap="10px" justify="between">
            <Button color="red" variant="inverted" onClick={restoreOriginalCategories}>
              Restore Original Selection
            </Button>

            <Flex gap="10px">
              <Button color="greenSmoke" onClick={selectAllCategories}>
                Select All
              </Button>

              <Button color="primary" isLoading={isSaveLoading} onClick={saveCategories}>
                Save
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};
