import { useApi } from "src/api";
import { useLoaders } from "src/components/InlineLoader";
import { Button, Flex } from "src/elements";
import { useModals } from "src/components/Modals";
import { useNavigation } from "src/utils/navigation";
import { pages } from "src/utils";
import { useState } from "react";
import { defaultPspRules } from "src/pages/Merchflows_merchflowId_BasePog_basePogId_/store/types";
import { RoutePostMerchflow_merchflowId_FlowUpdate } from "@CommonApi/merchflow/_merchflowId_/flow-update";
import { PspRules } from "@CommonTypes/backend/PspRules";
import { RulesSection } from "src/components/PlanogramRules/components/RulesSection";
import {
  ProductUpdateAdd,
  ProductUpdateRemove,
  ProductUpdateSwap,
  ProductUpdates,
} from "../store/types";
import { routeToUrl } from "@CommonUtils/routes";

interface Props {
  merchflowId: number;
  productUpdates: ProductUpdates;
}

export const Step4PlanogramRules = ({ merchflowId, productUpdates }: Props) => {
  const { postFlowUpdateApi } = useApi();
  const { hasLoader } = useLoaders();
  const { closeModal } = useModals();
  const { navigate } = useNavigation();

  const [pspRules, setPspRules] = useState<PspRules>(defaultPspRules);

  const startUpdate = async () => {
    const response = await postFlowUpdateApi(merchflowId, {
      products_to_swap: productUpdates
        .filter((productUpdate) => productUpdate.type === "swap")
        .map((swap: ProductUpdateSwap) => ({
          pnl_decision: swap.pnlDecision,
          product_code_to_add: swap.add.product_code,
          product_code_to_remove: swap.remove.product_code,
        })),
      products_to_insert: productUpdates
        .filter((productUpdates) => productUpdates.type === "add")
        .map((add: ProductUpdateAdd) => ({
          pnl_decision: add.pnlDecision,
          product_code: add.productToAdd.product_code,
          adjacent_product_code: add.adjacentProduct.product_code,
          place_to: add.adjacentPosition,
          facings: add.facings ? Number(add.facings) : "AUTO",
          reducible_product_code: add.reducibleProduct?.product_code,
          fit_strategy: add.fitStrategy,
        })),
      products_to_remove: productUpdates
        .filter((productUpdate) => productUpdate.type === "remove")
        .map((remove: ProductUpdateRemove) => ({
          product_code: remove.product.product_code,
          pnl_decision: remove.pnlDecision,
        })),
      psp_rules: pspRules,
    });

    if (response) {
      closeModal("ModalMerchflowReviewUpdate");

      navigate(
        routeToUrl(
          pages.merchflows_merchflowId_StoresAll,
          {
            merchflowId,
          },
          {
            store_pog_trigger_id: response.store_pog_trigger_id,
          },
        ),
      );
    }
  };

  return (
    <Flex width="100%" column>
      <Flex width="100%" gap="20px" margin="20px 0" flexGrow>
        <RulesSection
          pspRules={pspRules}
          setPspRules={setPspRules}
          title="Merchandising"
          rulesParent="merchandising"
          area="merchandising"
          rules={[
            {
              title: "Can we break trays if a core range product cannot fit otherwise?",
              rule: "can_break_trays_if_core_range_not_fit",
            },
            {
              title: "Can we fill extra space next to cases / trays with loose products?",
              rule: "can_fill_extra_space_next_to_cases_trays_with_loose_products",
            },
            {
              title: "How should loose products be placed with respect to their cases / trays?",
              rule: "how_should_loose_products_be_placed_with_respect_to_their_cases_trays",
              isHidden:
                pspRules.merchandising
                  .can_fill_extra_space_next_to_cases_trays_with_loose_products === false,
              options: ["behind", "front"],
            },
            {
              title: "Can we leverage alternative orientations to fill empty space on a shelf?",
              rule: "leverage_alternative_orientations",
            },
          ]}
        />
      </Flex>

      <Flex justify="right">
        <Button
          isLoading={hasLoader(RoutePostMerchflow_merchflowId_FlowUpdate)}
          onClick={startUpdate}
        >
          Start Update
        </Button>
      </Flex>
    </Flex>
  );
};
