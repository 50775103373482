import { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Color,
  Flex,
  Icon,
  Scroller,
  Search,
  SubgridTable,
  Text,
} from "src/elements";
import { MerchflowReviewUpdateStep, ProductUpdateRemove, ProductUpdates } from "../store/types";
import { useLoaders } from "src/components/InlineLoader";
import { PageStatus, Tooltip } from "src/components";
import { filterProducts, productToReadableFormat } from "../store/utils";
import { useModals } from "src/components/Modals";
import {
  FlowUpdateProduct,
  RouteGetMerchflow_merchflowId_FlowUpdateProducts,
} from "@CommonApi/merchflow/_merchflowId_/flow-update/products";

interface Props {
  removableProducts: FlowUpdateProduct[];
  setStep: (step: MerchflowReviewUpdateStep) => void;
  setProductUpdates: Dispatch<SetStateAction<ProductUpdates>>;
}

export const Step3BRemoveProducts = ({ removableProducts, setStep, setProductUpdates }: Props) => {
  const { openConfirmModal } = useModals();
  const { hasLoader } = useLoaders();

  const [selectedProducts, setSelectedProducts] = useState<{
    [key: string]: boolean;
  }>({});
  const [isModified, setIsModified] = useState(false);
  const [search, setSearch] = useState("");

  const isProductsLoading = hasLoader(RouteGetMerchflow_merchflowId_FlowUpdateProducts);

  const filteredRemovableProducts = useMemo(
    () => filterProducts(removableProducts, search),
    [removableProducts, search],
  );

  const onSave = () => {
    setProductUpdates((productUpdates) => [
      ...productUpdates,
      ...Object.keys(selectedProducts)
        .filter((productCode) => selectedProducts[productCode] === true)
        .map(
          (productCode) =>
            ({
              type: "remove",
              product: removableProducts.find((product) => product.product_code === productCode)!,
              pnlDecision: "DEFAULT",
            }) as ProductUpdateRemove,
        ),
    ]);
    setStep("STEP_3_PRODUCT_REVIEW");
  };

  const openInformationModal = () => {
    openConfirmModal({
      title: "How we fill space after removing products",
      description: (
        <Flex column padding="10px" gap="5px">
          <Text variant="small1" color={Color.textMain}>
            We fill shelf space leftover by removed products by doing these in this order:
          </Text>

          <Text variant="small2">
            • Increase facings of products already on shelf ranged below mos and dos
          </Text>

          <Text variant="small2">• Increase facings of products already on shelf</Text>
        </Flex>
      ),
      buttons: [{ label: "OK", color: "greenSmoke" }],
    });
  };

  return (
    <Flex column flexGrow gap="20px">
      <Flex column flexGrow gap="20px">
        <Text color={Color.primary} variant="h4">
          Please select an available product to be removed. We will increase facings of existing
          products to fill space left by this deletion.
          <Flex display="inline-flex" position="relative">
            <Flex position="absolute" bottom="4px" onClick={openInformationModal}>
              <Icon name="info" color={Color.primary} size={12} />
              <Tooltip>How do we do that?</Tooltip>
            </Flex>
          </Flex>
        </Text>

        {isProductsLoading ? (
          <PageStatus icon="spinner" label="Loading available products..." />
        ) : (
          <Flex column flexGrow gap="10px">
            <Search width="200px" search={search} setSearch={setSearch} />

            <Scroller>
              <SubgridTable
                columns={[
                  {
                    header: "",
                    id: "selected",
                    width: "40px",
                    justify: "center",
                  },
                  {
                    header: "Removables",
                    id: "product_code",
                    renderer: (data) => productToReadableFormat(data.row),
                  },
                ]}
                data={filteredRemovableProducts.map((product) => ({
                  ...product,
                  selected: (
                    <Checkbox
                      isChecked={selectedProducts[product.product_code] === true}
                      name="removable-product"
                      setIsChecked={(isChecked) => {
                        setSelectedProducts((selectedProducts) => ({
                          ...selectedProducts,
                          [product.product_code]: isChecked,
                        }));
                        setIsModified(true);
                      }}
                    />
                  ),
                }))}
              />
            </Scroller>
          </Flex>
        )}
      </Flex>

      <Flex justify="between" gap="10px">
        <Button
          color={isModified ? "red" : "greenSmoke"}
          variant="inverted"
          onClick={() => setStep("STEP_3_PRODUCT_REVIEW")}
        >
          {isModified ? "Cancel" : "Back"}
        </Button>

        <Button onClick={onSave} isDisabled={Object.keys(selectedProducts).length === 0}>
          Save
        </Button>
      </Flex>
    </Flex>
  );
};
