import { useAtom, useAtomValue } from "jotai";
import _isEmpty from "lodash/isEmpty";
import { PowerBIEmbed } from "powerbi-client-react";
import { usePowerBiReport } from "src/hooks/usePowerBiReport";

import styled from "styled-components";
import { reportFiltersAtom, reportIdAtom } from "./store/atoms";
import { Modal } from "src/elements";
import { useEffect } from "react";
import { useLoaders } from "src/components/InlineLoader";
import { PageStatus } from "src/components";
import { powerBiReportsAtom } from "src/pages/merchandise/store/atoms";
import { useApi } from "src/api";

const Wrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  .report-style-class {
    height: 100%;
  }
`;

export const PowerBiReportModal = () => {
  const { getPowerBIReportApi } = useApi();
  const { hasLoader } = useLoaders();

  const [, setReportData] = useAtom(powerBiReportsAtom);
  const [filters, setFilters] = useAtom(reportFiltersAtom);
  const reportId = useAtomValue(reportIdAtom);
  const { eventHandlersMap, reportConfig } = usePowerBiReport({
    reportId,
    filters: _isEmpty(filters) ? undefined : filters,
  });
  const isLoading = hasLoader("powerBiReportApi");

  const loadReport = async () => {
    const response = await getPowerBIReportApi();

    if (response) {
      setReportData(response);
    }
  };

  useEffect(() => {
    loadReport();

    return () => {
      setFilters([]);
      setReportData(null);
    };
  }, []);

  return (
    <Modal name="PowerBiReportModal" title="Merchflow PowerBI Report" width="90vw" height="95vh">
      <Wrapper>
        {isLoading && <PageStatus icon="spinner" label="Loading report..." />}

        {reportConfig && (
          <PowerBIEmbed
            cssClassName="report-style-class"
            embedConfig={reportConfig}
            eventHandlers={eventHandlersMap}
          />
        )}
      </Wrapper>
    </Modal>
  );
};
