import { Project } from "src/omni-common/projects";
import { omniAtom } from "src/utils/atoms";

export const projectsPageAtom = omniAtom({
  key: "pages/projects",
  value: undefined as Project[] | undefined | null,
});

export const projectModalAtom = omniAtom({
  key: "modals/project",
  value: null as Project | null,
});
