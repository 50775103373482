import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { importVariantRestrictionModalDataAtom } from "./atoms";
import { Category } from "@CommonApi/categories/list";

export const useImportVariantRestrictionModal = () => {
  const { openModal } = useModals();
  const setImportVariantRestrictionData = useSetAtom(importVariantRestrictionModalDataAtom);

  const openImportVariantRestrictionModal = (category: Category) => {
    setImportVariantRestrictionData(category);
    openModal("ModalImportVariantRestriction");
  };

  return { openImportVariantRestrictionModal };
};
