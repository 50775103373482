import { adapterPogToPogNonCircular } from "@CommonAdapters/pog";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { atom, useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Page, PageStatus, Planogram } from "src/components";
import { useLoaders } from "src/components/InlineLoader";
import { useModals } from "src/components/Modals";
import { Button, Color, Flex } from "src/elements";
import { useModifiedPog } from "src/hooks/useModifiedPogAtom";
import { RoutePutMerchflowBasePog_basePogId_ } from "@CommonApi/merchflow/base-pog/_basePogId_";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { PogAtom } from "src/components/Planogram/store/types";
import { PlanogramItemSearcher } from "src/components/PlanogramExtensions/PlanogramItemSearcher";
import { PlanogramMetrics } from "src/components/PlanogramExtensions/PlanogramMetrics";
import { useStorePlanogramRulesModal } from "src/modals/StorePlanogramRules/store/hooks";
import { pages } from "src/utils/pages";
import { useNavigation } from "src/utils/navigation";
import { MerchflowHeader } from "../../components/merchflows/MerchflowHeader/MerchflowHeader";
import { MerchflowStepsNavigator } from "../../components/merchflows/MerchflowStepsNavigator/MerchflowStepsNavigator";
import {
  merchflows_merchflowId_BasePog_basePogId_Atom,
  merchflows_merchflowId_BasePog_basePogId_PogAtom,
} from "./store/atoms";
import { PlanogramHovererDetails } from "src/components/PlanogramExtensions/PlanogramHovererDetails/PlanogramHovererDetails";
import { usePowerBiReportModal } from "src/modals/PowerBiReportModal/hooks";
import { RoutePostMerchflowApplyToBases } from "@CommonApi/merchflow/apply-to-bases";
import { useApi } from "src/api";
import { RoutePutBasePogSelectedStatus } from "@CommonApi/pogger/base-pog/status";
import { RouteGetMerchflows_merchflowId_BasePog_basePogId_ } from "@CommonApi/merchflows/_merchflowId_/base-pog/_basePogId_";
import { sleep } from "src/utils/sleep";
import { RouteGetMerchflows_merchflowId_StoresAll } from "@CommonApi/merchflows/_merchflowId_/stores/all";
import { PlanogramLegend } from "src/components/PlanogramExtensions/PlanogramLegend";
import { RoutePostMerchflowApplyToBasesByCopyStores } from "@CommonApi/merchflow/apply-to-bases-by-copy-stores";
import { downloadFileFromUrl } from "src/utils/downloadFile";
import { RouteGetMerchflowBasePog_basePogId_logs } from "@CommonApi/merchflow/base-pog/_basePogId_/logs";
import { routeToUrl } from "@CommonUtils/routes";

export const PageMerchflows_merchflowId_BasePog_basePogId_ = () => {
  const {
    getMerchflowBasePogByMerchflowIdAndBasePogIdApi,
    putMerchflowBasePogApi,
    putMerchflowBasePog_basePogId_StatusApi,
    getMerchflowBasePogLogsApi,
  } = useApi();
  const { hasLoader } = useLoaders();
  const { navigate } = useNavigation();
  const { openConfirmModal } = useModals();
  const { openStorePlanogramRules } = useStorePlanogramRulesModal();
  const { openBasePogReport } = usePowerBiReportModal();

  const { merchflowId: _merchflowId, basePogId: _basePogId } = useParams<{
    merchflowId: string;
    basePogId: string;
  }>();
  const merchflowId = Number(_merchflowId);
  const basePogId = Number(_basePogId);

  const [data, setData] = useAtom(merchflows_merchflowId_BasePog_basePogId_Atom);
  const setHighlightsMode = useSetAtom(highlightsModeAtom);

  const [isFavoriteDone, setIsFavoriteDone] = useState(false);
  const [isSavingDone, setIsSavingDone] = useState(false);

  const containersAtom = useMemo(() => atom([] as PlanogramContainer[]), []);
  const {
    isModified,
    setPog: setBasePog,
    pog: basePog,
    revertPog,
    savePogAsOriginal,
    applySnaking,
  } = useModifiedPog(merchflows_merchflowId_BasePog_basePogId_PogAtom);

  const isPogAvailable = Boolean(data && data?.pog !== null);
  const isPlanogramLoading =
    data === undefined ||
    (data?.status !== "IN_PROGRESS" &&
      hasLoader(RouteGetMerchflows_merchflowId_BasePog_basePogId_));
  const isSaveLoading = hasLoader(RoutePutMerchflowBasePog_basePogId_);
  const isFavoriteLoading = hasLoader(RoutePutBasePogSelectedStatus);
  const isGenerateStorePlanogramsLoading =
    hasLoader(RoutePostMerchflowApplyToBases) ||
    hasLoader(RouteGetMerchflows_merchflowId_StoresAll) ||
    hasLoader(RoutePostMerchflowApplyToBasesByCopyStores);

  useEffect(() => {
    loadBasePog();
    setHighlightsMode(false);

    return () => {
      setData(undefined);
      setBasePog(null);
    };
  }, []);

  const loadBasePog = async () => {
    const response = await getMerchflowBasePogByMerchflowIdAndBasePogIdApi(merchflowId, basePogId);
    setData(response);

    // Base pog in progress, keep checking.
    if (response?.status === "IN_PROGRESS") {
      await sleep(10000);
      loadBasePog();
    }
  };

  // Save POG to atom once.
  useEffect(() => {
    if (data?.pog && basePog === null) {
      setBasePog(data.pog);
    }
  }, [data]);

  const onFavorite = async () => {
    if (!data) return;

    if (
      await putMerchflowBasePog_basePogId_StatusApi({
        is_not_favourite: data.favorited ? [basePogId] : [],
        is_favourite: !data.favorited ? [basePogId] : [],
      })
    ) {
      setIsFavoriteDone(true);
      setTimeout(() => {
        setData({ ...data, favorited: !data.favorited });
        setIsFavoriteDone(false);
      }, 1000);
    }
  };

  const onSave = async (saveAsNew: boolean) => {
    if (!basePog) return;

    const newBasePogId = await putMerchflowBasePogApi(basePogId, {
      pog: adapterPogToPogNonCircular(basePog),
      save_as_new: saveAsNew,
    });

    if (newBasePogId) {
      setIsSavingDone(true);
      setTimeout(() => {
        setIsSavingDone(false);

        if (saveAsNew && newBasePogId !== basePogId) {
          navigate(
            routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
              merchflowId,
              basePogId: newBasePogId,
            }),
          );
        }

        savePogAsOriginal();
      }, 1000);
    }
  };

  const openRevertChangesConfirmation = () => {
    openConfirmModal({
      title: "Revert modified planogram changes?",
      description: "All unsaved changes made to the planogram will be lost. This is irreversible.",
      buttons: [
        {
          label: "Keep Changes",
          color: "primary",
          variant: "inverted",
        },
        {
          label: "Revert Changes",
          color: "red",
          onClick: () => {
            revertPog();
          },
        },
      ],
    });
  };

  const openReport = () => {
    if (!data) return;

    openBasePogReport([
      {
        column: "filter_config_id",
        table: "gen_id_table",
        value: [data.filter_config_id],
      },
      {
        column: "id",
        table: "base_id",
        value: [data.id],
      },
    ]);
  };

  const downloadLogs = async (
    logsType:
      | "pogger_request_path"
      | "pogger_response_path"
      | "poggerize_request_path"
      | "poggerize_response_path",
  ) => {
    const response = await getMerchflowBasePogLogsApi(basePogId);

    if (response && response[logsType]) {
      downloadFileFromUrl(response[logsType]);
    }
  };

  return (
    <Page>
      <MerchflowHeader
        merchflowId={merchflowId}
        templateId={data?.template_id || null}
        basePogId={basePogId}
      />

      <MerchflowStepsNavigator
        activeStep="BASE_POG"
        merchflowId={merchflowId}
        templateId={data?.template_id || undefined}
        basePogId={basePogId}
      />

      <Flex gap="10px" flexGrow column>
        {(data?.status === "IN_PROGRESS" && (
          <PageStatus label="Base planogram is generating..." icon="spinner" />
        )) ||
          (data === null && (
            <PageStatus label="Base planogram not found" color={Color.red} icon="alert" />
          )) ||
          (data?.status === "ERROR" && (
            <PageStatus label="Base planogram generation failed" color={Color.red} icon="alert" />
          )) ||
          (isPlanogramLoading && (
            <PageStatus label="Base planogram is loading..." icon="spinner" />
          )) ||
          (data && data.pog && basePog && (
            <>
              <Flex justify="between" align="center">
                <PlanogramItemSearcher />

                <Flex gap="10px" align="center">
                  <Button
                    color="greenSmoke"
                    variant="inverted"
                    dropdown={[
                      { label: "Odd shelves", onClick: () => applySnaking("odd") },
                      { label: "Even shelves", onClick: () => applySnaking("even") },
                    ]}
                  >
                    Apply Snaking
                  </Button>
                </Flex>
              </Flex>

              <Planogram
                pogAtom={merchflows_merchflowId_BasePog_basePogId_PogAtom as unknown as PogAtom}
                containersAtom={containersAtom}
                isEditable
                isPanelVisible
                isPog
                merchflowId={merchflowId}
                currentStoreCode={data.store_code || undefined}
                isWithBorder
              />

              <PlanogramLegend />

              <PlanogramHovererDetails />
            </>
          ))}
      </Flex>

      <Flex justify="between" align="center">
        <PlanogramMetrics pog={basePog} isLoading={isPlanogramLoading} />

        <Flex gap="10px">
          {isModified && (
            <>
              <Button
                color="red"
                variant="inverted"
                isDisabled={isSaveLoading || isSavingDone}
                onClick={openRevertChangesConfirmation}
              >
                Revert Changes
              </Button>

              <Button
                color="primary"
                isEnabled={isPogAvailable}
                isLoading={isSaveLoading}
                isSuccessful={isSavingDone}
                onClick={() => onSave(false)}
                dropdown={[{ label: "Save New Version", onClick: () => onSave(true) }]}
              >
                Save
              </Button>
            </>
          )}

          {!isModified && (
            <>
              <Button
                color="greenSmoke"
                variant="inverted"
                dropdown={[
                  { label: "Pogger request", onClick: () => downloadLogs("pogger_request_path") },
                  { label: "Pogger response", onClick: () => downloadLogs("pogger_response_path") },
                  {
                    label: "Poggerize request",
                    onClick: () => downloadLogs("poggerize_request_path"),
                  },
                  {
                    label: "Poggerize response",
                    onClick: () => downloadLogs("poggerize_response_path"),
                  },
                ]}
                isDisabled={isPlanogramLoading || isGenerateStorePlanogramsLoading}
                isLoading={hasLoader(RouteGetMerchflowBasePog_basePogId_logs)}
              >
                Download Logs
              </Button>

              <Button
                color="greenSmoke"
                variant="inverted"
                onClick={openReport}
                isDisabled={isPlanogramLoading || isGenerateStorePlanogramsLoading}
                isEnabled={isPogAvailable}
              >
                View Report
              </Button>

              <Button
                color="greenSmoke"
                onClick={onFavorite}
                isEnabled={isPogAvailable}
                isDisabled={isPlanogramLoading || isGenerateStorePlanogramsLoading}
                isLoading={isFavoriteLoading}
                isSuccessful={isFavoriteDone}
              >
                {data?.favorited ? "Unfavorite" : "Favorite"}
              </Button>

              <Button
                color="primary"
                onClick={() =>
                  openStorePlanogramRules({
                    merchflowId,
                    basePogId,
                    isBasePogFavorited: data?.favorited || false,
                  })
                }
                isDisabled={isPlanogramLoading || isFavoriteLoading}
                isEnabled={isPogAvailable}
                isLoading={isGenerateStorePlanogramsLoading}
              >
                Generate Store Planograms
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    </Page>
  );
};
