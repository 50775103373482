import { omniAtom } from "src/utils/atoms";
import { UnrangedItemViewFilter } from "../UnrangedItems";

export const unrangedShelfIdAtom = omniAtom({
  key: "unrangedItems/shelfId",
  value: null as string | null,
});

export const unrangedFilterItemsAtom = omniAtom({
  key: "unrangedItems/filterItems",
  value: "ALL" as UnrangedItemViewFilter,
});

export const unrangedFilterVariantAtom = omniAtom({
  key: "unrangedItems/filterVariant",
  value: null as null | string,
});

export const unrangedFilterCdt1Atom = omniAtom({
  key: "unrangedItems/filterCdt1",
  value: null as null | string,
});

export const unrangedFilterCdt2Atom = omniAtom({
  key: "unrangedItems/filterCdt2",
  value: null as null | string,
});

export const unrangedFilterCoreRangeAtom = omniAtom({
  key: "unrangedItems/filterCoreRange",
  value: null as null | boolean,
});

export const unrangedFilterNewItemAtom = omniAtom({
  key: "unrangedItems/filterNewItem",
  value: null as null | boolean,
});
