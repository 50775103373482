import {
  ResponseGetMerchflowTemplateAllStores_templateId_,
  RouteGetMerchflowTemplateAllStores_templateId_,
} from "@CommonApi/merchflow/template/all-stores/_templateId_";
import { RequiredPaginationQueryParams } from "@CommonTypes/backend/pagination";
import { useLoaders } from "src/components/InlineLoader";
import { useToasts } from "src/components/Toasts";
import { API, getRequest } from "src/utils/axios";

export const getMerchflows_AssignedStores_TemplateIdReq = (
  templateId: string,
  queryParams: RequiredPaginationQueryParams,
) => {
  return getRequest<ResponseGetMerchflowTemplateAllStores_templateId_>(
    `${API}${RouteGetMerchflowTemplateAllStores_templateId_}`,
    {
      params: {
        templateId,
      },
      queryParams,
    },
  );
};

export const useMerchflowTemplateApi = () => {
  const { addLoader, removeLoader } = useLoaders();
  const { toast } = useToasts();

  const getStoreAssignedApi = async (
    setData: (data: ResponseGetMerchflowTemplateAllStores_templateId_ | null) => void,
    templateId: string,
    queryParams: RequiredPaginationQueryParams,
  ) => {
    addLoader("merchflow/template/all-stores/:templateId");

    try {
      const { data } = await getMerchflows_AssignedStores_TemplateIdReq(templateId, queryParams);

      setData(data);
    } catch (error) {
      toast({
        title: "Failed to get Assigned store of template",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        error,
      });
    }

    removeLoader("merchflow/template/all-stores/:templateId");
  };

  return {
    getStoreAssignedApi,
  };
};
