import { useRef } from "react";
import { Color, Flex, HorizontalDivider, Icon, Radios, Scroller, Text } from "src/elements";
import { AnchorLayer } from "src/elements/AnchorLayer/AnchorLayer";
import { useAnchorLayer } from "src/elements/AnchorLayer/store/hooks";
import styled from "styled-components";

const FilterableElement = styled(Flex)`
  padding: 5px;
  background-color: ${Color.lightGray};
  cursor: default;
`;

const SelectedFilter = styled(Flex)`
  padding: 3px 5px;
  background-color: ${Color.lightGrayHover};
  border-radius: 10px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: ${Color.greenSmoke};
  }

  &:active {
    background-color: ${Color.greenSmokeHover};
  }
`;

const Dropdown = styled(Flex)`
  width: 100%;
  flex-direction: column;
  background-color: ${Color.white};
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const ClearButton = styled(Flex)`
  &:hover .inner {
    fill: ${Color.redDisabled};
  }

  &:active .inner {
    fill: ${Color.red};
  }
`;

const SortButton = styled(Flex)`
  width: 18px;
  height: 18px;
  background-color: ${Color.white};
  border-radius: 3px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${Color.greenSmoke};
  }

  &:active {
    background-color: ${Color.greenSmokeHover};
  }
`;

interface Props<T> {
  label: string;
  options: { label: string; value: T }[];
  value: T;
  setValue: (filter: T) => void;
  defaultValue?: T;
  direction?: "ASCENDING" | "DESCENDING";
  setDirection?: (direction: "ASCENDING" | "DESCENDING") => void;
}

export const Filterable = <T,>({
  label,
  options,
  value,
  setValue,
  defaultValue,
  direction,
  setDirection,
}: Props<T>) => {
  const refContainer = useRef(null);
  const refFilter = useRef(null);

  const { refLayer, isRendering, isVisible, layerState, hideAnchorLayer, isUsingClick } =
    useAnchorLayer({
      refTrigger: refFilter,
      refAnchor: refContainer,
      isUsingClick: true,
      isTopOfParent: true,
    });

  const toggleDirection = () => {
    if (direction && setDirection) {
      setDirection(direction === "ASCENDING" ? "DESCENDING" : "ASCENDING");
    }
  };

  return (
    <FilterableElement
      ref={refContainer}
      borderRadius={isVisible ? "10px 10px 0 0" : "10px"}
      boxShadow={isVisible ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : undefined}
    >
      <Flex gap="5px" align="center">
        <Text variant="small2" color={Color.textSecondary} whiteSpace="nowrap">
          {label.replaceAll(":", "")}:
        </Text>

        <SelectedFilter ref={refFilter}>
          <Text variant="small1" color={Color.textMain} whiteSpace="nowrap">
            {options.find((option) => option.value === value)?.label ||
              options.find((option) => option.value === null)?.label}
          </Text>
        </SelectedFilter>

        {defaultValue !== undefined && value !== defaultValue && (
          <ClearButton onClick={() => setValue(defaultValue)}>
            <Icon name="clearCircle" color={Color.white} colorInner={Color.redActive} />
          </ClearButton>
        )}
      </Flex>

      {direction !== undefined && (
        <SortButton onClick={toggleDirection}>
          <Icon
            name={direction === "ASCENDING" ? "arrowUp" : "arrowDown"}
            color={Color.primary}
            size={10}
          />
        </SortButton>
      )}

      <AnchorLayer
        refLayer={refLayer}
        isRendering={isRendering}
        isUsingParentWidth
        layerState={layerState}
        isUsingClick={isUsingClick}
        hideAnchorLayer={hideAnchorLayer}
      >
        <Dropdown>
          <Flex
            background={Color.lightGray}
            width="100%"
            justify="between"
            padding="5px"
            gap="5px"
            align="center"
          >
            <Flex flexGrow gap="5px" align="center">
              <Text variant="small2" color={Color.textSecondary} whiteSpace="nowrap">
                {label.replaceAll(":", "")}:
              </Text>

              <SelectedFilter>
                <Text variant="small1" color={Color.textMain} whiteSpace="nowrap">
                  {options.find((option) => option.value === value)?.label ||
                    options.find((option) => option.value === null)?.label}
                </Text>
              </SelectedFilter>
            </Flex>
            {defaultValue !== undefined && value !== defaultValue && (
              <ClearButton onClick={() => setValue(defaultValue)}>
                <Icon name="clearCircle" color={Color.white} colorInner={Color.redActive} />
              </ClearButton>
            )}
          </Flex>
          <HorizontalDivider />

          <Scroller maxHeight="206px">
            <Flex padding="5px">
              <Radios
                options={options}
                value={value}
                variant="small2"
                setValue={(value) => {
                  setValue(value);
                  hideAnchorLayer();
                }}
                direction="column"
              />
            </Flex>
          </Scroller>
        </Dropdown>
      </AnchorLayer>
    </FilterableElement>
  );
};
