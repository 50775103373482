import React from "react";

import { useModals } from "src/components/Modals";
import { Button, Color, Flex, Icon, Skeleton, Text } from "src/elements";
import { Category } from "@CommonApi/categories/list";
import { useMenu } from "src/components/Menu";
import { Tooltip } from "src/components";
import styled from "styled-components";
import { useApi } from "src/api";

const SubCategoryContainer = styled(Flex)`
  &:last-of-type {
    margin-bottom: 10px;
  }
`;

interface Props {
  category: Category;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onGuidelines: (string: any) => void;
  onEdit: (category: Category) => void;
  onBindStores: (onBindStores: Category) => void;
  onImportPsa: (category: Category) => void;
  onImportCsv: (category: Category) => void;
  onImportStoreSummary: (category: Category) => void;
  onImportVariantRestriction: (category: Category) => void;
}

export const SubCategory = ({
  category,
  onGuidelines,
  loading,
  onBindStores,
  onImportPsa,
  onImportCsv,
  onImportStoreSummary,
  onImportVariantRestriction,
  onEdit,
}: Props) => {
  const { putCategories_categoryId_StatusApi } = useApi();
  const { openConfirmModal } = useModals();
  const { openMenu } = useMenu();

  const isArchived = category.status === "ARCHIVED";
  const isActive = category.status === "ACTIVE";

  const openArchiveCategoryConfirmModal = () => {
    openConfirmModal({
      title: `Are you sure you want to archive category ${category.code}?`,
      icon: { name: "alert", color: Color.yellow },
      buttons: [
        {
          label: "Back",
          color: "primary",
          variant: "inverted",
        },
        {
          label: "Archive",
          color: "yellow",
          onClick: archiveCategory,
        },
      ],
    });
  };

  const archiveCategory = () => {
    putCategories_categoryId_StatusApi(category.id, {
      status: "ARCHIVED",
    });
  };

  const unarchiveCategory = () => {
    putCategories_categoryId_StatusApi(category.id, {
      status: "ACTIVE",
    });
  };

  const openMoreMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    openMenu({
      event,
      menu: {
        options: [
          {
            label: "Edit",
            onClick: () => onEdit(category),
            icon: { name: "edit", color: Color.primary },
          },
          {
            label: isActive ? "Archive" : "Unarchive",
            icon: { name: "delete", color: Color.yellow },
            onClick: isActive ? openArchiveCategoryConfirmModal : unarchiveCategory,
          },
        ],
      },
    });
  };

  return (
    <SubCategoryContainer column gap="10px">
      <Flex padding="10px" margin="0 10px" borderColor={Color.lightGrayHover} borderRadius="3px">
        <Flex column justify="space-between" gap="10px" flexGrow={1}>
          <Flex justify="between" gap="10px" align="center">
            <Text variant="caption1" color={Color.textSecondary}>
              {loading ? <Skeleton height="18px" /> : category.code}
            </Text>

            <Flex gap="18px" align="center">
              {!loading && !category?.isBound && (
                <Flex>
                  <Icon name="alert" color={Color.yellow} size={14} />

                  <Tooltip>
                    No stores are bound to this category.
                    <br />
                    To generate Planograms, bind a store in the “stores” page,
                    <br />
                    available in the navigation panel on the left
                  </Tooltip>
                </Flex>
              )}

              {!loading && isArchived && (
                <Text variant="caption2" color={Color.red}>
                  Category Archived
                </Text>
              )}

              <Button variant="borderless" iconRight={{ name: "more" }} onClick={openMoreMenu} />
            </Flex>
          </Flex>

          <Flex gap="16px">
            <Flex column gap="5px">
              <Text variant="small4" color={Color.spaceGrayHover}>
                Merchandising Guidelines
              </Text>

              <Button
                color="greenSmoke"
                width="120px"
                variant="inverted"
                size="small"
                onClick={() => onGuidelines(category)}
                isDisabled
                isLoading={loading}
              >
                View/Modify
              </Button>
            </Flex>

            <Flex width="1px" height="100%" background={Color.lightGrayHover} />

            <Flex column gap="5px">
              <Text variant="small4" color={Color.spaceGrayHover}>
                Planograms
              </Text>

              <Flex gap="10px">
                <Button
                  color="greenSmoke"
                  variant="inverted"
                  size="small"
                  isDisabled
                  isLoading={loading}
                >
                  View
                </Button>

                <Button
                  color="greenSmoke"
                  variant="inverted"
                  size="small"
                  onClick={() => onBindStores(category)}
                  isDisabled
                  isLoading={loading}
                >
                  Stores
                </Button>
                <Button
                  color="greenSmoke"
                  variant="inverted"
                  size="small"
                  isDisabled={isArchived}
                  isLoading={loading}
                  dropdown={[
                    {
                      label: "PSA",
                      onClick: () => onImportPsa(category),
                    },
                    {
                      label: "CSV",
                      onClick: () => onImportCsv(category),
                    },
                    {
                      label: "STORE SUMMARY",
                      onClick: () => onImportStoreSummary(category),
                    },
                    {
                      label: "VARIANT RESTRICTION",
                      onClick: () => onImportVariantRestriction(category),
                    },
                  ]}
                >
                  Import
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </SubCategoryContainer>
  );
};
