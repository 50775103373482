import { Pagination, RequiredPaginationQueryParams } from "@CommonTypes/backend/pagination";

export const RouteGetInputsMasterFixturesAll = "/inputs/master-fixtures/all";

export type ParamsGetInputsMasterFixturesAll = RequiredPaginationQueryParams;

export type ResponseGetInputsMasterFixturesAll = Pagination<MasterFixture>;

export interface MasterFixture {
  master_fixture_id: number;
  upload_id: number;
  category_id: number;
  store_id: number;
  section_num: number;
  section_width: number;
  section_height: number;
  section_multiplier: number | null;
  section_combine_key: string | null;
  lead_in_right: boolean;
  can_combine: number;
  merchant_fixture_id: string;
  assembly: string | null;
  oos: string | null;
  variant_restriction: string | null;
  notch_spacing: number;
  notch_num: number;
  first_notch_y: number;
  grill_height: number | null;
  hang_cell_limit: number | null;
  textbox_x: number | null;
  textbox_y: number | null;
  textbox_height: number | null;
  textbox_width: number | null;
  textbox_text: string | null;
  max_notch_owa: number | null;
  notch_spacing_owa: number | null;
  bay_flip_flag: boolean | null;
  x: number | null;
  y: number | null;
  z: number | null;
  shelf_depth: number;
  shelf_y: number;
  shelf_type: "BASKET" | "HANGCELL" | "PEGBOARD" | "REGULAR" | "RISER";
  shelf_thickness: number;
  shelf_slope: number | null;
  stopper_height: number | null;
  shelf_key: string | null;
  shelf_clone: string | null;
  shelf_display: boolean;
  shelf_combine: string | null;
  merchandisable_shelf: boolean;
  merchant_peg_id: string | null;
  peg_notch_spacing_x: number | null;
  peg_notch_spacing_y: number | null;
  peg_notch_offset_x: number | null;
  peg_notch_offset_y: number | null;
  peg_start_x: number | null;
  peg_start_y: number | null;
  peg_end_x: number | null;
  peg_end_y: number | null;
  peg_drop: number | null;
  peg_gap_x: number | null;
  peg_gap_y: number | null;
  planogram_base_height: number;
  planogram_height: number;
  planogram_notch_offset: number;
  planogram_notch_spacing: number;
  segment_width: number;
  shelf_adjustment_notch_offset_calc: number;
  divider_width: number;
  divider_height: number;
  divider_depth: number;
  divider_at_start: number;
  divider_at_end: number;
  dividers_between_facings: number;
}
