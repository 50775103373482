import { Radios } from "../../../elements";
import { PspRules } from "@CommonTypes/backend/PspRules";

export interface PlanogramMudmapLevelProps {
  mudmapLevel: PspRules["mudmap_level"];
  setMudmapLevel: (mudmapLevel: PlanogramMudmapLevelProps["mudmapLevel"]) => void;
  isDisabled?: boolean;
}

export const PlanogramMudmapLevel = ({
  mudmapLevel,
  setMudmapLevel,
  isDisabled,
}: PlanogramMudmapLevelProps) => (
  <Radios
    label="Mudmap level"
    options={[
      {
        label: "cdt1",
        value: "CDT1",
      },
      {
        label: "cdt2",
        value: "CDT2",
      },
      {
        label: "item_id",
        value: "ITEM_ID",
      },
    ]}
    value={mudmapLevel}
    setValue={setMudmapLevel}
    isDisabled={isDisabled}
  />
);
