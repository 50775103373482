import moment from "moment";
import React, { useRef } from "react";
import { Color, Flex, Icon } from "src/elements";
import { SubgridCell } from "src/elements/SubgridTable/components/SubgridCell";
import { Project } from "src/omni-common/projects";
import { useHoverClickable } from "src/utils/hoverClickable";
import { useProjects } from "../store/hooks";
import { useNavigation } from "src/utils/navigation";
import { pages } from "src/utils";
import { useMerchflowHeader } from "src/components/merchflows/MerchflowHeader/store/hooks";
import { SubgridRow } from "src/elements/SubgridTable/components/SubgridRow";

const EditableColumn = ({ project, children }: { project: Project; children: React.ReactNode }) => {
  const refColumn = useRef(null);

  const { editProject } = useProjects();
  const { isHovered } = useHoverClickable(refColumn);

  return (
    <Flex
      ref={refColumn}
      width="100%"
      padding="8px"
      justify="between"
      align="center"
      gap="8px"
      height="minContent"
    >
      <Flex>{children}</Flex>

      <Flex
        opacity={isHovered ? 1 : 0}
        onClick={(event) => {
          event.stopPropagation();
          editProject(project);
        }}
      >
        <Icon name="edit" />
      </Flex>
    </Flex>
  );
};

export const ProjectRow = (project: Project) => {
  const refRow = useRef(null);

  const { setProject } = useMerchflowHeader();
  const { navigate } = useNavigation();
  const { isHovered } = useHoverClickable(refRow);

  const formatDate = (date: string | null) => {
    return date ? moment(date).format("DD.MM.YYYY HH:MM") : null;
  };

  const navigateToPogSets = () => {
    setProject(project);
    navigate(pages.projects_projectId_pogsets, { projectId: project.project_id });
  };

  return (
    <SubgridRow ref={refRow} backgroundHovered={Color.lightGray} onClick={navigateToPogSets}>
      <SubgridCell justify="right" color={isHovered && Color.primaryHover} leftBorder>
        {project.project_id}
      </SubgridCell>

      <SubgridCell color={isHovered && Color.primaryHover} padding="0">
        <EditableColumn project={project}>{project.name}</EditableColumn>
      </SubgridCell>

      <SubgridCell color={isHovered && Color.primaryHover}>
        {formatDate(project.go_live_date)}
      </SubgridCell>

      <SubgridCell color={isHovered && Color.primaryHover}>
        {formatDate(project.deactivation_date)}
      </SubgridCell>

      <SubgridCell color={isHovered && Color.primaryHover}></SubgridCell>

      <SubgridCell color={isHovered && Color.primaryHover}></SubgridCell>

      <SubgridCell color={isHovered && Color.primaryHover} padding="0">
        <EditableColumn project={project}>{project.notes}</EditableColumn>
      </SubgridCell>

      <SubgridCell color={isHovered && Color.primaryHover}></SubgridCell>
    </SubgridRow>
  );
};
