import React from "react";
import { Sidebar } from "src/components";
import styled from "styled-components";

import { sidebarPadding, sidebarWidth } from "../Sidebar/Sidebar";
import { Color, Flex } from "src/elements";

const PageContainer = styled(Flex)<{ hasSidebar: boolean }>`
  padding: ${({ hasSidebar }) =>
    hasSidebar &&
    `${sidebarPadding}px ${sidebarPadding}px ${sidebarPadding}px
    ${sidebarWidth + 2 * sidebarPadding}px`};
  background: ${Color.white};
  height: 100vh;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

interface Props {
  children: React.ReactNode;
  hasSidebar?: boolean;
}

export const Page = ({ children, hasSidebar = true }: Props) => (
  <>
    <PageContainer hasSidebar={hasSidebar}>
      {hasSidebar && <Sidebar />}

      <Main>{children}</Main>
    </PageContainer>
  </>
);
