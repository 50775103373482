import { useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "src/components";
import { MerchflowHeader } from "src/components/merchflows/MerchflowHeader/MerchflowHeader";
import { Button, Flex, Pagination, Scroller, Search, SubgridTable } from "src/elements";
import { usePaginationPage } from "src/elements/Pagination";

export const PagePogSets = () => {
  const { projectId: _projectId } = useParams<{ projectId: string }>();
  const projectId = Number(_projectId);

  const [page, setPage] = usePaginationPage();
  const [search, setSearch] = useState("");

  return (
    <Page>
      <MerchflowHeader projectId={projectId} showFrogger={false} />

      <Flex gap="10px" justify="between" align="center">
        <Flex gap="10px" align="center">
          <Button iconRight={{ name: "filter" }}>Filters</Button>

          <Search
            width="180px"
            search={search}
            setSearch={setSearch}
            placeholder="Search POG Sets"
          />
        </Flex>

        <Flex gap="10px">
          <Button color="greenSmoke" dropdown={[]} isDisabled>
            Favorite
          </Button>

          <Button color="greenSmoke" dropdown={[]} isDisabled>
            Approvals
          </Button>

          <Button color="greenSmoke" dropdown={[]} isDisabled>
            Exports
          </Button>

          <Button color="greenSmoke" iconRight={{ name: "plus" }}>
            Create New POG Set
          </Button>
        </Flex>
      </Flex>

      <Scroller gutter="auto">
        <SubgridTable
          columns={[
            {
              id: "pogset_id",
              header: "POG Set ID",
            },
            {
              id: "method",
              header: "Method",
            },
            {
              id: "pogset",
              header: "POG Set",
            },
            {
              id: "core_range",
              header: "Core Range",
            },
            {
              id: "return_on_space",
              header: "Return on Space",
            },
            {
              id: "utilization",
              header: "Utilization",
            },
            {
              id: "dos_mos",
              header: "DOS / MOS",
            },
            {
              id: "assigned_stores",
              header: "Assigned Stores",
            },
            {
              id: "generated_stores",
              header: "Stores Generated",
            },
            {
              id: "approved_stores",
              header: "Approved Stores",
            },
            {
              id: "notes",
              header: "Notes",
              width: "1fr",
            },
            {
              id: "actions",
              header: "Actions",
            },
          ]}
          data={[]}
        />
      </Scroller>

      <Pagination page={page} setPage={setPage} totalPages={1} />
    </Page>
  );
};
