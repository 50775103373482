import React, { useRef, useState } from "react";
import styled from "styled-components";

import { Color, Flex, Icon } from "src/elements";
import { useHoverClickable } from "src/utils/hoverClickable";

const PanelElement = styled(Flex)<{ width: string }>`
  flex-direction: column;
  flex-grow: 1;
  min-width: ${({ width }) => width};
  width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  min-height: 0;
  border-left: 1px solid ${Color.primary};
`;

const Content = styled(Flex)`
  flex-grow: 1;
  min-height: 0;
`;

const SidepanelToggleElement = styled(Flex)<{ isExpanded: boolean }>`
  width: 100%;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
`;

const SidepanelToggle = ({
  isExpanded,
  setIsExpanded,
}: {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}) => {
  const refToggle = useRef(null);
  const { isHovered, isActive } = useHoverClickable(refToggle);

  const togglePanel = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <SidepanelToggleElement
      ref={refToggle}
      isExpanded={isExpanded}
      background={
        (isActive && Color.greenSmokeActive) ||
        (isHovered && Color.greenSmokeHover) ||
        Color.greenSmokeDisabled
      }
      borderColor={Color.primary}
      borderWidth="0 0 0 1px"
      onClick={togglePanel}
    >
      <Icon
        name={isExpanded ? "chevronRight" : "chevronLeft"}
        color={((isHovered || isActive) && Color.primaryActive) || Color.primary}
      />
    </SidepanelToggleElement>
  );
};

interface Props<T> {
  tabs: T[];
  tab: T;
  setTab: (tab: T) => void;
  width?: string;
  children: React.ReactNode;
}

export const Panel = <T,>({
  tabs,
  tab: activeTab,
  setTab: setActiveTab,
  width = "100%",
  children,
}: Props<T>) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Flex>
      {isExpanded && (
        <PanelElement width={width}>
          <Content>{children}</Content>
        </PanelElement>
      )}

      <Flex background="red" align="between" column minWidth="28px">
        <Flex></Flex>
        <SidepanelToggle isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      </Flex>
    </Flex>
  );
};
